import axios from "axios";
import React, { useState } from "react";
import eInvoiceImage from "../static/img/Einvoicing.png";
import "../assets/css/eInvoice.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";
const Einvoice = () => {

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const [captchaToken, setCaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    type: '',
    name: '',
    email: '',
    mobile: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios.post('/apiCustomerDetail/', dataToSubmit)
      .then(response => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError('');
        setFormData({
          type: '',
          name: '',
          email: '',
          mobile: ''
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch(error => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };



  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box


  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };
  return (
    <div>
      <Helmet>
        <title>Smart e-Invoicing Solution</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Businesses/Taxpayers with a turnover more than INR 100 crores in a financial year must implement the e-Invoicing process from 1 January 2021. This process requires the submission of documents like GST invoices, debit/credit notes, and more to the Invoice Registration Portal (IRP) for validation and QR code generation." />
        <meta name="keywords" content="E-invoicing, smart e-invoicing, invoicing, invoice" />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Smart e-Invoicing Solution" />
        <meta property="og:description" content="Implement the mandatory e-Invoicing process for businesses with a turnover exceeding INR 100 crores. Submit your invoices to the IRP for validation and receive a digital e-Invoice." />
        <meta property="og:url" content="https://www.taxosmart.com/Einvoice" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Smart e-Invoicing Solution" />
        <meta name="twitter:description" content="Implement the mandatory e-Invoicing process for businesses with a turnover exceeding INR 100 crores. Submit your invoices to the IRP for validation and receive a digital e-Invoice." />
        <meta name="twitter:url" content="https://www.taxosmart.com/Einvoice" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/Einvoice" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container ">
          <div className="Topbox" style={{ marginTop: '-9%' }}>
            <div className="topcontent">
              <h1 className="InfoColor">Information About Smart E-INVOICING</h1>
              <p className="effort">
                Businesses/Taxpayers with a turnover more than INR 50 crores in
                a financial year will have to implement the e-Invoicing process
                from 01st April 2021 mandatorily.{" "}
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={eInvoiceImage}
                className="img-fluid gstImageCss"
                style={{ width: '100%', height: 'auto' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about" style={{ marginTop: "3%" }}>
        <div className="container-fluied">
          <div className="row gx-0">
            <div className="col-lg-12 d-flex flex-column justify-content-center">
              <div className="container content">
                <br />
                <h1
                  className="  slideInDown"
                  style={{ color: "#0f316d", textAlign: "center" }}
                >
                  Introduction to E-invoicing
                </h1>
                <br />
                <p className="busTop text-justify">
                  Businesses/Taxpayers with a turnover more than INR 50 crores
                  in a financial year will have to implement the e-Invoicing
                  process from 01st April 2021 mandatorily. Under this process,
                  businesses have to submit documents like GST invoices,
                  debit/credit notes, reverse change invoices, export invoices,
                  etc. to a centralised Government portal called the Invoice
                  Registration Portal (IRP). The IRP will validate the data in
                  these invoices and generate an Invoice Reference Number and QR
                  code for the same. It will digitally sign the invoice and send
                  back an e-Invoice to the taxpayers electronically.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTop">
        <div className="container">
          <h1
            className="   slideInDown"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            E-Invoicing Applicability
          </h1>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex" id="whyRecon">
              <div className="row align-self-center gy-4">
                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Has a turnover of INR 50 crores or more (based on PAN) in
                      the previous financial year.
                    </h3>
                  </div>
                </div>

                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Issues B2B invoices.</h3>
                  </div>
                </div>

                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Supplies goods or services or both to a registered
                      person(recipient or transporter of supplies cannot
                      generate e-Invoices)
                    </h3>
                  </div>
                </div>

                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Taxpayers undertaking Exports (with/without payment) and
                      Deemed Exports are liable to generate e-Invoices.
                    </h3>
                  </div>
                </div>

                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Supplies to SEZs (with/without payment) are covered under
                      e-Invoicing
                    </h3>
                  </div>
                </div>
                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      SEZ Developers who have the specified turnover (while
                      fulfilling the other conditions) are required to generate
                      e-Invoices.
                    </h3>
                  </div>
                </div>
                <div className="col-md-12 wow fadeInUp">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      DTA units are required to issue e-Invoices if other
                      guidelines are met.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="table d-flex justify-content-center">
        <div className="container-fluied" data-aos="">
          <div
            className="table-responsive aos-init ng-scope aos-animate"
            data-aos=""
            style={{ width: "100%" }}
          >
            <table className="table table-bordered" style={{ width: "1086px" }}>
              <thead>
                <tr>
                  <th
                    colspan="2"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#0F316D",
                      color: "#fff",
                    }}
                  >
                    CBIC Notified Timelines
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Turnover in INR at PAN level</td>
                  <td>E-Invoicing Applicable from</td>
                </tr>
                <tr>
                  <td>More than INR 500 crores</td>
                  <td>01st Oct, 2020</td>
                </tr>
                <tr>
                  <td>More than INR 100 crores</td>
                  <td>01st Jan, 2021</td>
                </tr>
                <tr>
                  <td>More than INR 50 crores</td>
                  <td>01st April, 2021</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTop">
        <div className="container" data-aos="">
          <h1
            className="   slideInDown"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            Taxapayers Exempted from E-Invoice
          </h1>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Insurance Company</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Transport service provider, providing passenger
                      transportation service
                    </h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Special Economic Zone (SEZ) units</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Free Trade & Warehousing Zones (FTWZ)</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      E-Invoicing is not applicable for import Bills of Entry
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Invoices issued by Input Service Distributors (ISD) are
                      not covered under E-Invoicing.
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      From an unregistered person (attracting reverse charge) or
                    </h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Through the import of services, e-invoicing is not
                      applicable
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTopOb">
        <div className="container" data-aos="">
          <h1
            className=" slideInDown"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            Taxpayer's Obligation
          </h1>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4">
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Report the invoice details to the IRP,</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Obtain IRN and</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Issue the final invoice with QR code</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features" style={{ marginTop: "0%" }}>
        <div className="container aos-init aos-animate" data-aos="">
          <div
            className="row feature-icons aos-init aos-animate"
            data-aos=""
            style={{ marginTop: "0%" }}
          >
            <h3>
              Multiple Data Integration offered by our E-Invoicing solution
            </h3>

            <div className="row" style={{ marginTop: "0%" }}>
              <div className="col-xl-12 d-flex content">
                <div className="row align-self-center gy-4 decTop">
                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>DATA API's</h4>
                      <p>
                        Send data for one invoice at a time to taxosmart and get
                        real time response
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i className="ri-stack-line"></i>
                    <div>
                      <h4>Push file to SFTP:</h4>
                      <p>
                        Push files and get IRN either through portal or file
                        response
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="ri-command-line"></i>
                    <div>
                      <h4>Upload File APIs</h4>
                      <p>
                        Send CSV file containing one or multiple invoices
                        through APIs
                      </p>
                      <p></p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="300"
                  >
                    <i className="ri-magic-line"></i>
                    <div>
                      <h4>Manual Upload</h4>
                      <p>
                        Use taxosmart - E-invoicing Software to upload the CSV
                        file and generate IRN
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                TDS SOFTWARE
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={eInvoiceImage}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Smart E-INVOICING")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Einvoice;
