import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/CompanyAnnualfiling.css";
import CAI from "../static/img/companyAnnual.webp";
import Areturn from "../static/assetse/img/annualReturn.jpg";
import ComAfiling from "../static/image/img/compAnnualFiling.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
const CompanyAnnualfiling = () => {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Company Annual Filing   </title>
        <meta charset="utf-8" />
        <meta name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Annual filing of the company includes all the documentation related to the filing of financial statements and Annual return which consists of information that includes the Financial Statements of the company, Certifications(if any) Registered Office Address, Shares and Debenture details, Register of Members, Debt details and information about the Management of the Company." />
        <meta name="keywords" content="Annual Filling,Forms, Compliances ,Penalties." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <main id="pageTop" className="pageTop">
        {/* <!-- End Call To Action Section --> */}

        <section id="hero" className="hero d-flex align-items-center">
          <div className="container comTop">
            <div className="Topbox">
              <div className="topcontent">
                <h1 className="fs-1">
                  EASILY MANAGE YOUR COMPANY'S ANNUAL RETURN
                </h1>
                <p>Simplifying the Maintenance of Company Compliances</p>
                <div data-aos="" data-aos-delay="600">
                  <div className="text-center text-lg-start" id="resp">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Get In Touch</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg">
                <img src={CAI} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <br />

        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={Areturn} className="img-fluid mt-0" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>Company's Annual Return</h3>
                <p>
                  It is mandatory for every company incorporated in India
                  whether public or private to file the various e-Forms along
                  with the necessary documents with the Registrar of Companies.
                </p>
                <p>
                  Annual filing of the company includes all the documentation
                  related to the filing of financial statements and Annual
                  return which consists of information that includes the
                  Financial Statements of the company, Certifications(if any)
                  Registered Office Address, Shares and Debenture details,
                  Register of Members, Debt details and information about the
                  Management of the Company. The annual return would also
                  disclose the shareholdings structure of the Company, changes
                  in Directorship and details of the transfer of securities.
                </p>
                <p>
                  TaxOSmart can help you file your Company's Annual Return in
                  India.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop includeTop">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2 className="mediaFont">What Is Included In Our Package?</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Preparation Of E-Form AOC 4 for Filing Financial
                        Statement
                      </h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Preparation of E-Form MGT 7 for Filing Annual return
                      </h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Documentation</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Filing Expenses with the register</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>

        <br />

        <section className="pt-0">
          <div className="container">
            <div className="row">
              {/* <!-- ***** FAQ Start ***** --> */}
              <div className="col-md-6 offset-md-3">
                <div className="faq-title text-center pb-3">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="faq" id="accordion">
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-1"
                          data-aria-expanded="true"
                          data-aria-controls="faqCollapse-1"
                        >
                          <span className="badge">1</span>What do you mean by
                          Annual Filing?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-1"
                      className="collapse"
                      aria-labelledby="faqHeading-1"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          An Annual Filling is a mandatory filing to be made by
                          the company incorporated in India. The E-form filing
                          along with the required documents must be filed with
                          MCA.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-2">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-2"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-2"
                        >
                          <span className="badge">2</span>What are the penalty &
                          prosecutions for not filing the Annual Filling?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-2"
                      className="collapse"
                      aria-labelledby="faqHeading-2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          The Company will be considered as the defaulting
                          company and liable to pay the penalty. The amount of
                          penalty will depend on the number of days in default
                          till the default continues.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-3">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-3"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-3"
                        >
                          <span className="badge">3</span>What information is
                          contained in Annual Filing?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-3"
                      className="collapse"
                      aria-labelledby="faqHeading-3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Annual filing consists of the balance sheet of the
                          company, profit and loss account, certifications if
                          any, details of members, details of shareholders and
                          its shareholdings and details of Directors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-4">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-4"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-4"
                        >
                          <span className="badge">4</span> Who should sign
                          Annual filling E-forms?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-4"
                      className="collapse"
                      aria-labelledby="faqHeading-4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          The Annual filing documents of the Company must be
                          signed by the Directors of the Company. The financial
                          statements must be audited & signed by a Chartered
                          Accountant.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- Modal --> */}

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                TDS SOFTWARE
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={CAI}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "company Annual Filing")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAnnualfiling;
