import React, { useEffect, useState } from "react";


import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';
import "../assets/css/animate.min.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/carousel.css";
import "../assets/css/datepicker.css";
import "../assets/css/font-awesome.min.css";
import "../assets/css/homepage.css";
import "../assets/css/navbar.css";
import "../assets/css/owl-carousel.css";
import "../assets/css/responsive.css";
import "../assets/css/style.css";
import "../static/assets/css/all.min.css";
import "../static/assets/css/styleMin.css";
import aboutImage from "../static/assets/img/about-2.jpg";
import icon1 from "../static/assets/img/icons/icon-1.png";
import icon2 from "../static/assets/img/icons/icon-2.png";
import icon3 from "../static/assets/img/icons/icon-3.png";
import icon4 from "../static/assets/img/icons/icon-4.png";
import taxosmartPNG from "../static/assets/img/Taxosmart.png";
import "../static/assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "../static/assets/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css";
import gstBgImage from "../static/img/gst_logo-removebg-preview.png";
import invoiceMainImage from "../static/img/i-smart-removebg-preview.png";
import tdsMainImage from "../static/img/tds-o-removebg-preview.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const HomePage = () => {
  const [impDatesIncomeTax, setimpDatesIncomeTax] = useState([]);
  const [impDatesGST, setimpDatesGST] = useState([]);
  const [impDatesTDS, setimpDatesTDS] = useState([]);
  const [impDatesESI, setimpDatesESI] = useState([]);
  const [impDatesProvidentFund, setimpDatesProvidentFund] = useState([]);
  const [impDatesOthers, setimpDatesOthers] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [formData, setFormData] = useState([]);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  // Fetch data from API
  const fetchData = async () => {
    try {

      const response = await axios.get("/apiimportantDates/list/count");
      const result = response.data;

      setimpDatesIncomeTax(result.impDatesIncomeTax);
      setimpDatesGST(result.impDatesGST);
      setimpDatesTDS(result.impDatesTDS);
      setimpDatesESI(result.impDatesESI);
      setimpDatesProvidentFund(result.impDatesProvidentFund);
      setimpDatesOthers(result.impDatesOthers);
    } catch (error) {
      
    }
  };



  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };



  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios.post('/apiCustomerDetail/', dataToSubmit)
      .then(response => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError('');
        setFormData({
          type: '',
          name: '',
          email: '',
          mobile: ''
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch(error => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  // Close modals
  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Combine all arrays into one
  const combinedData = [
    ...impDatesIncomeTax,
    ...impDatesGST,
    ...impDatesTDS,
    ...impDatesESI,
    ...impDatesProvidentFund,
    ...impDatesOthers,
  ];

  // Function to format date
  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };
  // Render component
  return (
    <div style={{
      overflowX: 'hidden',
      overflowY: 'hidden'
    }}>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators" style={{ color: "black" }}>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="col-md-6 ">
              <img src={tdsMainImage} className="d-block w-100" alt="..." />
            </div>
            <div className="col-md-6 carouselText">
              <div className=" carouselMargin carousel-caption d-flex flex-column h-100 align-items-center justify-content-center bottom-0">
                <h1 className=" bg-opacity-50 py-2 px-4 tdsFonts">
                  TDS SOFTWARE
                </h1>
                <div data-aos="" data-aos-delay="600">
                  <div className="text-center text-lg-start" id="btnWidth">
                    <a
                      href="/TdsSoftware"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center "
                      id="padd"
                    >
                      <span className="getCss">Get In Touch</span>
                      <i className="bi bi-arrow-right whiteCss"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item ">
            <div className="col-md-6">
              <img src={gstBgImage} className="d-block w-100" alt="..." />
            </div>
            <div className="col-md-6 carouselText">
              <div className=" carouselMargin carousel-caption d-flex flex-column h-100 align-items-center justify-content-center bottom-0">
                <h1 className=" bg-opacity-50 py-2 px-4 tdsFonts">
                  GST SOFTWARE
                </h1>
                <div data-aos="" data-aos-delay="600">
                  <div className="text-center text-lg-start">
                    <a
                      href="/GstReconcile"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center "
                      id="padd"
                    >
                      <span className="getCss">Get In Touch</span>
                      <i className="bi bi-arrow-right whiteCss"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item ">
            <div className="col-md-6">
              <img src={invoiceMainImage} className="d-block w-100" alt="..." />
            </div>
            <div className="col-md-6 carouselText">
              <div className=" carouselMargin carousel-caption d-flex flex-column h-100 align-items-center justify-content-center bottom-0">
                <h1 className=" bg-opacity-50 py-2 px-4 tdsFonts">
                  SMART INVOICE
                </h1>
                <div data-aos="" data-aos-delay="600">
                  <div className="text-center text-lg-start">
                    <a
                      ui-sref="home.tdsSoftware"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center "
                      href="/TdsSoftware"
                      id="padd"
                    >
                      <span className="getCss">Get In Touch</span>
                      <i className="bi bi-arrow-right whiteCss"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="ng-scope marqueeTop">
        {" "}
        <div className="ticker">
          {" "}
          <div className="title">
            <h3 className="importantCss">Important Dates </h3>
          </div>{" "}
          <div className="news">
            {" "}
            <marquee
              className="news-content"
              behavior="scroll"
              loop="infinite"
              scrolldelay="10s"
              onmouseover="this.stop();"
              onmouseout="this.start();"
            >
              {" "}
              <p
                className="whiteCss"
                style={{ marginBottom: "0rem", color: "#fff" }}
              >
                <div className="d-flex whiteCss">
                  {/* {`error` && <p style={{ color: "#fff" }}></p>} */}
                  {combinedData.length === 0 ? (
                    <p style={{ color: "#fff" }}></p>
                  ) : (
                    <div style={{ display: "flex" }}>
                      {impDatesGST.map((item, index) => (
                        <div key={index} className="d-flex">
                          {/* <p>id: {item.id}</p> */}
                          <p
                            style={{
                              padding: "10px 10px 0px 0px",
                              color: "orange",
                            }}
                          >
                            {item.duedate}{" "}
                          </p>
                          <p
                            style={{
                              padding: "10px 10px 0px 0px",
                              color: "#fff",
                            }}
                          >
                            {" "}
                            - {item.description}
                          </p>{" "}
                          {/* <p>Description: {item.description}</p> */}
                        </div>
                      ))}
                    </div>
                  )}
                </div>{" "}
              </p>
            </marquee>{" "}
          </div>{" "}
        </div>{" "}
      </div>
      <div className="container-xxl py-5 mt-2">
        <div className="container pt-5">
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp mb-3"
              data-wow-delay="0.1s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                <div className="fact-icon">
                  <img src={icon2} alt="Icon" />
                </div>
                <h3 className="mb-3 licenceColor">Business Incorporation</h3>
                <p className="mb-0 text-justify">
                  Tax-O-Smart is one of the leader in business incorporation
                  services in India. We specialize in registering a wide range
                  of business entities like Limited Liability Partnership, Pvt
                  Ltd Company, Partnership, public limited Company.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp mb-3"
              data-wow-delay="0.3s"
              id="wow-child"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                <div className="fact-icon">
                  <img src={icon3} alt="Icon" />
                </div>
                <h3 className="mb-3 licenceColor">License & Registrations</h3>
                <p className="mb-0 text-justify">
                  Tax-O-Smart can help your business obtain various license and
                  registrations. We specialize in obtaining property
                  registrations, GST Registration, tax registrations, service
                  tax and professional tax registration
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp mb-3"
              data-wow-delay="0.5s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                <div className="fact-icon">
                  <img src={icon4} alt="Icon" />
                </div>
                <h3 className="mb-3 legalColor">Legal & Compliance</h3>
                <p className="mb-0 text-justify">
                  Tax-O-Smart can help you create a Will or support your
                  business maintain the legal and regulatory compliance. We
                  specialize in Will drafting, winding up of business,
                  regulatory compliance filing services like TDS return filing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5 z-10000">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h4 className=" reasonColor">Reasons Why Customers Like Us</h4>
              <h1 className="display-5 mb-4 subColor">
                Why You Should Trust Us? Learn More About Us!
              </h1>
              <p className="mb-4">
                TaxOSmart is using the internet to simplify time-consuming
                paperwork. Over the past five years, we've helped ten thousands
                of start-ups register thems elves, protect their intellectual
                property, secure funding from Venture Capitalists & comply with
                the many regulations of the MCA.
              </p>
              <div className="row g-4">
                <div className="col-12">
                  <div className="d-flex align-items-start">
                    <img className="flex-shrink-0" src={icon2} alt="Icon" />
                    <div className="ms-4">
                      <h3 className="subColor">Startup Friendly</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-start">
                    <img className="flex-shrink-0" src={icon3} alt="Icon" />
                    <div className="ms-4">
                      <h3 className="subColor">Cost-Efficient</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex align-items-start">
                    <img className="flex-shrink-0" src={icon4} alt="Icon" />
                    <div className="ms-4">
                      <h3 className="subColor">Time-Saving</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <img className="img-fluid forThe" src={taxosmartPNG} alt="" />
              {/* <div className="feature-img">
                <img className="img-fluid mt-0" src={aboutImage} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl project py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp maxWidth"
            data-wow-delay="0.1s"
            mb-6
          >
            <h4 className=" smallText">Our Services</h4>
            <h1 className="display-5 mb-5 legalColor pb-6">
              Technology empowering your business
            </h1>
          </div>
          <div className="row g-4 wow fadeInUp mt-3" data-wow-delay="0.3s">
            <div className="col-lg-4">
              <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                <button
                  className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active border-17"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-1"
                  type="button"
                >
                  <h3 className="m-0 legalColor">01. Start a Business</h3>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 border-17"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-2"
                  type="button"
                >
                  <h3 className="m-0 legalColor">02. Tax Registration</h3>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 border-17"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-3"
                  type="button"
                >
                  <h3 className="m-0 legalColor">03. Trademark & Copyright</h3>
                </button>
                <button
                  className="nav-link w-100 d-flex align-items-center text-start p-4 mb-0 border-17"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-pane-4"
                  type="button"
                >
                  <h3 className="m-0 legalColor">04. Tax Filing</h3>
                </button>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content w-100">
                <div className="tab-pane fade show active" id="tab-pane-1">
                  <div className="row g-4 mt-4">
                    <div
                      className="col-lg-6 col-md-6 wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon1} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">
                          PRIVATE LIMITED COMPANY
                        </h3>
                        <p className="mb-0 text-justify">
                          The default option for start-ups and growing
                          businesses as only private limited companies can raise
                          venture capital. Private Limited Company registration
                          is the most popular legal structure option for
                          businesses in India.
                        </p>
                        <br />{" "}
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                        >
                          Read More
                        </a>
                      </div>
                    </div>

                    <div
                      className="col-lg-6 col-md-6 wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon2} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">
                          LIMITED LIABILITY PARTNERSHIP
                        </h3>
                        <p className="mb-0 text-justify">
                          The perfect structure for professional services firms
                          such as financial advisories and ad agencies LLP
                          Registration can be done through TaxOSmart in Delhi
                          NCR, Mumbai, Bengaluru, Chennai and all other Indian
                          cities.
                        </p>
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                          ui-sref="home.llp"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane " id="tab-pane-2">
                  <div className="row g-4">
                    <div className="col-lg-6 col-md-6  ">
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon1} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">GST REGISTRATION</h3>
                        <p className="mb-0 text-justify">
                          Register only if the aggregate turnover in any
                          financial year exceeds the threshold limit with a
                          turnover of over Rs. 20 lakh.Thus the current indirect
                          taxes levied by state and centre are all set to be
                          replaced with proposed implementation of GST by April
                          2017. GST registration usually takes between 2-6
                          working days
                        </p>
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                          ui-sref="home.gst"
                        >
                          Read More
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 ">
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon2} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">
                          SALES TAX REGISTRATION
                        </h3>
                        <p className="mb-0 text-justify">
                          To pay this tax, the service provider must be
                          registered with the Central Government if the
                          preceding fiscal year's turnover is over Rs. 5 lakh.
                          Sales Tax Registration is required if the annual
                          turnover crosses Rs. 5 lakhs, though some have raised
                          this threshold limit to Rs. 10 lakhs.
                        </p>
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                          ui-sref="home.sales"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tab-pane-3">
                  <div className="row g-4">
                    <div className="col-lg-6 col-md-6" data-wow-delay="0.1s">
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon1} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">
                          TRADEMARK REGISTRATION
                        </h3>
                        <p className="mb-0 text-justify">
                          Protect your brand, slogan or logo. Begin now and use
                          the trademark symbol beside your name in three working
                          days.It is always advised to get trademark
                          registration because getting your company registered
                          will not protect your brand against those who might
                          initiate using identical
                        </p>
                        <br />{" "}
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                        >
                          Read More
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6" data-wow-delay="0.1s">
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon2} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">
                          COPYRIGHT REGISTRATION
                        </h3>
                        <p className="mb-0 text-justify">
                          A registration confers upon its owner sole rights to
                          copy or reproduce the work or grant permission to
                          another to do so. Copyright is basically a legal right
                          which has been provided to the creators of literature,
                          dramatics and artistic work and even the producer's
                          films and sound recordings.
                        </p>
                        <a
                          href=""
                          ui-sref="home.copyRight"
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane " id="tab-pane-4">
                  <div className="row g-4">
                    <div className="col-lg-6 col-md-6" data-wow-delay="0.1s">
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon1} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">INCOME TAX RETURN</h3>
                        <p className="mb-0 text-justify">
                          Income tax is tax levied on the income of a person by
                          the government of India as per the provisions
                          contained in the Income Tax Act 1961. Income Tax
                          Return is the process of filing your tax returns at
                          the end of a financial year
                        </p>
                        <br />
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                          ui-sref="home.itr"
                        >
                          Read More
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6" data-wow-delay="0.1s">
                      <div className="fact-item text-center bg-light h-100 p-5 pt-0 borderRad">
                        <div className="fact-icon">
                          <img src={icon2} alt="Icon" />
                        </div>
                        <h3 className="mb-3 legalColor">
                          BUSINESS INCOME RETURN
                        </h3>
                        <p className="mb-0 text-justify">
                          Easy ITR filing for Professionals, Freelancers and
                          Businessman.There are basically two types of return
                          which is to be filed by a professional, either ITR 4
                          if he chooses for the maintenance and audit of
                          accounts else ITR 4S if he do not choose the same
                        </p>
                        <a
                          href=""
                          className="btn btn-success py-3 px-5 mt-3 readColor"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h4 className=" contactUs">Contact Us</h4>
              <h1 className="display-5 mb-4 legalColor">
                We'd love to hear from you!
              </h1>
              <div className="row g-4">
                <div className="col-12">
                  <div className="d-flex">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light styleClass">
                      <i className="fa fa-2x fa-phone-alt legalColor "></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-2 contactUs">TALK TO AN EXPERT </p>
                      <h3 className="mb-0 legalColor">+91 9967 646 171</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light widthCss">
                      <i className="fa fa-2x fa-envelope-open legalColor"></i>
                    </div>
                    <div className="ms-4">
                      <p className="mb-2 contactUs"> FOR QUERIES </p>
                      <h3 className="mb-0 legalColor">info@taxosmart.com</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow " data-wow-delay="0.5s">
              <form onSubmit={handleSubmit} >
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      className="form-control height55"
                      placeholder="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input
                      type="email"
                      className="form-control height55"
                      placeholder="Your Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      className="form-control height55"
                      placeholder="Your Mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <select
                      className="form-select height55"
                      placeholder="Search for Service"
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      required
                    >
                      <option className="black" value="Private Limited Company">
                        Private Limited Company
                      </option>
                      <option value="Limited Liability Partnership">
                        Limited Liability Partnership
                      </option>
                      <option value="Partnership Registration">
                        Partnership Registration
                      </option>
                      <option value="One Person Company">
                        One Person Company
                      </option>
                      <option value="Public Limited Company">
                        Public Limited Company
                      </option>
                      <option value="MSME/SSI Registration">
                        MSME/SSI Registration
                      </option>
                      <option value="Society Registration">
                        Society Registration
                      </option>
                      <option value="Section 8 Company">Section 8 Company</option>
                      <option value="Nidhi Company Registration">
                        Nidhi Company Registration
                      </option>
                      <option value="Indian Subsidiary">Indian Subsidiary</option>
                      <option value="FSSAI License">FSSAI License</option>
                      <option value="Import Export Code Registration">
                        Import Export Code Registration
                      </option>
                      <option value="ISO Registration">ISO Registration</option>
                      <option value="Digital Signature (DSC)">
                        Digital Signature (DSC)
                      </option>
                      <option value="ESI Registration">ESI Registration</option>
                      <option value="Provident Fund Registration">
                        Provident Fund Registration
                      </option>
                      <option value="Trade License">Trade License</option>
                      <option value="GST Registration">GST Registration</option>
                      <option value="Sales Tax Registration">
                        Sales Tax Registration
                      </option>
                      <option value="Professional Tax Registration">
                        Professional Tax Registration
                      </option>
                      <option value="Excise Tax Registration">
                        Excise Tax Registration
                      </option>
                      <option value="Trademark Registration">
                        Trademark Registration
                      </option>
                      <option value="Trademark Assignment">
                        Trademark Assignment
                      </option>
                      <option value="Trademark Objection">
                        Trademark Objection
                      </option>
                      <option value="Copyright Registration">
                        Copyright Registration
                      </option>
                      <option value="Patent Registration">
                        Patent Registration
                      </option>
                      <option value="Patent Search">Patent Search</option>
                      <option value="ROC Compliances">ROC Compliances</option>
                      <option value="LLP Annual Filing">LLP Annual Filing</option>
                      <option value="Company Annual Filing">
                        Company Annual Filing
                      </option>
                      <option value="Maintenance of minutes/Statutory register">
                        Maintenance of minutes/Statutory register
                      </option>
                      <option value="Business Plan Preparation">
                        Business Plan Preparation
                      </option>
                      <option value="Allotment of Shares">
                        Allotment of Shares
                      </option>
                      <option value="Change in Company Name">
                        Change in Company Name
                      </option>
                      <option value="Change in Directors">
                        Change in Directors
                      </option>
                      <option value="Change Registered Office">
                        Change Registered Office
                      </option>
                      <option value="Change in Authorized Share Capital">
                        Change in Authorized Share Capital
                      </option>
                      <option value="Proprietorship to Private Ltd.">
                        Proprietorship to Private Ltd.
                      </option>
                      <option value="Partnership to LLP">
                        Partnership to LLP
                      </option>
                      <option value="Closing LLP">Closing LLP</option>
                      <option value="Closing Private Limited">
                        Closing Private Limited
                      </option>
                      <option value="Income Tax Return">Income Tax Return</option>
                      <option value="Bulk Return Filing">
                        Bulk Return Filing
                      </option>
                      <option value="Revised Return Filing">
                        Revised Return Filing
                      </option>
                      <option value="Respond to tax notice">
                        Respond to tax notice
                      </option>
                      <option value="Business Income Return">
                        Business Income Return
                      </option>
                      <option value="TDS Return Filing">TDS Return Filing</option>
                      <option value="Service Tax Return">
                        Service Tax Return
                      </option>
                      <option value="Sales Tax Return">Sales Tax Return</option>
                      <option value="Employment Offer Letter">
                        Employment Offer Letter
                      </option>
                      <option value="Appointment Letter">
                        Appointment Letter
                      </option>
                      <option value="Employee Warning Letter">
                        Employee Warning Letter
                      </option>
                      <option value="Direct Selling Agent Agreement">
                        Direct Selling Agent Agreement
                      </option>
                      <option value="Sale Deed">Sale Deed</option>
                      <option value="Gift Deed">Gift Deed</option>
                      <option value="Residential Rental Agreement">
                        Residential Rental Agreement
                      </option>
                      <option value="Commercial Rental Agreement">
                        Commercial Rental Agreement
                      </option>
                      <option value="Founders Agreement">
                        Founders Agreement
                      </option>
                      <option value="Share Purchase Agreement">
                        Share Purchase Agreement
                      </option>
                      <option value="Invitation Bid Letter">
                        Invitation Bid Letter
                      </option>
                      <option value="Board Resolution Letter">
                        Board Resolution Letter
                      </option>
                      <option value="Automobile Sale Agreement">
                        Automobile Sale Agreement
                      </option>
                      <option value="Franchise Agreement">
                        Franchise Agreement
                      </option>
                      <option value="Service Complaint/ Adjustment Request">
                        Service Complaint/ Adjustment Request
                      </option>
                      <option value="Software Distribution Agreement">
                        Software Distribution Agreement
                      </option>
                      <option value="Website Maintenance Agreement">
                        Website Maintenance Agreement
                      </option>
                      <option value="Website Development Agreement">
                        Website Development Agreement
                      </option>
                      <option value="Website Terms and Conditions">
                        Website Terms and Conditions
                      </option>
                      <option value="Website Privacy Policy Agreement">
                        Website Privacy Policy Agreement
                      </option>
                      <option value="Agency Agreement">Agency Agreement</option>
                      <option value="Equipment Lease Agreement">
                        Equipment Lease Agreement
                      </option>
                      <option value="Breach of Contract Notice">
                        Breach of Contract Notice
                      </option>
                      <option value="Copyright Assignment Agreement">
                        Copyright Assignment Agreement
                      </option>
                      <option value="Business Loans">Business Loans</option>
                      <option value="Loan Against Property">
                        Loan Against Property
                      </option>
                      <option value="Merchant Cash Advance">
                        Merchant Cash Advance
                      </option>
                      <option value="Online Seller Finance">
                        Online Seller Finance
                      </option>
                      <option value="Equipment Financing">
                        Equipment Financing
                      </option>
                      <option value="Inventory Financing">
                        Inventory Financing
                      </option>
                      <option value="Invoice Financing">Invoice Financing</option>
                      <option value="Home Loans">Home Loans</option>
                      <option value="Personal Loans">Personal Loans</option>
                    </select>
                  </div>

                  <div className="col-12">
                    <textarea
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      name="Message"
                      value={formData.Message}
                      onChange={handleChange}
                      required
                      autocomplete="off"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

 {/* Success Modal */}

 <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header closeButton style={{ width: "100%" }}>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}

          >
            <Modal.Header closeButton style={{ width: "100%" }}>
              <Modal.Title style={{ fontSize: "28px" }}>Something Went Wrong !</Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                id="closeBtn"

                onClick={closePop}>
                Close
              </Button>
              {/* <Button variant="primary" id="closeBtn" onClick={closePop}>
                CLOSE
              </Button> */}
            </Modal.Footer>
          </Modal>
        )}
      </div>


    </div>
  );
};
export default HomePage;
