import React, { useState } from "react";
import $ from "jquery";
import axios from "axios";
import CPReg from "../static/img/copyrightReg.png";
import CPW from "../static/img/copyrigh.webp";
import SucWeb from "../static/img/Succ.webp";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css"; import { Helmet } from 'react-helmet';

const CopyRight = () => {
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>Copyright Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Trademark objections in India occur when applications do not comply with legal standards. Common reasons include similarity to existing trademarks and violation of public sentiments. Learn how to effectively respond to objections within 30 days with guidance from TaxOSmart." />
        <meta name="keywords" content="Trademark Objection, trademark application, legal standards, respond to trademark objection, intellectual property, TaxOSmart" />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="hero" class="hero d-flex align-items-center">
        <div class="container copyTop">
          <div class="Topbox">
            <div class="topcontent">
              <h1 className="fs-1">Copyright Registration</h1>
              <p className="fs-5">Get objection drafted</p>
              <div data-aos="" data-aos-delay="600">
                <div class="text-center text-lg-start" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img src={CPReg} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= About Section ======= --> */}
      <section>
        <div class="container-fluied" data-aos="">
          <div class="row gx-0">
            <div
              class="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div class="container content">
                <header class="section-header">
                  <h2>Copyright Registration</h2>
                  <br />
                </header>
                <p className="mt-0">
                  Copyright registration in India is enrolled under Copyright
                  Act, 1957. It provides a kind of safety so that the work
                  carried out by the creator of work cannot be copied by anyone
                  and to restore the uniqueness of the product. There are
                  bundles of rights under Copyright like communication to the
                  public, the rights of reproduction, adaptation, and
                  translation of the work.
                </p>
                <p>
                  Copyright is basically a legal right which has been provided
                  to the creators of literature, dramatics, musical and artistic
                  work and even the producer's films and sound recordings.
                  Sometimes even businesses and startups get copyright
                  registration related to instruction manuals, product
                  literature and user guides. Usually, copyright is possessed by
                  a creator of the work, but sometimes even the employer of its
                  creator or the person who has authorised the work can own the
                  copyright.
                </p>
                <p>
                  Copyright registration can be done through TaxOSmart in Delhi
                  NCR, Mumbai, Bengaluru, Chennai & all other Indian cities
                </p>
                <p>
                  Just like Trademark and Patent, Copyright is also one of the
                  intellectual property protection. The Copyright Act, 1957
                  helps you in protecting the original or genuine literature,
                  drama, music and artistic work. And ideas, procedures or even
                  the methods of operation can never be copyrighted. In a
                  layman's language, a copyright is an exclusive right granted
                  to the owner in order to protect his work from being copied,
                  exploited or misused.
                </p>
                <p>
                  Doing a particular work takes lots of efforts and energy and
                  therefore protecting the work done is as important as doing a
                  work. Hence, one of the mediums through which one can protect
                  as well as enjoy certain rights over his/ her works is
                  Copyright.
                </p>
                <p>
                  The only owner of registered work can use the product or even
                  can grant permission to another person. Copyrights are also
                  transferrable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <section id="features" class="features docsTop">
        <div class="container" data-aos="">
          <header class="section-header">
            <h2 class="mediaFont">What Is Included In Our Package?</h2>
          </header>
          <br />
          <div class="row">
            <div class="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Application Drafting</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Application Filing</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Government Fees</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Diary Number</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>

      <section id="features" class="features adTop">
        <div class="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div class="row feature-icons aos-init aos-animate mt-4" data-aos="">
            <h3>Advantages Of Copyright Registration</h3>

            <div class="row Legaldiv">
              <div class="col-xl-12 d-flex content">
                <div class="row align-self-center gy-4">
                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-desktop" aria-hidden="true"></i>

                    <div>
                      <h4>Legal Protection</h4>
                      <p>
                        The creators or owners of original work can always
                        protect their work through copyright registration. It
                        helps them against infringement.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i class="fa fa-shopping-bag" aria-hidden="true"></i>

                    <div>
                      <h4>Market Presence</h4>
                      <p>
                        With copyright registration, your creative work will get
                        promoted among mass in a single go. It can be easily
                        used for publicising or for marketing in the mind of the
                        customer.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i class="fa fa-suitcase" aria-hidden="true"></i>

                    <div>
                      <h4>Rights of the Owner</h4>
                      <p>
                        Rights over dissemination, translation, reproduction and
                        adaptation of work have been fully assigned to the
                        creator of work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-handshake" aria-hidden="true"></i>

                    <div>
                      <h4>Protection</h4>
                      <p>
                        Copyright registration helps you in protecting your
                        unique work and will act as an evidence in the court of
                        law over ownership of the work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i
                      class="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>

                    <div>
                      <h4>Restricts unauthorized Reproduction</h4>
                      <p>
                        It restricts to produce duplicity of the unique work and
                        give more preference to the owner of work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>

                    <div>
                      <h4>Creation of Asset</h4>
                      <p>
                        It generates an intellectual property. Registered
                        copyrights can be sold, commercially contracted or
                        franchised with the prior permission of the owner.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <section id="features" class="features docsTop">
        <div class="container" data-aos="">
          <header class="section-header">
            <h2 class="mediaFont">
              What All Things Are Protected Under A Copyright?
            </h2>
            <p>
              It basically protects the expression of ideas (e.g. words &
              illustrations), alone it cannot be protected. The following may be
              protected under copyright law:
            </p>
          </header>
          <br />

          <div class="row">
            <div
              class="col-lg-12 mt-5 mt-lg-0 d-flex 
            whyRecon"
            >
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Literary works (e.g., written books, computer programs,
                      website)
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Dramatic works (e.g., scripts for films and dramas)</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Musical works (e.g., melodies)</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Artistic works (e.g., paintings, photographs)</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Published editions</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Recorded Sounds</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Movies, Films and telefilms</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Broadcasts on Radio and Television</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features adTop">
        <div class="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div
            class="row feature-icons aos-init aos-animate mt-0 pt-4"
            data-aos=""
          >
            <h3>Details Required For Copyright Registration</h3>

            <div class="row mt-6">
              <div class="col-xl-12 d-flex content">
                <div class="row align-self-center gy-4 mt-4">
                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i class="fa fa-user" aria-hidden="true"></i>

                    <div>
                      <h4>Personal details</h4>
                      <p>
                        This includes the Name, Address and Nationality of
                        Applicant. Applicant should specify the nature. Whether
                        he is the owner or the representative?
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i class="fa fa-desktop" aria-hidden="true"></i>

                    <div>
                      <h4>Nature of the work</h4>
                      <p>
                        This includes class & description of the work, title of
                        the Work. In case of a website copyright, give the URL
                        of the website. You also need be mention the language of
                        the Work.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i class="fa fa-clock-o" aria-hidden="true"></i>

                    <div>
                      <h4>Date of Publication</h4>
                      <p>
                        Mention the date of Publication in internal magazines
                        (if possible). Like a company magazine or a research
                        paper submitted to a professor does not count as
                        publication
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <br />
      {/* <!-- End About Section --> */}

      <section class="lastsection pt-0">
        <div class="container">
          <div class="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div class="col-md-6 offset-md-3">
              <div class="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div class="col-md-12 ">
              <div class="faq" id="accordion">
                <div class="card panel">
                  <div class="card-header" id="faqHeading-1">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span class="badge">1</span>For what can Copyrights be
                        obtained?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    class="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        You can get a copyright for any unique and creative
                        content. This could range from design, sketch, painting,
                        song recording, music video, movie file, poem, book or
                        even a short story.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-2">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span class="badge">2</span>What is the validity of a
                        Copyright?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    class="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        Copyright protection typically lasts for 60 years. In
                        the case of original literary, dramatic, musical and
                        artistic works the 60-year period is counted from the
                        year following the death of the author. In the case of
                        cinematographic films, sound recordings, photographs,
                        anonymous and pseudonymous publications, works of
                        government and works of international organisations, the
                        60-year period is counted from the date of publication.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-3">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span class="badge">3</span> Who can apply for Copyright
                        registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    class="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        Any person claiming to be the owner of the work to be
                        copyrighted can apply for Copyright registration. The
                        person can be an individual, company, NGO or any other.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-4">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span class="badge">4</span> What is difference between
                        a Trademark and a Copyright?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    class="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        A trademark is a mark given to a brand to protect the
                        brand name, Logo or Slogan. A Copyright is a Protection
                        given to unique content such as books, music, videos,
                        songs or even software.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-5">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span class="badge">5</span> What are the documents
                        required to register Copyright?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    class="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        A copy of the work needs to be provided along with the
                        address and identity proof of the owner is required. For
                        a company, the name of the company along with its
                        Certificate of Incorporation and Address proof is
                        required.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-6">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span class="badge">6</span> Will my Copyright
                        registration be valid across the world?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    class="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        A copy of the work needs to be provided along with the
                        address and identity proof of the owner is required. For
                        a company, the name of the company along with its
                        Certificate of Incorporation and Address proof is
                        required.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-7">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span class="badge">7</span>Will my Copyright
                        registration be valid across the world?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    class="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        A Copyright is valid in India. However, if you have
                        filed a Copyright in India, you can use this as evidence
                        before any government to prove that you were using the
                        copyrighted content first.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card panel">
                  <div class="card-header" id="faqHeading-8">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span class="badge">8</span>What is difference between
                        Patent and Copyright?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    class="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        A Copyright is given to content, while a Patent is given
                        to an invention.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card panel">
                  <div class="card-header" id="faqHeading-9">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span class="badge">9</span> Can I sell or Transfer
                        Copyright Registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    class="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        A Copyright registration is an intellectual property,
                        which can be sold, transferred, gifted and franchised.
                        Copyright registration gives the owner of the work
                        ownership over the work and any person wishing to use
                        the work must obtain the registered copyright owners
                        consent.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="card panel">
                  <div class="card-header" id="faqHeading-10">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span class="badge">10</span>Is Copyright applicable for
                        all products?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    class="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        No. Copyright is applicable for only unique content. The
                        content can be book, song, film, software, fashion
                        designs.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-11">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-11"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-11"
                      >
                        <span class="badge">11</span> Who can apply for
                        Copyright?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-11"
                    class="collapse"
                    aria-labelledby="faqHeading-11"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        Whosoever has worked on unique content or produced some
                        unique content, song, or any data can apply for
                        copyright registration in India.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card panel">
                  <div class="card-header" id="faqHeading-12">
                    <div class="mb-0">
                      <h5
                        class="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-12"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-12"
                      >
                        <span class="badge">12</span>Can I register my name or
                        title?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-12"
                    class="collapse"
                    aria-labelledby="faqHeading-12"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      <p>
                        NO you cannot register your name or title. Name or
                        titles can only get trademark registration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--/ End Single News -->

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Copyright Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={CPReg}
                  style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Copy Right")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div class="modal-content">
            <div class="modal-header">
              <h4>
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span class="headingMsg"></span>
              </h4>
            </div>
            <div class="modal-body">
              <div class="d-flex">
                <div class="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="col-sm-9">
                  <div class="msg"></div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" class="modal" role="dialog">
        <div class="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div class="modal-content">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>
                  <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Copy Right Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={CopyRight}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)})</script>
    </div>
  );
};
export default CopyRight;
