import axios from "axios";
import React, { useState } from "react";
import "../assets/css/itrNon.css";
import itrNonFilter from "../static/img/ITR NON-FILERS STATUS LOGO final-01-01.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha'; import { Helmet } from 'react-helmet';

const ItrNon = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (


    <div>
      <Helmet>
        <title>Taxosmart | Non Filers Status</title>
        <meta charset="ISO-8859-1" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="As per Rule 114AAA of Income Tax Rules, 1962, PAN of taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021, and it shall be deemed that he has not furnished the PAN." />
        <meta name="keywords" content="Bulk PAN-Aadhaar Status, PAN-Aadhaar Status, Bulk PAN-Aadhaar" />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph meta tags for Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Non Filers Status - PAN-Aadhaar Linking" />
        <meta property="og:description" content="As per Rule 114AAA of Income Tax Rules, PAN of taxpayer shall become inoperative if not linked to Aadhaar by 31st March 2021." />
        <meta property="og:image" content="/path/to/image.jpg" />
        <meta property="og:url" content="https://www.taxosmart.com/ItrNon" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter meta tags --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.taxosmart.com/ItrNon" />
        <meta name="twitter:title" content="Non Filers Status - PAN-Aadhaar Linking" />
        <meta name="twitter:description" content="As per Rule 114AAA of Income Tax Rules, PAN of taxpayer shall become inoperative if not linked to Aadhaar by 31st March 2021." />


        {/* <!-- Bing meta tags --> */}

        <meta name="robots" content="index, follow" />

        {/* <!-- Canonical link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/ItrNon" />

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="InfoColor">
                Information About ITR NON-FILERS Status
              </h1>
              <p className="effort">
                HIGHER RATE OF TDS/TCS IN CASE OF INCOME TAX RETURN(ITR)
                NON-FILER The Finance Act, 2021 inserted new Sections 206AB and
                206CCA in the Income Tax Act, with effect from 1st July 2021
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={itrNonFilter}
                className="img-fluid gstImageCss" style={{ width: '100%' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section style={{ marginTop: "0%" }} className="pb-0">
        <div className="container-fluied" data-aos="">
          <div className="row gx-0">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div className="container content">
                <h1
                  className="   slideInDown"
                  style={{ color: "#0f316d", textAlign: "center" }}
                >
                  ITR NON-FILERS Status
                </h1>
                <br />
                <p className="decTop">
                  HIGHER RATE OF TDS/TCS IN CASE OF INCOME TAX RETURN(ITR)
                  NON-FILER The Finance Act, 2021 inserted new Sections 206AB
                  and 206CCA in the Income Tax Act, with effect from 1st July
                  2021, as a special provision for the deduction of higher rates
                  of TDS and TCS, respectively for the non-filers of an income
                  tax return.
                </p>

                <p>
                  The Non -Filers are persons who satisfies the following
                  conditions:
                </p>
                <ul>
                  <li>
                    A person who has not filed the Income Tax Return for two
                    previous years immediately prior to the previous year in
                    which tax is required to be deducted
                  </li>
                  <li>
                    The time limit of filing return of income under sub-section
                    (1) of section 139 is expired; and
                  </li>
                  <li>
                    The aggregate tax deducted at source or tax collected at
                    source, as the case maybe, is Rs. 50,000 or more in each of
                    the two previous years.
                  </li>
                </ul>
                <p>Rate of deduction under Section 206AB/206CCA</p>

                <ul>
                  <li>
                    The applicable TDS / TCS rate if the amount is paid/
                    credited /collected to/ from a Non-filer being higher than
                    the below rates:
                  </li>
                  <li>
                    at twice the rate specified in the relevant provision of the
                    Act; or
                  </li>
                  <li>Twice the rate /rates in force; or</li>
                  <li>The rate of 5%.</li>
                </ul>

                <p>
                  Thus, it is pertinent to have a valid pan & income tax return
                  non filer status to ascertain the rate at which TDS/TCS is to
                  be deducted.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="features"
        className="features keyTop"
        style={{ marginTop: "-5%" }}
      >
        <div className="container" data-aos="">
          <h1
            className="slideInDown mt-5"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            Key potential benefits at a glance:
          </h1>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Hosted on secured environment with adequate data security
                      measures
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Data received, stored and shared in password protected
                      Excel file
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Compliance with Aadhaar guidlines</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Result in substantial saving of time and efforts</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade pb-0" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Information About ITR NON-FILERS Status
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={itrNonFilter}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "ITR Non Filers Status")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ItrNon;
