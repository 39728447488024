import React, { useState } from "react";

import logo from "../static/img/taxosmartLogo.png";
import "../assets/css/style.css";

import "../assets/css/bootstrap.min.css";

import "../assets/css/navbar.css";

import $ from "jquery";

// javascript File //

function Home() {
  // Sticky Navbar
  $(window).scroll(function () {
    if ($(this).scrollTop() > 300) {
      $(".sticky-top").addClass("shadow-sm").css("top", "0px");
    } else {
      $(".sticky-top").removeClass("shadow-sm").css("top", "-100px");
    }
  });

  const [show, setshow] = useState(false);

  const add = () => {
    setshow(!show);
  };

  // const closeNavbar = () => {
  //   if (window.innerWidth <= 768) {
  //     setshow(false);
  //   }
  // };

  return (
    <>
      <div
        className="container-fluid bgColor p-0 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-3">
              <a className=" px-2 iconColor" href="">
                <i className="fa fa-phone-alt me-2"></i>+91 9967646171
              </a>
              <a className=" px-2 iconColor" href="mailto:info@taxosmart.com">
                <i className="fa fa-envelope-open  me-2"></i>info@taxosmart.com
              </a>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center">
              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="https://wa.me/7304343581"
              >
                <i className="fab fa-whatsapp iconColor"></i>
              </a>

              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="https://www.facebook.com/people/Tax-O-Smart/100082747576751/"
              >
                <i className="fab fa-facebook iconColor"></i>
              </a>
              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="https://in.linkedin.com/company/tax-o-smart-llp"
              >
                <i className="fab fa-linkedin-in iconColor"></i>
              </a>
              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="https://www.instagram.com/taxo_smart"
              >
                <i className="fab fa-instagram iconColor"></i>
              </a>
              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="https://x.com/Tax_O_Smart"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-twitter-x iconColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>
              </a>
              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="https://www.youtube.com/@tax-o-smart4271"
              >
                <i className="fab fa-youtube iconColor"></i>
              </a>
              <a
                className="btn btn-sm-square btn-outline-body me-1 iconColor"
                href="/ImportantDates"
              >
                <i className="fa fa-calendar iconColor"></i>
              </a>
              <a
                className="btn btn-sm-square btn-outline-body me-0 iconColor"
                href="/NewsArticle"
              >
                <i className="fab fa-blogger iconColor"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <b className="screen-overlay"></b>
      <nav
        className="navbar  hide-on-mobile navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn mobileNavbar "
        data-wow-delay="0.1s"
      >
        {/* <a  href="/homePage" className="navbar-brand ms-4 ms-lg-0">
            <h1 className=" m-0 imageNav" ><img className="me-3 mb imgWidthNav" src={logo} alt="Icon"/></h1>
        </a> */}
        <button
          type="button"
          className="navbar-toggler me-4 mb"
          data-toggle="collapse"
          data-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand ms-4 ms-lg-0 navMargin" href="/">
          <h1 className="ipadMargin colornav">
            <img
              className="imgCss hide-on-mobile "
              src={logo}
              alt="TAXOSMART"
            />
          </h1>
        </a>
        <div className="collapse navbar-collapse navTop" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link navFont dropdown-toggles textTransform"
                data-toggle="dropdown"
              >
                BusinessTax Software
              </a>
              <div className="dropdown-menu border-0 m-0">
                <a href="/TdsSoftware" className="dropdown-item">
                  TDS Software
                </a>
                <a href="/GstReconcile" className="dropdown-item">
                  GST Reconciliation Software
                </a>
                <a href="/gstInvoice" className="dropdown-item">
                  GST Invoice,Accounting And Inventory Management{" "}
                </a>
                <a href="/InvestmentProof" className="dropdown-item">
                  Investment Proof Verification Software{" "}
                </a>
                <a href="/BulkForm" className="dropdown-item">
                  Bulk Form 15CA & 15CB{" "}
                </a>
                <a href="/Einvoice" className="dropdown-item">
                  Smart E-Invoicing Software{" "}
                </a>
                <a href="BulkPan" className="dropdown-item">
                  Bulk PAN-Aadhaar Link Status{" "}
                </a>
                <a href="/PdfSighn" className="dropdown-item">
                  PDF Signer{" "}
                </a>
                <a href="/ItrNon" className="dropdown-item">
                  ITR Non-Filers Status{" "}
                </a>
                <a href="/As26Recon" className="dropdown-item">
                  26AS-Reconciliation{" "}
                </a>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle textTransform"
                data-toggle="dropdown"
              >
                Start Business
              </a>
              <div className="dropdown-menu border-0 m-0">
                <a href="/Pvt" className="dropdown-item">
                  Private Limited Co.
                </a>
                <a href="/Llp" className="dropdown-item">
                  Limited Liability(LLP)
                </a>
                <a href="/Opc" className="dropdown-item">
                  One Person Company{" "}
                </a>
                <a href="/PublicLtdCompany" className="dropdown-item">
                  Public Limited Company{" "}
                </a>
                <a href="/MSMEregistration" className="dropdown-item">
                  MSME/SSI Registration{" "}
                </a>
                <a href="/SocietyRegistration" className="dropdown-item">
                  Society Registration{" "}
                </a>
                <a href="/Section8Registeration" className="dropdown-item">
                  Section 8 Company(NGO){" "}
                </a>
                <a href="/NidhiCRegisrtation" className="dropdown-item">
                  Nidhi Company Registration
                </a>
                <a href="/IndianSubsidiary" className="dropdown-item">
                  Indian Subsidiary{" "}
                </a>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle textTransform"
                data-toggle="dropdown"
              >
                Registartion
              </a>
              <div className="dropdown-menu border-0 m-0">
                <a href="/Fssai" className="dropdown-item">
                  Fssai License
                </a>
                <a href="/IecRegistration" className="dropdown-item">
                  Import Export Code
                </a>
                <a href="/IsoRegistration" className="dropdown-item">
                  ISO Registration{" "}
                </a>
                <a href="/Dsc" className="dropdown-item">
                  Digital Signature (DSC{" "}
                </a>
                <a href="/EsiRegistration" className="dropdown-item">
                  ESI Registration{" "}
                </a>
                <a href="/Epf" className="dropdown-item">
                  Provident Fund Registration{" "}
                </a>
                <a href="/TradeLicence" className="dropdown-item">
                  Trade License{" "}
                </a>
                <a href="/GstRegistration" className="dropdown-item">
                  GST Registration{" "}
                </a>
                <a href="/ProfessionalTR" className="dropdown-item">
                  Professional Tax Registration{" "}
                </a>
                <a href="/TradeMarkReg" className="dropdown-item">
                  Trademark Registration{" "}
                </a>

                <a href="/TrademarkAssignment" className="dropdown-item">
                  Trademark Assignment{" "}
                </a>

                <a href="/TrademarkObjection" className="dropdown-item">
                  Trademark Objection{" "}
                </a>

                <a href="/CopyRight" className="dropdown-item">
                  Copyright Registration{" "}
                </a>

                <a href="/PatentRegistration" className="dropdown-item">
                  Patent Registration{" "}
                </a>
              </div>
            </div>
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle textTransform"
                data-toggle="dropdown"
              >
                Compliances
              </a>
              <div className="dropdown-menu border-0 m-0">
                <a href="/RocCompliances" className="dropdown-item">
                  ROC Compliances
                </a>
                <a href="/LlpCompliance" className="dropdown-item">
                  LLP Annual Filing
                </a>
                <a href="/CompanyAnnualfiling" className="dropdown-item">
                  Company Annual Filing
                </a>

                <a href="/AccountingBookKpg" className="dropdown-item">
                  Accounting/Bookkeeping
                </a>
                <a href="/ChangeInDirector" className="dropdown-item">
                  Change in Directors{" "}
                </a>
              </div>
            </div>

            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle textTransfrom"
                data-toggle="dropdown"
              >
                Tax Filing
              </a>
              <div className="dropdown-menu border-0 m-0 dropdown-menu-right">
                <a href="/IncomeTaxreturn" className="dropdown-item">
                  Income Tax Return
                </a>
                <a href="/RevisedReturnfiling" className="dropdown-item">
                  Revised Return Filing
                </a>

                <a href="/RespondTaxNotice" className="dropdown-item">
                  Respond To Tax Notice
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav
        className="navHead navbar  mediaNavbarView navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn mobileNavbar  d-lg-none"
        data-wow-delay="0.1s"
      >
        {/* <button
          type="button"
          className="navbar-toggler me-4 navMR"
          data-toggle="collapse"
          data-trigger="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <div className="toAlign">
          <button
            class="navbar-toggler"
            style={{ marginRight: "20px" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <a className="navbar-brand ms-4 ms-lg-0 navMargin" href="/">
            <h1 className="ipadMargin colornav">
              <img className="imgCss" src={logo} alt="TAXOSMART" />
            </h1>
          </a>
        </div>
      </nav>

      <nav
        // id="navbarCollapse"
        className="mobile-offcanvas navbar fixed-top navbar-expand-lg navbar-light d-lg-none"
        // data-toggle="collapse"
        // data-trigger="#navbarCollapse"
        // class="collapse"
        id="navbarToggleExternalContent"
      >
        <div className="container nav-cont">
          <div className="offcanvas-header" style={{marginTop:'-12%'}}>
            <img className="imageWidth" src={logo} alt="Icon" href="/" style={{marginLeft:'6%'}} />

            <button
              className="navbar-toggler btn-close"
              style={{ marginRight: "10px" }}
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa fa-close fs-2"></i>
            </button>
          </div>
          <ul className="navbar-nav d-block">
            <li className="nav-item dropdown">
              <a
                className="nav-link  dropdown-toggle textTransform"
               id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              >
                {" "}
                BusinessTax Software
              </a>
              <ul className="dropdown-menu">
                <li>
                  {" "}
                  <a href="/TdsSoftware" className="dropdown-item">
                    TDS Software
                  </a>
                </li>
                <li>
                  <a href="/GstReconcile" className="dropdown-item">
                    GST Reconciliation Software
                  </a>
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="gstInvoice" className="dropdown-item">
                    GST Invoice,Accounting And Inventory{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="InvestmentProof" className="dropdown-item">
                    Investment Proof Verification Software{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="BulkForm" className="dropdown-item">
                    Bulk Form 15CA & 15CB{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="Einvoice" className="dropdown-item">
                    Smart E-Invoicing Software{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/BulkPan" className="dropdown-item">
                    Bulk PAN-Aadhaar Link Status{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/PdfSighn" className="dropdown-item">
                    PDF Signer{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/ItrNon" className="dropdown-item">
                    ITR Non-Filers Status{" "}
                  </a>
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/As26Recon" className="dropdown-item">
                    26AS-Reconciliation{" "}
                  </a>
                </li>
              </ul>
            </li>
          
            <li className="nav-item dropdown">
              <a
                className="nav-link  dropdown-toggle textTransform"
                 id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              >
                Start Business
              </a>
              <ul className="dropdown-menu">
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/Pvt" className="dropdown-item">
                    Private Limited Co.
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/Llp" className="dropdown-item">
                    Limited Liability(LLP)
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/Opc" className="dropdown-item">
                    One Person Company{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/PublicLtdCompany" className="dropdown-item">
                    Public Limited Company{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/MSMEregistration" className="dropdown-item">
                    MSME/SSI Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/SocietyRegistration" className="dropdown-item">
                    Society Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/Section8Registeration" className="dropdown-item">
                    Section 8 Company(NGO){" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/NidhiCRegisrtation" className="dropdown-item">
                    Nidhi Company Registration
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/IndianSubsidiary" className="dropdown-item">
                    Indian Subsidiary{" "}
                  </a>{" "}
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link  dropdown-toggle textTransform"
                id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              >
                Registration
              </a>
              <ul className="dropdown-menu">
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/fssai" className="dropdown-item">
                    Fssai License
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/iecRegistration" className="dropdown-item">
                    Import Export Code
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/isoRegistration" className="dropdown-item">
                    ISO Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/dsc" className="dropdown-item">
                    Digital Signature (DSC{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/EsiRegistration" className="dropdown-item">
                    ESI Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/epf" className="dropdown-item">
                    Provident Fund Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/TradeLicence" className="dropdown-item">
                    Trade License{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/GstRegistration" className="dropdown-item">
                    GST Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/ProfessionalTR" className="dropdown-item">
                    Professional Tax Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/TradeMarkReg" className="dropdown-item">
                    Trademark Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/TrademarkAssignment" className="dropdown-item">
                    Trademark Assignment{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/TrademarkObjection" className="dropdown-item">
                    Trademark Objection{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/CopyRight" className="dropdown-item">
                    Copyright Registration{" "}
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/PatentRegistration" className="dropdown-item">
                    Patent Registration{" "}
                  </a>{" "}
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link  dropdown-toggle textTransform"
                id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              >
                Compliances
              </a>
              <ul className="dropdown-menu">
                <li data-trigger="#navbarCollapse">
                  <a href="/RocCompliances" className="dropdown-item">
                    ROC Compliances
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/llpCompliance" className="dropdown-item">
                    LLP Annual Filing
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/CompanyAnnualfiling" className="dropdown-item">
                    Company Annual Filing
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/AccountingBookKpg" className="dropdown-item">
                    Accounting/Bookkeeping
                  </a>{" "}
                </li>
                <li data-trigger="#navbarCollapse">
                  <a href="/changeInDirector" className="dropdown-item">
                    Change in Directors{" "}
                  </a>{" "}
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link  dropdown-toggle textTransform"
               id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              >
                Tax Filing
              </a>
              <ul className="dropdown-menu">
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/IncomeTaxreturn" className="dropdown-item">
                    Income Tax Return
                  </a>
                </li>
                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/RevisedReturnfiling" className="dropdown-item">
                    Revised Return Filing
                  </a>
                </li>

                <li data-trigger="#navbarCollapse">
                  {" "}
                  <a href="/respondTaxNotice" className="dropdown-item">
                    Respond To Tax Notice
                  </a>{" "}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <div class=" contact_fixed ">
		<div>
			<span> <i class="fab fa-whatsapp" aria-hidden="true"></i><a
				href="https://api.whatsapp.com/send?phone=919967646171&text=Hi%20Team%20R J Soni and Associates%2C%0A%20%21%20I%20am%20interested%20to%20inquire%20about%20your%20services.%20Please%20touch%20base%20with%20me.%20Thanks.%20&source=&data=%22"
				style={{color: '#fff'}} target="_blank">&nbsp;&nbsp;9967646171</a>
			</span><br /> <span> 
			<i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;<a href="mailto:info@rjsa.in" style={{color: '#fff'}}>info@rjsa.in</a>
			</span>		</div>
	</div>
      <script src="https://www.google.com/recaptcha/api.js" async defer></script>
    </>
  );
}

export default Home;
