import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/Section8reg.css";
import Sec8jpg from "../static/image/img/section8.jpg";
import Sec8Comjpg from "../static/assetse/img/section8Com.jpg";
import Section8png from "../static/assetse/img/section8.png";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
const Section8Registeration = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Section 8 Company Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="A Section 8 Company is a Non-Profit Organization (NPO) focused on promoting arts, education, social welfare, and more. Learn about its registration process and how it operates like a limited company without using 'Limited' in its name." />
        <meta name="keywords" content="Section 8 company registration, Non-Profit Organization, NPO, charitable organization, company formation, social welfare, arts, education." />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* <!-- ======= Call To Action Section ======= --> */}
      <section id="call-to-action" className="call-to-action ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h3 className="fs-2">Section 8 Company Registration</h3>

              <a
                className="cta-btn"
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                data-bs-backdrop="false"
                href=""
              >
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section -->
<!-- End Hero --> */}

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about mt-0 pb-0">
          <div className="container-fluied" data-aos="">
            <div className="row gx-0">
              <div
                className="col-lg-12 d-flex flex-column justify-content-center"
                data-aos=""
                data-aos-delay="200"
              >
                <div className="container content">
                  <h3 className="fs-2">Who We Are</h3>
                  <header className="section-header mt-0">
                    <p className="fs-5">What is LLP Registration?</p>
                    <br />
                  </header>
                  <p className="mt-0">
                    LLP refers to Limited Liability Partnership and is governed
                    by Limited Liability Partnership Act 2008. Limited Liability
                    Partnership provides advantage of limited liability to its
                    owners and at the same time requires minimal maintenance.
                    The directors of a Private Limited Company have limited
                    liability to creditors. In case of default, banks /
                    creditors can only sell company's assets and not personal
                    assets of directors.
                  </p>
                  <p>
                    LLP Registration can be done through TaxOSmart in Delhi NCR,
                    Mumbai, Bengaluru, Chennai and all other Indian cities.
                  </p>
                  <p>
                    LLP is a Separate Legal Entity that gives the benefit of
                    limited liability of company plus the flexibility of
                    partnership, wherein no partner's is held liable on account
                    of other's partner misconduct and their rights and duties to
                    be governed by LLP agreement. The basic premise behind the
                    introduction of Limited Liability Partnership (LLP) is to
                    provide a form of business organization that is simple to
                    maintain while at the same time providing limited liability
                    to the owners.
                  </p>
                  <p>Choose LLP because:</p>
                  <ul>
                    <li>Dual advantages- Company and a Partnership.</li>
                    <li>
                      No partner will be responsible for other partner's
                      misconduct.
                    </li>
                    <li>
                      Cheaper to incorporate than a Private Limited Company.
                    </li>
                    <li>Limits the liabilities of its partners.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features mt-0 pt-0">
          <div className="container">
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img src={Sec8Comjpg} className="img-fluid mt-0" alt="" />
              </div>
              <div className="col-md-7 mt-7">
                <h3 className="fs-2">Section 8 Company Registration</h3>
                <p className="fst-italic">
                  A Section 8 Company is an organization which is registered as
                  a Non-Profit Organization (NPO). NPO/company has its objective
                  of promotion of arts, commerce, charity, education, protection
                  of environment, science, social welfare, sports, research,
                  religion and intends to apply its profits, if any, or other
                  income in promoting its objectives. It functions exactly like
                  a limited company including all the rights and obligations
                  that come with such a company. However, it differs from a
                  company in one very crucial aspect, i.e. it cannot use the
                  words "Section 8" or "Limited" in its name.
                </p>
                <p>What Is Included In Our Package?</p>
                <ul>
                  <li className="fst-italic">
                    <i className="bi bi-check"></i> DIN For 2 Partners.
                  </li>
                  <li className="fst-italic">
                    <i className="bi bi-check"></i> Digital Signature For 2
                    Directors
                  </li>
                  <li className="fst-italic">
                    <i className="bi bi-check"></i> Name search and Approval
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- Features Item --> */}

            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img src={Section8png} className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3 className="fs-2">Choose LLP because</h3>
                <p className="fst-italic">
                  The income of the Company must be used to promote only
                  charitable objects and cannot pay any dividend to the members
                  of the company. The Central government provides an
                  incorporation certificate to all such companies and also
                  informs them about some restrictions and conditions. In case
                  they don't fulfil them, the Central government may also order
                  them to wind up the company. In case fraud objectives of the
                  Company are proved, legal action will be taken against all
                  officers of the Company. Section 8 Company Registration can be
                  done through TaxOSmart in Delhi NCR, Mumbai, Bengaluru,
                  Chennai and other Indian cities.
                </p>
                <p className="fst-italic">
                  In India, a non-profit organisation can be created by
                  registering a Society under the Societies Registration Acts or
                  registering a Trust or incorporating a Section 8 company with
                  a not for profit motive. Section 8 under the Companies Act,
                  2013 corresponds to the old Section 25 under the Companies
                  Act, 1956, which was popularly known as "Section 25 Company",
                  hence the name "Section 8 Company".
                </p>
              </div>
            </div>
            {/* <!-- Features Item --> */}
          </div>
        </section>
        {/* <!-- End Features Section -->

<!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop documentsReq">
          <div className="container" data-aos="">
            <div className="section-header">
              <h2>Documents Required for Section 8 Company Registration</h2>
            </div>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Digital Signature Certificate (if you already have it)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Director Identification Number (if you already have it)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Memorandum of Association</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Articles of Association</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        ID proof for members: (Aadhar Card, Passport, Voter ID)
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Address Proof</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}
      <section id="features" className="features featuresTop advantageTop">
        <div className="container" data-aos="">
          <div className="section-header pt-0">
            <h2>Advantages Of Section 8 Company Registration</h2>
          </div>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No Minimum Share Capital Requirement</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No Stamp Duty</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Easy Transfer of Ownership</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Clear Objectives for easy donations and loans</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Tax Benefits</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Easy to Manage</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No need to use "Section 8" or "Limited" title</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      <section id="features" className="features stepTop pt-0 mt-0">
        <div className="container aos-init aos-animate" data-aos="">
          <br />
          <br />

          {/* <!-- Feature Icons --> */}
          <div
            className="row feature-icons aos-init aos-animate mt-0"
            data-aos=""
          >
            <div className="section-header pt-0 pb-0">
              <h2>Steps to Incorporate Section 8 Company Registration</h2>
            </div>
            <br />
            <div className="row mt-5">
              <div className="col-xl-12 d-flex content">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Application of DSC & DPIN:</h4>
                      <p>
                        First of all, the partners have to apply for Digital
                        signature and DPIN. Digital signature is an online
                        signature used of filing and DPIN refers to Directors
                        PIN number issued by MCA. If the directors already have
                        DSC and DPIN, then this step can be skipped.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="100"
                  >
                    <i className="ri-stack-line"></i>
                    <div>
                      <h4>Name Approval:</h4>
                      <p>
                        You have to provides 3 different name options to MCA of
                        which one will be selected. Names provided should
                        ideally be unique and suggestive of company business.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="ri-command-line"></i>
                    <div>
                      <h4>Approval of other authorities:</h4>
                      <p>
                        The Registrar of Companies may require the applicant to
                        furnish the approval or concurrence of any department,
                        regulatory body, appropriate authority, or Ministry of
                        the Central or State Government(s) in relation to the
                        work to be done.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Get Incorporation Certificate:</h4>
                      <p>
                        It typically takes 15- 25 days to form a Private limited
                        company and get the incorporation certificate.
                        Incorporation certification is a proof that company has
                        been created. It also includes your CIN number.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="ri-command-line"></i>
                    <div>
                      <h4>MOA & AOA submission:</h4>
                      <p>
                        Once the licence is obtained, one needs to draft
                        Memorandum of Association and Articles of Associate. But
                        the object of the company must always be a charitable
                        object. Both MOA and AOA are filed with the MCA with the
                        subscription statement.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Apply for PAN, TAN and Bank account:</h4>
                      <p>
                        Then you need to apply for PAN and TAN. PAN and TAN are
                        received in 7 working days. Post this, you can submit
                        the Incorporation certificate, MOA, AOA and PAN with
                        bank to open your bank account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <br />
      <br />
      <section className="stepTop">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card panel">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-bs-toggle="collapse"
                        data-bs-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span> Is it necessary to have
                        2 directors for Section 8 Company Registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, you need a minimum of two directors for a section 8
                        company registration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Who can become a member
                        in section 8 Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Any individual/organization can become the member of
                        Section 8 Company including foreigners/NRI's.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span> For incorporation of
                        Section 8 Company, do I have to be present in person?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The entire procedure is 100% online and you don't have
                        to be present at our office or any other office for
                        incorporation. A scanned copy of documents has to be
                        sent via mail.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span> Are there any other
                        payments to be made for incorporation certificate in
                        addition to fee paid at the time of registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        There is absolutely no other payment. Our charges are
                        inclusive of everything. We will send you an invoice
                        with no hidden charges. Our team provides you support
                        till you get your bank account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span> Are there any other
                        payments to be made for incorporation certificate in
                        addition to fee paid at the time of registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        There is absolutely no other payment. We will send you
                        an invoice that is all-inclusive, with no hidden
                        charges.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> Time taken by
                        TaxOSmart.com in forming a Section 8 Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart.com can incorporate a section 8 Company in
                        less than 20 days. The time taken also depends on
                        relevant documents provided by the applicant and speed
                        of approvals from government. To ensure speedy
                        registration, please pick a unique name for the proposed
                        Company and make sure you have all the required
                        documents prior to starting the registration process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span> Is it necessary to have
                        a charitable objective to register a Section 8 company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, it is necessary to have a non-profit motive to
                        incorporate a Section 8 company.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span className="badge">8</span> What is the life span
                        of the Section 8 company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The life span of the company is not dependent on or
                        related to the life of any particular person(s) or
                        individual(s). It could go on indefinitely until it
                        accomplishes its objective, merges with another such
                        company, or goes bankrupt.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">9</span>In which cities does
                        TaxOSmart incorporate Section 8 Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart provides Section 8 Company incorporation
                        services across India in all cities. We have done
                        Section 8 company registration in Mumbai, Delhi,
                        Gurgaon, Noida, Bangalore, Chennai, Hyderabad,
                        Ahmedabad, Kolkata, Surat, Pune, Jaipur, Lucknow,
                        Kanpur, Nagpur and other Indian cities.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span className="badge">10</span> Why should I choose
                        TaxOSmart for Section 8 Company registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart.com is one of the best online service portals
                        to help register Section 8 Companies in India. We have
                        10 years of experience in application of Section 8
                        Company registration. TaxOSmart has 30+ regional offices
                        in India and expand its network in India with 2000+
                        customers for Section 8 Company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--/ End Single News -->

      <!-- Modal --> */}

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Section 8 Company Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Sec8Comjpg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Section 8 Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default Section8Registeration;
