import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import SocRegjpg from "../static/image/img/societyReg.jpg";
import SocRegweb from "../static/img/societyReg.webp";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

const SocietyRegistration = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };
    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>Society Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="A society is an organized group focused on charitable activities, registered under The Societies Registration Act, 1860. Learn about society registration, its objectives, and the roles of governing and general body members." />
        <meta name="keywords" content="society registration, charitable society, Societies Registration Act 1860, non-profit organization, governing body, general body, TaxOSmart." />
        <meta name="author" content="TaxOSmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1
                className="display-1 animated slideInDown fs-1"
                style={{ color: "#0f316d" }}
              >
                Society Registration
              </h1>
              <p className="text-success">
                Ideal Business Structure for Small Traders and Merchants.
              </p>

              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img src={SocRegweb} className="img-fluid gstImageCss" alt="" />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="" className=" societyTop">
          <div className="container-fluied" data-aos="">
            <div className="row gx-0">
              <div
                className="col-lg-12 d-flex flex-column justify-content-center"
                data-aos=""
                data-aos-delay="200"
              >
                <div className="container content">
                  <div className="section-header mt-0 pt-0">
                    <h2>What Is Society Registration?</h2>
                  </div>
                  <p className="mt-0 text-justify">
                    A Society is an organized group of persons whose main
                    objective is to serve society and not to earn a profit. A
                    society is registered under The Societies Registration Act,
                    1860 to become a legal entity. A society exists for the
                    purpose of charitable activities such as poverty relief,
                    arts, education, religion, cultures and sports. The
                    Governing body members are entrusted with the management of
                    the affairs of the society. Whereas, the General Body
                    member's duties include the election of governing body
                    members, alteration of rules and regulations, name and
                    objects, if necessary. A foreigner can also become a society
                    member in India.
                  </p>
                  <p>Society Registration is done through TaxOSmart.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features docsTop pt-0">
          <div className="container" data-aos="">
            <div className="section-header mt-0 pt-0">
              <h2>Documents Required for Society Registration</h2>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Affidavit No. 1 regarding ownership and NOC for
                        registered office of society
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Affidavit No. 2 regarding persons not related to each
                        other and name of society
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>ID Proofs of all the members</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Address Proof of all the members</h3>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Electricity/ Water bill (Business Place)</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Copy of Property papers (If owned property)</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Landlord NOC (Format will be provided)</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features pt-0">
          <div className="container" data-aos="">
            <div className="section-header mt-0 pt-0">
              <h2>Advantages Of Society</h2>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Independent judicial persons</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>File cases</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Exemptions from income tax</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Easily inheritable</h3>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Can purchase property</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="500"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Secured assets</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features  societyTop mt-0 pt-0">
          <div className="container" data-aos="">
            <div className="section-header mt-0 pt-0">
              <h2>Important Points For Society Registration</h2>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Minimum 7 Members required No PAN Card required</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        The objective shall be scientific, literary & for
                        charitable purpose
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>No stamp paper required.</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      <br />
      <br />

      <section className="pt-0" id="frequently">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card panel">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span> Can Societies be formed
                        for profits?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, societies are formed to serve society and not to
                        earn profits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Where can I Register a
                        Society?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Registrar of societies i.e., District Registrar of a
                        District is appointed and has the power to register the
                        societies. Societies can be registered with the
                        registrar of the District in which the society is
                        intended to be established.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span> What is the maximum
                        number of people to form a Society/Association?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        A minimum number of 7 persons who are above 18 years of
                        age can form a society or an association. There is no
                        such limit for maximum number of persons.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span>For what reasons a
                        society/association is formed?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        1. Association can be formed for the following reasons:
                      </p>
                      <ul>
                        <li>
                          2. Arts, literature, educational, cultural, charity,
                          and religious
                        </li>
                        <li>3. Fine Arts, crafts</li>
                        <li>4. Knowledge of commerce and industry</li>
                        <li>
                          5. Promotion of scientific temper in the society
                        </li>
                        <li>6. Political education and training</li>
                        <li>7. Sports (excluding games of chance)</li>
                        <li>
                          8. Diffusion of any knowledge or any public purpose,
                        </li>
                        <li>9. Maintaining Library</li>
                        <li>
                          10. Collection/preservation of historical monuments,
                          technical and philosophical research.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span> What is the Difference
                        between 'Trust' and 'Society' registered under Andhra
                        Pradesh and Telangana Societies Registration Act 2001?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>Society</p>
                      <ul>
                        <li>
                          1.District Registrar and Registrar of Societies is the
                          competent authority to register.
                        </li>
                        <li>
                          2.Aims and objectives are for the development/welfare
                          of the entire society without restriction.
                        </li>
                        <li>
                          3.At least 7 persons are required to form a Society.
                        </li>
                        <li>
                          4.The official board manages, subject to the approval
                          of the general body.
                        </li>
                        <li>
                          5.Accounts should be recorded to the Registrar of
                          Societies
                        </li>
                        <li>6.There is provision to add members.</li>
                        <li>
                          7.In order to cancel an association, action can be
                          taken only after an enquiry.
                        </li>
                        <li>
                          8.Profit can be used only for the development of
                          society. It cannot be distributed among the members.
                        </li>
                      </ul>

                      <br />

                      <p>Trust</p>
                      <ul>
                        <li>
                          Trust deed can be registered in Office of the Sub
                          Registrar.
                        </li>
                        <li>
                          There are two kinds of Trusts Public and Private. The
                          latter is a private trust for the beneficiaries.
                        </li>
                        <li>
                          Anyone can form Trust without restriction of a number
                          of persons.
                        </li>
                        <li>
                          Official board of trustees runs it. Managing trustee
                          runs it.
                        </li>
                        <li>
                          Management is under the control of the management of
                          Trustees and Managing Trustees.
                        </li>
                        <li>Does not have any significant bearing.</li>
                        <li>
                          Trust deed can be registered in Office of the Sub
                          Registrar.
                        </li>
                        <li>
                          Trustees cannot share. Beneficiaries might be
                          permitted to utilize the benefit.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> What guidelines should
                        be kept in mind while deciding a name for the Society?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The name should not be Similar to one effectively
                        existing in the District. Name denied or registered by
                        State or Central Government through official guidelines
                        or through Emblems and Names (Prevention of Improper
                        Use) Act, 1950. Indian Institute or University.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span> What are the follow-up
                        actions after the Society is registered?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        You need to start the company with a minimum capital of
                        Rs. 100,000. However, you need not pay this amount to
                        the bank. You can also show that the capital has been
                        utilised as the pre-incorporation expenses of the
                        startups e.g, private limited registration expenses. You
                        can also show this capital has been infused in form of
                        assets such as computers etc.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span className="badge">8</span> Do I need a proper
                        office address to start a company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The society should present a list to the Registrar of
                        societies within fifteen days from the date of the
                        General Body meeting every year. The list should contain
                        the names and address of each member of the Managing
                        Committee (Executive Committee) and officers entrusted
                        with the administration of the society. Executive
                        Committee should meet at least once in three months, and
                        General Body shall meet at least once in a year.
                        Amendments, if any, made should be recorded with the
                        Registrar.
                      </p>
                      <p>
                        Minutes of all the meetings should be recorded with the
                        Registrar of Societies.
                      </p>
                      <p>
                        Society should choose Executive Committee members as per
                        its by-law.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">9</span>Can memorandum, by the
                        law of Society, be revised?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Memorandum can be revised by endorsement in a unique
                        General Body Meeting called for the purpose after giving
                        due notification and by approval another special General
                        Body Meeting called after 30 days again. Bylaws can be
                        altered by approval of a majority of members in Special
                        General Body Meeting. Such corrected by-laws shall be
                        filed with the Registrar within one month.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span className="badge">10</span>Why a Private Limited
                        Company is called a Separate Legal Entity
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        A company is a legal entity and a different person
                        established under the Act. A company is treated as a
                        different person which can own property and have
                        creditors or debts. The members (Shareholders/Directors)
                        of a company will have no liability to creditors of a
                        company in case the company is unable to pay the debts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-11">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-11"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-11"
                      >
                        <span className="badge">11</span> Can anyone other than
                        a member of Society review records of society and
                        duplicate it?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-11"
                    className="collapse"
                    aria-labelledby="faqHeading-11"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, since records of the Society are regarded as public
                        records. Under section 24, any person can get a
                        duplicate copy of it and create a proof.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* // <!-- Modal --> */}

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Society Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={SocRegweb}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Society Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init()</script>
    </div>
  );
};

export default SocietyRegistration;
