import axios, { Axios } from "axios";
import React, { useState } from "react";
import "../assets/css/as26Recon.css";
import asReconcilationImage from "../static/img/26AS.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from 'react-google-recaptcha';
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';


const As26Recon = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }


    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>Taxosmart | 26 AS Reconciliation</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="As per Rule 114AAA of Income Tax Rules, 1962, the PAN of a taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021." />
        <meta name="keywords" content="26 AS Reconciliation, Bulk PAN-Aadhaar Status, PAN-Aadhaar Status" />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="26 AS Reconciliation" />
        <meta property="og:description" content="As per Rule 114AAA of Income Tax Rules, 1962, the PAN of a taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021." />
        <meta property="og:url" content="https://www.taxosmart.com/As26Recon" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="26 AS Reconciliation" />
        <meta name="twitter:description" content="As per Rule 114AAA of Income Tax Rules, 1962, the PAN of a taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021." />
        <meta name="twitter:url" content="https://www.taxosmart.com/As26Recon" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/As26Recon" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="InfoColor">26 AS Reconciliation Status</h1>
              <p className="effort">
                Enterprises often run the risk of losing tax credits (i.e. TDS
                and TCS) due to lack of regular reconciliation between books of
                accounts and the government records.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={asReconcilationImage}
                className="img-fluid ReconImageCss" style={{ width: '100%' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features keyTop mt-2">
        <div class="container" data-aos="">
          <h1
            class=" slideInDown fs-2 "
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            26AS Reconciler - TDS and TCS reconciliation with Form 26AS:
          </h1>
          <br />
          <b>
            <p style={{ marginTop: "0%" }}>
              Enterprises often run the risk of losing tax credits (i.e. TDS and
              TCS) due to lack of regular reconciliation between books of
              accounts and the government records. This also becomes a key risk
              area for levy of interest and penalties. It also fends itself to
              generate voluminous data that can be difficult to manage. 26AS
              Reconciler is our technology enabled service for managing TDS and
              TCS credits as per Form 26AS and books of accounts and minimising
              leakages. Analytical dashboards with actionable reports are
              enabled for digitally managing such credits.
            </p>
          </b>

          <div class="row" style={{ marginTop: "2%" }}>
            <div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div class="row align-self-center gy-4">
                <div class="col-md-12" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Technology enabled reconciliation of TDS and TCS credits
                      for enhancing accuracy
                    </h3>
                  </div>
                </div>

                <div class="col-md-12" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Significantly reduces time in processing low value adding
                      activities
                    </h3>
                  </div>
                </div>

                <div class="col-md-12" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Proactive tracking of discussions with tax
                      deductor/collector for corrections due to identified
                      mismatches
                    </h3>
                  </div>
                </div>

                <div class="col-md-12" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Correct claim of TDS and TCS credit thereby mitigating
                      working capital inefficiencies due to tax outflow
                    </h3>
                  </div>
                </div>

                <div class="col-md-12" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Better control over organisation's TDS and TCS credits
                    </h3>
                  </div>
                </div>
                <div class="col-md-12" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Data driven insights to mitigate TDS and TCS leakages.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="features"
        class="features keyTop"
        style={{ marginTop: "-5%" }}
      >
        <div class="container" data-aos="">
          <h1
            class="slideInDown mt-5 fs-2"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            Key potential benefits at a glance:
          </h1>
          <br />

          <div class="row">
            <div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Hosted on secured environment with adequate data security
                      measures
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Data received, stored and shared in password protected
                      Excel file
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Compliance with Aadhaar guidlines</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Result in substantial saving of time and efforts</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                26 AS Reconciliation Status
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={asReconcilationImage}
                  alt=""
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <form
                    onSubmit={handleSubmit}
                    style={{ border: "0px solid black" }}
                  >
                    <div className="form-group">
                      <input
                        className="form-control"
                        style={{ display: "none" }}
                        type="text"
                        id="type"
                        name="type"
                        value={(formData.type = "26 AS Reconciliation")}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="mobile"
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile"
                        className="form-control"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group" style={{ marginTop: '20px' }}>
                      <ReCAPTCHA
                        sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                        onChange={handleCaptchaChange} />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '3%' }}>
                      <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default As26Recon;
