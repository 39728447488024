import axios from "axios";
import React, { useState } from "react";
import "../../assets/css/style.css";

import "../../assets/css/bootstrap.min.css";

import "../../assets/css/navbar.css";
import SucWeb from "../../static/img/Succ.webp";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactUs = () => {
  
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		mobile: '',
    service : '',
    comment : '',
	});
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

  
	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [errorModalVisible, setErrorModalVisible] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		axios.post('/apiCustomerDetail/', formData)
			.then(response => {
				setSuccess('Registration successful!');
        setSuccessModalVisible(true); // Show success modal
				setError('');
				setFormData({
					type :'ContactUs',
					name: '',
					email: '',
					mobile: '',
          service: '',
          comment : ''
				});
			})
			.catch(error => {
				setError('Error during registration: ' + error.message);
        setErrorModalVisible(true);
				setSuccess('');
			});
	};

  const handleCloseSuccess = () => setSuccessModalVisible(false);
	const handleCloseError = () => setErrorModalVisible(false);

	// Use to close the second popup box
	const [isVisible, setIsVisible] = useState(true);

	const closePop = () => {
	  setIsVisible(false);
	  window.location.reload();
	};
  return (
    <div>
      <div 
        className="container-fluid page-header py-5 mb-5 wow fadeIn ng-scope"
        data-wow-delay="0.1s"
      >
        <div className="container py-5" >
          <h1 className="display-1 text-white fs-115 slideInDown">
            Contact Us
          </h1>
          <div className="d-flex"></div>
        </div>
      </div>

      {/* <!-- Appointment Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h4 className="section-title text-end">Contact Us</h4>
              <h1 className="display-5 mb-4 fs-1">
                We'd love to hear from you!
              </h1>
              <div className="row g-4">
                <div className="col-12">
                  <div className="d-flex info">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light w-65 h-65">
                      <i className="fa fa-2x fa-phone-alt "></i>
                    </div>
                    <div className="ms-4 expertInfo">
                      <p className="mb-2">TALK TO AN EXPERT</p>
                      <h3 className="mb-0">+91 9967 646 171</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex info">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light">
                      <i className="fa fa-2x fa-envelope-open "></i>
                    </div>
                    <div className="ms-4 expertInfo">
                      <p className="mb-2">FOR QUERIES</p>
                      <h3 className="mb-0">info@taxosmart.com</h3>
                    </div>
                  </div>
                </div>

                {/* <!-- <div className="col-12">
						<div className="d-flex info">
							<div
								className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light"
								>
								<i className="fa fa-2x fa-envelope-open " style="color: #0e306d"></i>
							</div>
							<div className="ms-4 expertInfo">
								<p className="mb-2">Registered Office:</p>
								<h3 className="mb-0">Kamala Niwas,Plot
									No.136/141, RDP 1,Near Gorai Bridge,Gorai,
									Borivali(W),Mumbai-400 091.</h3>
							</div>
						</div>
					</div> --> */}
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <form onSubmit={handleSubmit}  style={{border: '0px solid black'}}>
              <div className="row g-3">

              <input
                    type="text"
                    className="form-control"
                    name="name"
                  	value={formData.type='ContactUS'}
											onChange={handleChange}
											required
                      style={{display:'none'}}
                    placeholder="Your Name"
                  />
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                  	value={formData.name}
											onChange={handleChange}
											required
                    placeholder="Your Name"
                  />
                   
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="email"
                    className="form-control"
                    // type="text"
                    name="email"
                    placeholder="Email Id"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder="Mobile"
                    required
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <select  	value={formData.service}
											onChange={handleChange}
											required
                      name="service"
                    className="form-select fs-55"
                    placeholder="Search for Service"
                  >
                    <option
                      className="text-black"
                      value="Private Limited Company"
                    >
                      Private Limited Company
                    </option>
                    <option value="Limited Liability Partnership">
                      Limited Liability Partnership
                    </option>
                    <option value="Partnership Registration">
                      Partnership Registration
                    </option>
                    <option value="One Person Company">
                      One Person Company
                    </option>
                    <option value="Public Limited Company">
                      Public Limited Company
                    </option>
                    <option value="MSME/SSI Registration">
                      MSME/SSI Registration
                    </option>
                    <option value="Society Registration">
                      Society Registration
                    </option>
                    <option value="Section 8 Company">Section 8 Company</option>
                    <option value="Nidhi Company Registration">
                      Nidhi Company Registration
                    </option>
                    <option value="Indian Subsidiary">Indian Subsidiary</option>
                    <option value="FSSAI License">FSSAI License</option>
                    <option value="Import Export Code Registration">
                      Import Export Code Registration
                    </option>
                    <option value="ISO Registration">ISO Registration</option>
                    <option value="Digital Signature (DSC)">
                      Digital Signature (DSC)
                    </option>
                    <option value="ESI Registration">ESI Registration</option>
                    <option value="Provident Fund Registration">
                      Provident Fund Registration
                    </option>
                    <option value="Trade License">Trade License</option>
                    <option value="GST Registration">GST Registration</option>
                    <option value="Sales Tax Registration">
                      Sales Tax Registration
                    </option>
                    <option value="Professional Tax Registration">
                      Professional Tax Registration
                    </option>
                    <option value="Excise Tax Registration">
                      Excise Tax Registration
                    </option>
                    <option value="Trademark Registration">
                      Trademark Registration
                    </option>
                    <option value="Trademark Assignment">
                      Trademark Assignment
                    </option>
                    <option value="Trademark Objection">
                      Trademark Objection
                    </option>
                    <option value="Copyright Registration">
                      Copyright Registration
                    </option>
                    <option value="Patent Registration">
                      Patent Registration
                    </option>
                    <option value="Patent Search">Patent Search</option>
                    <option value="ROC Compliances">ROC Compliances</option>
                    <option value="LLP Annual Filing">LLP Annual Filing</option>
                    <option value="Company Annual Filing">
                      Company Annual Filing
                    </option>
                    <option value="Maintenance of minutes/Statutory register">
                      Maintenance of minutes/Statutory register
                    </option>
                    <option value="Business Plan Preparation">
                      Business Plan Preparation
                    </option>
                    <option value="Allotment of Shares">
                      Allotment of Shares
                    </option>
                    <option value="Change in Company Name">
                      Change in Company Name
                    </option>
                    <option value="Change in Directors">
                      Change in Directors
                    </option>
                    <option value="Change Registered Office">
                      Change Registered Office
                    </option>
                    <option value="Change in Authorized Share Capital">
                      Change in Authorized Share Capital
                    </option>
                    <option value="Proprietorship to Private Ltd.">
                      Proprietorship to Private Ltd.
                    </option>
                    <option value="Partnership to LLP">
                      Partnership to LLP
                    </option>
                    <option value="Closing LLP">Closing LLP</option>
                    <option value="Closing Private Limited">
                      Closing Private Limited
                    </option>
                    <option value="Income Tax Return">Income Tax Return</option>
                    <option value="Bulk Return Filing">
                      Bulk Return Filing
                    </option>
                    <option value="Revised Return Filing">
                      Revised Return Filing
                    </option>
                    <option value="Respond to tax notice">
                      Respond to tax notice
                    </option>
                    <option value="Business Income Return">
                      Business Income Return
                    </option>
                    <option value="TDS Return Filing">TDS Return Filing</option>
                    <option value="Service Tax Return">
                      Service Tax Return
                    </option>
                    <option value="Sales Tax Return">Sales Tax Return</option>
                    <option value="Employment Offer Letter">
                      Employment Offer Letter
                    </option>
                    <option value="Appointment Letter">
                      Appointment Letter
                    </option>
                    <option value="Employee Warning Letter">
                      Employee Warning Letter
                    </option>
                    <option value="Direct Selling Agent Agreement">
                      Direct Selling Agent Agreement
                    </option>
                    <option value="Sale Deed">Sale Deed</option>
                    <option value="Gift Deed">Gift Deed</option>
                    <option value="Residential Rental Agreement">
                      Residential Rental Agreement
                    </option>
                    <option value="Commercial Rental Agreement">
                      Commercial Rental Agreement
                    </option>
                    <option value="Founders Agreement">
                      Founders Agreement
                    </option>
                    <option value="Share Purchase Agreement">
                      Share Purchase Agreement
                    </option>
                    <option value="Invitation Bid Letter">
                      Invitation Bid Letter
                    </option>
                    <option value="Board Resolution Letter">
                      Board Resolution Letter
                    </option>
                    <option value="Automobile Sale Agreement">
                      Automobile Sale Agreement
                    </option>
                    <option value="Franchise Agreement">
                      Franchise Agreement
                    </option>
                    <option value="Service Complaint/ Adjustment Request">
                      Service Complaint/ Adjustment Request
                    </option>
                    <option value="Software Distribution Agreement">
                      Software Distribution Agreement
                    </option>
                    <option value="Website Maintenance Agreement">
                      Website Maintenance Agreement
                    </option>
                    <option value="Website Development Agreement">
                      Website Development Agreement
                    </option>
                    <option value="Website Terms and Conditions">
                      Website Terms and Conditions
                    </option>
                    <option value="Website Privacy Policy Agreement">
                      Website Privacy Policy Agreement
                    </option>
                    <option value="Agency Agreement">Agency Agreement</option>
                    <option value="Equipment Lease Agreement">
                      Equipment Lease Agreement
                    </option>
                    <option value="Breach of Contract Notice">
                      Breach of Contract Notice
                    </option>
                    <option value="Copyright Assignment Agreement">
                      Copyright Assignment Agreement
                    </option>
                    <option value="Business Loans">Business Loans</option>
                    <option value="Loan Against Property">
                      Loan Against Property
                    </option>
                    <option value="Merchant Cash Advance">
                      Merchant Cash Advance
                    </option>
                    <option value="Online Seller Finance">
                      Online Seller Finance
                    </option>
                    <option value="Equipment Financing">
                      Equipment Financing
                    </option>
                    <option value="Inventory Financing">
                      Inventory Financing
                    </option>
                    <option value="Invoice Financing">Invoice Financing</option>
                    <option value="Home Loans">Home Loans</option>
                    <option value="Personal Loans">Personal Loans</option>
                  </select>
                </div>

                <div className="col-12">
                  <textarea
                    className="form-control"
                    rows="5"
                    name="comment"
                    placeholder="Message"
                  	value={formData.comment}
											onChange={handleChange}
											required
                  ></textarea>
                 
                </div>
              
                <div className="col-12 subBtn">
                  <button
                    className="btn btn-success w-100 py-3"
                    name="submit"
                  
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              
              </div>
              </form>
           
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment End --> */}

      

		 {/* Success Modal */}

		 <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header closeButton style={{ width: "100%" }}>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary"  onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            
          >
            <Modal.Header closeButton style={{ width: "100%" }}>
              <Modal.Title style={{ fontSize: "28px" }}>Something Went Wrong !</Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                id="closeBtn"
                
                onClick={closePop}>
                Close
              </Button>
              {/* <Button variant="primary" id="closeBtn" onClick={closePop}>
                CLOSE
              </Button> */}
            </Modal.Footer>
          </Modal>
        )}
      </div>

    </div>
  );
};

export default ContactUs;
