import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/IsoRegistration.css";
import IsoImg from "../static/img/isoImage.webp";
import IsoImgMain from "../static/assetse/img/isoImage.png";
import PubPri from "../static/assetse/img/publicPrivate.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const IsoRegistration = () => {
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    

    <div>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="fs-1">ISO Registration</h1>
              <p>Ideal for Maintaining Standard and Quality.</p>
              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start" id="resp">
                  <a
                    href=""
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={IsoImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <br />
      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>ISO Registration</h2>
              </div>
              <p>
                ISO is an independent international organisation that
                facilitates innovation and leads way to the development of
                trade. The objective of ISO is to advance the improvement of
                standardization in technology. ISO has listed internationally
                recognised standards (e.g, 9001, 22000, 14000) to encourage
                worldwide exchange of goods. ISO registration or ISO
                certification improves the credibility of your business and
                helps you get more business. You might have seen ISO 9001
                Quality mark on products from large companies. This can be
                attained through ISO certification.
              </p>
              <p>
                Companies looking to obtain ISO certification, ISO registration
                or ISO certificates including ISO 9001, ISO 14001, OHSAS 18001,
                ISO 20000, ISO 27001, ISO 50001, ISO TS 16949 etc. certification
                in India can contact TaxOSmart. TaxOSmart has a team of
                certified lead auditors and engineers who are having more than
                7+ years experience in implementation of various ISO standards.
                ISO Registration can be done through TaxOSmart in Delhi NCR,
                Mumbai, Bengaluru, Chennai & all other Indian cities.
              </p>
            </div>

            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={IsoImgMain} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Features Section ======= --> */}
      <section id="features" className="features whatTop">
        <div className="container">
          <div className="section-header">
            <h2>What Is Included In Our Package?</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4">
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Consultation</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Application Drafting</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Drafting of policy Standards</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Certificate Issue</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      <br />

      {/* <!-- ======= Featured Services Section ======= --> */}
      <section id="featured-services" className="featured-services isoTop">
        <div className="container">
          <div className="section-header">
            <h2>Advantages Of ISO Certification</h2>
            <p>
              There are multiple benefits of ISO registration. Some of the key
              benefits have been outlined below:
            </p>
          </div>

          <br />
          <div className="row gy-4">
            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-briefcase"></i>
              </div>
              <div>
                <h4 className="title">Get government tenders:</h4>
                <p className="description">
                  ISO certificate is now required in a majority of government
                  tenders.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-solid  fa fa-truck"></i>
              </div>
              <div>
                <h4 className="title">Build credibility internationally:</h4>
                <p className="description">
                  ISO certification helps your organisation helps credibility to
                  build overseas business.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-user-secret"></i>
              </div>
              <div>
                <h4 className="title">Better customer satisfaction:</h4>
                <p className="description">
                  ISO standards are designed to enable an organisation to serve
                  their customers better and hence increase customer
                  satisfaction. ISO certification enhances customer satisfaction
                  by meeting customer requirements.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-thumbs-up"></i>
              </div>
              <div>
                <h4 className="title">Improve product quality:</h4>
                <p className="description">
                  Since product quality matches the international level, this
                  can reduce the risk order rejections which can occur due to
                  the flaw in the product.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-tasks"></i>
              </div>
              <div>
                <h4 className="title">Improve business efficiency:</h4>
                <p className="description">
                  ISO certification implementation enhances functional
                  efficiency of an organisation. ISO certification agency helps
                  you develop SOP's & work Instructions for all your processes.
                  ISO implementations help to manage the resources effectively,
                  as you become able to utilise all your resources to its
                  maximum extent.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-shopping-bag"></i>
              </div>
              <div>
                <h4 className="title">Improve marketability:</h4>
                <p className="description">
                  ISO certification helps to improve the credibility of business
                  with a current & new client which leads to creating niche
                  market for business.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Featured Services Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header">
            <h2>Steps Required For ISO Registration</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    ISO Certificate Application:
                  </a>
                </h3>
                <p>
                  Apply for ISO certification on our TaxOSmart online ISO
                  application form. You need to provide complete business
                  details including the nature business, company address, years
                  of operation etc.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    ISO Audit:
                  </a>
                </h3>
                <p>
                  An ISO audit is conducted based on the documents provided. The
                  central ISO company checks for the genuineness of all the
                  documents and confirm whether the company is eligible for the
                  ISO certification or not.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    ISO Decision:
                  </a>
                </h3>
                <p>
                  Subsequently ISO certification decision is taken by ISO
                  authority based on defined procedures & an ISO certificate is
                  issued for your organisation..
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-12 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    ISO Yearly Renewal:
                  </a>
                </h3>
                <p>
                  Every year a surveillance audit is conducted to ensure
                  continued adherence to your quality management system to the
                  requirements of ISO standards.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      <section id="service" className="services pt-0">
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="section-header">
              <h2>ISO Standards</h2>
            </div>
            <div className="col-md-5 mt-0">
              <img src={PubPri} className="img-fluid" alt="" />
            </div>
            <div className="col-md-7">
              <ul>
                <li>
                  <i className="bi bi-check"></i> ISO 9001- Quality management
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 2768-1- General tolerances
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 14001- Environmental
                  management
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 27001- Information media
                  security system
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 31000- Risk management
                </li>
                <li>
                  <i className="bi bi-check"></i>ISO 4217- Currency codes
                </li>

                <li>
                  <i className="bi bi-check"></i>ISO 50001- Energy management
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Features Item --> */}

      <section className="pt-0">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span> What is ISO?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        ISO certification has been largely considered as a
                        quality management tool which provides a kind of
                        identity to company's quality management system. It
                        standardises how businesses and organisations involved
                        in commerce and industry manage information and
                        processes. It simply means that organisation has met
                        certain requirements
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> What is ISO 9000?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        It is a kind of ISO certificate which represents a
                        quality of the product on an international basis and
                        also ensures that the company meets up with all the
                        required quality measures. ISO 9000 family of standards
                        consist of basically three core of standards:
                      </p>
                      <ul>
                        <li>
                          1. ISO 9001- Quality Management Systems -
                          Requirements.
                        </li>
                        <li>2. ISO 9000- Fundamentals and Vocabulary.</li>
                        <li>
                          3. ISO 9004- Quality Management Systems - Guidelines
                          for performance improvements.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span> What is the role of BIS
                        (Bureau of Indian Standards) in ISO 9000?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        BIS stands for Body of India and is the founder of ISO.
                        For the advancement of ISO 9000 standards the technical
                        and its sub-committees are responsible. BIS officers
                        which are included in Quality and industry expert's
                        panel are nominated by BIS. They are the one who
                        enthusiastically participates in the gatherings offered
                        by Technical Committee ISO/TC 176 and its
                        Sub-committees.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span> What is ISO 9001?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        ISO 9001 is the most common ISO certification. It refers
                        to the Quality Management System and is applicable to
                        all businesses. TaxOSmart is a leading ISO 9001
                        certification company of India. ISO 9001 certificate is
                        considered to be one of the best certificates which
                        assure the customer about the quality of the product. IT
                        builds up a trust among concerned people around you.
                      </p>
                      <p>
                        ISO 9001:2016 is the latest quality management system
                        standard. ISO 9001 standard provides a set of
                        standardised requirements for a quality management
                        system. It does not depend upon the activities of an
                        organisation or its size, or on any kind of sector such
                        as a private or public sector. The only certification
                        standard in the IS/ISO 9000 family is IS/ISO 9001. ISO
                        9001 Standard provide uniformity to the structure of
                        quality management systems
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span>What is ISO 9004
                        certification?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        To improve the quality of a product, organisations have
                        implemented some rules and regulations as per the
                        requirements of IS/ISO 9001. The standard IS/ISO 9004
                        has an important element of 'self- assessment' and this
                        standard is not agreeable to certification.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> How to implement the
                        requirements of ISO 9001 certification in my business?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        As a minimum, you should familiarise yourself not only
                        with the requirements of IS/ISO 9001, but vocabularies
                        given in IS/ISO 9000 and direction standard IS/ISO 9004
                        will help you to clearly understand your association's
                        activities and processes and appropriately interpret the
                        requirements of the standards. Implement the
                        requirements in the different activities and processes
                        adding value to these processes and activities. For
                        training programs on general awareness on the
                        requirements, our National Institute of Training for
                        Standardisation (NITS) may be reached.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span> What is the difference
                        between ISO 'certification' and 'accreditation'?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        In simple words, accreditation is a kind of
                        certification but accreditation' should not be used as
                        an interchangeable alternative for certification or
                        registration.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span className="badge">8</span> What are the standards
                        in ISO 9000 family of standards?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The other standards of ISO 9000 family have also been
                        adopted by BIS. The new IS/ISO 9000 family involves
                        three main standards:
                      </p>
                      <ul>
                        <li>1. ISO 9000 Fundamentals and vocabulary.</li>
                        <li>
                          2. ISO 9001 Quality management system requirements.
                        </li>
                        <li>
                          3. ISO 9004 Managing for sustained success of
                          organisation.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">9</span>What is ISO audit?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Well, you can get someone from your family or friend
                        circle as the second direction and give them marginal
                        share, e.g, 0.01%. You also have the option to register
                        a One Person Company, but it will not be possible to add
                        directors in OPC later.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span className="badge">10</span> I am small business or
                        startup with less than 10 employees. Is ISO
                        certification relevant for me?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Definitely. The revised version of the standard is as
                        relevant to small as well as large organisations.
                        Irrespective of your size, it will enable you to drive
                        improvement in your business and increase customer
                        satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-11">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-11"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-11"
                      >
                        <span className="badge">11</span>Can a salaried working
                        person also become a director of a Private Limited
                        Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-11"
                    className="collapse"
                    aria-labelledby="faqHeading-11"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, you as a salaried person can become the director in
                        Private Limited, LLP or OPC Private Limited Company. You
                        need to check your employment agreement if that allows
                        for such provisions. In most, cases employers are
                        comfortable with the fact that their employee is a
                        director in another company.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-12">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-12"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-12"
                      >
                        <span className="badge">12</span>In which cities does
                        TaxOSmart provide ISO registration and ISO
                        certification?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-12"
                    className="collapse"
                    aria-labelledby="faqHeading-12"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart provides ISO certification services across
                        India in all cities. We have done ISO registration in
                        Mumbai, Delhi, Gurgaon, Noida, Bangalore, Chennai,
                        Hyderabad, Ahmedabad, Kolkata, Surat, Pune, Jaipur,
                        Lucknow, Kanpur, Nagpur and other Indian cities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                ISO Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={IsoImg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "ISO Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default IsoRegistration;
