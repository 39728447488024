import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/GstRegisteration.css";
import GstImg from "../static/img/gstImage.png";
import Gst from "../static/assets/img/minslider/GST.png";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const GstRegistration = () => {
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>GST (Goods and Service Tax)</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="GST (Goods and Service Tax) is an indirect tax that has replaced many indirect taxes in India. Learn about GST registration, requirements for businesses with turnover exceeding Rs. 40 lakhs, and the registration process through TaxOSmart." />
        <meta name="keywords" content="GST, Goods and Service Tax, indirect tax, GST registration, tax compliance, TaxOSmart." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container gstTop">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="fs-1">GST Registration</h1>
              <p>
                Get GST registration by TaxOSmart and stay ahead on GST
                compliance!
              </p>
              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={GstImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
        <section id="" className="">
          <div className="container-fluied">
            <div className="row gx-0">
              <div
                className="col-lg-12 d-flex flex-column justify-content-center"
                data-aos=""
                data-aos-delay="200"
              >
                <div className="container content">
                  <header className="section-header">
                    <h2>What Is GST?</h2>
                    <br />
                  </header>
                  <p>
                    GST is an Indirect Tax which has replaced many Indirect
                    Taxes in India. The Goods and Service Tax Act was passed in
                    the Parliament on 29th March 2017. In simple words, Goods
                    and Service Tax (GST) is an indirect tax levied on the
                    supply of goods and services. GST is one indirect tax for
                    the entire country. In the GST Regime, businesses whose
                    turnover exceeds Rs. 40 lakhs* (Rs 10 lakhs for NE and hill
                    states) is required to register as a normal taxable person.
                    This process of registration is called GST registration. GST
                    registration usually takes between 2-6 working days. GST
                    Registration is done through TaxOSmart.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* 
<!-- ======= Features Section ======= --> */}
        <section id="features" className="features advantageTop ">
          <div className="container" data-aos="">
            <header className="section-header pt-0">
              <h2 className="mediaFont">Advantages Of GST Registration</h2>
            </header>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Eliminating cascading effect of taxes.</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Tax rates will be comparatively lower as the tax base
                        will widen.
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Seamless flow of Input tax credit.</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Prices of the goods and services will fall.</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Efficient supply change management.</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Promote shift from unorganised sector to organised
                        sector.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services mt-0">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2 className="mediaFont">Steps of GST Registration</h2>
          </div>

          <div className="row gy-4">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 1
                  </a>
                </h3>
                <p>Log in to the online GST Portal (www.gst.gov.in).</p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 2
                  </a>
                </h3>
                <p>Fill Part-A of Form GST Registration form 1.</p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 3
                  </a>
                </h3>
                <p>
                  You will receive an application reference number on your
                  mobile and via E-mail.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/trucking-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 4:
                  </a>
                </h3>
                <p>
                  You will then need to fill the second part of the form and
                  upload the required documents according to the business type.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/packaging-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    Step 5
                  </a>
                </h3>
                <p>
                  Finally a certificate of registration is issued to you by the
                  department.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/warehousing-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 6
                  </a>
                </h3>
                <p>
                  In case of errors and questions, you may need to visit the
                  department.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/warehousing-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 7
                  </a>
                </h3>
                <p>
                  Produce the documents within 7 working days along with GST
                  REG-04.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/warehousing-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Step 8
                  </a>
                </h3>
                <p>
                  The office may also reject your application, if he finds any
                  errors. You will be informed in form GST REG-05 of GST
                  registration regarding the same.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      <section id="about" className=" advantageTopWho mt-0 pt-0">
        <div className="container-fluied" data-aos="">
          <div className="row gx-0">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div className="container content">
                <header className="section-header pt-0">
                  <h2 className="mediaFont">
                    Who all need to get GST registration?
                  </h2>
                  <br />
                </header>
                <p className="mt-0">
                  Goods and Services Tax Identification Number (GSTIN) or GST
                  Number is a unique identifier assigned to a business or person
                  registered under the GST Act. GSTIN is utilized by tax
                  authorities to maintain records of GST dues and payments of
                  those who are registered under the GST Act. Persons who are
                  required to get a GST number are:
                </p>

                <ul>
                  <li>
                    Individuals registered under the Pre-GST law. (i.e., Excise,
                    VAT, Service Tax etc.)
                  </li>
                  <li>
                    Businesses with turnover above the threshold limit of Rs. 40
                    Lakhs* (Rs. 10 Lakhs for North-Eastern States, J&K, Himachal
                    Pradesh and Uttarakhand)
                  </li>
                  <li>Casual taxable person / Non-Resident taxable person.</li>
                  <li>Agents of a supplier & Input service distributor.</li>
                  <li>Those paying tax under the reverse charge mechanism.</li>
                  <li>Person who supplies via e-commerce aggregator.</li>
                  <li>Every e-commerce aggregator.</li>
                  <li>
                    Person supplying online information and database access or
                    retrieval services from a place outside India to a person in
                    India, other than a registered taxable person
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <section className="mt-0 pt-0">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card panel">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span>What is GST?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Goods and Service Tax is a tax proposed to be levied on
                        supply of goods and services right from the point of
                        manufacturing to final consumption by user. In short it
                        is a destination based tax accruing at the place of
                        sale/supply.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Do I need to get
                        registered under GST law?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        A business entity that is currently registered under any
                        of the existing tax regimes then you are compulsorily
                        required to migrate under GST law irrespective of the
                        threshold limits. But if you are supplying goods and
                        services and not registered under any existing tax
                        legislative then you are liable to register only if the
                        aggregate turnover in any financial year exceeds the
                        threshold limit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span>What are the threshold
                        limits for registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Every supplier of goods and services is required to get
                        registered under GST if his aggregate annual turnover
                        during the financial year exceeds 20 lakhs (for North
                        Eastern States the threshold limit is 10 lakhs).
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span>Who is exempted from
                        registration even if threshold limit is reached?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        <p>
                          Suppliers who are engaged in supply of following goods
                          or services listed below:
                        </p>
                        <ul>
                          <li>
                            Exempted Goods (list will be notified by GST
                            Council).
                          </li>
                          <li>
                            Goods like liquor for personal consumption,
                            petroleum products etc.
                          </li>
                          <li>
                            Importing services for personal use as reverse
                            charge will not be applicable.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span> Is Threshold limit for
                        registration applicable for everyone?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, the below listed category of suppliers will have to
                        get registration even if they do not exceed the
                        threshold limit:
                      </p>

                      <p>Casual Taxable Person</p>
                      <p>Non Resident Person</p>
                      <p>
                        Business engaged in inter-state supply of goods and
                        services.
                      </p>
                      <p>
                        Those who are required to deduct TDS u/s 46 or collect
                        TCS u/s 56
                      </p>
                      <p>Input Service Distributor</p>
                      <p>
                        Electronic commerce operator supplying goods and
                        services directly or acting as intermediary to promote
                        exchange (list of specific goods and services will be
                        prescribed for this purpose).
                      </p>
                      <p>
                        Person supplying data management services irrespective
                        of whether located in India or not.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> If I have multiple
                        business units within the same state will I be liable to
                        get separate registrations?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, there is no concept of central registration under
                        GST. Every person will have to obtain a separate
                        registration for every state in which he has a business
                        establishment whether by same name or a different name.
                        Even if person is having same PAN number and has
                        operations in different states every operational unit
                        will have to apply for separate registration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span>What is composition
                        scheme?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        This scheme is introduced for small tax payers to reduce
                        the compliance burden on them. Business registered under
                        composition scheme will be liable to pay tax only to a
                        maximum of 2.5% for manufacturers and 1% for other than
                        manufacturers. They would not be part of credit chain
                        and hence cannot collect any tax from consumers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 
<!--/ End Single News -->

<!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                GST Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={GstImg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "GST Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default GstRegistration;
