import axios from "axios";
import React, { useState } from "react";

import privateLimitedImage from "../static/assetse/img/privateLimitedImage.png";
import features from "../static/assetse/img/features-1.jpg";
// import packageing from '../../assets/img/packaging-service.jpg'
// import storageService from '../../assets/img/storage-service.jpg'
// import logisticsImage from '../assets/img/logistics-service.jpg'
// import cargoServiceImage from '../assets/img/cargo-service.jpg'
// import truckingImage from '../assets/img/trucking-service.jpg';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
const Pvt = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>Private Limited Company Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Learn how to register a Private Limited Company effortlessly. This comprehensive guide covers all essential steps, requirements, and legal considerations to help you establish your business with confidence." />
        <meta name="keywords" content="Private Limited Company, company registration, business formation, limited liability, legal requirements, entrepreneurship." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="index, follow" />

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="InfoColor">
                Private Limited Company Registration
              </h1>
              <p
                classNameName="fs-6 pt-2"
                data-aos=""
                style={{ color: "#0f316d" }}
              >
                Ideal business structure for Small and Medium sized Enterprises
                going for Partnership
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={privateLimitedImage}
                className="img-fluid gstImageCss"
                style={{ borderRadius: "20%" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features">
        <div className="container">
          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5">
              <img src={features} className="img-fluid mt-5" alt="" />
            </div>
            <div className="col-md-7">
              <h3 className="fs-2">
                Information about private limited company registration
              </h3>
              <p>
                Private Limited Company registration is the most popular legal
                structure option for businesses in India. Private Limited
                Company can have a minimum of two members and a maximum of fifty
                members. The directors of a Private Limited Company have limited
                liability to creditors. In a case of default, banks / creditors
                can only sell company's assets but not personal assets of
                directors.
              </p>
              <p>
                Start-ups and growing companies prefer Private Limited Company
                as it allows outside funding to be raised easily, limits the
                liabilities of its shareholders and enables them to offer
                employee stock options to pull in top talent. Private Limited
                Company Registration can be done through TaxOSmart in Delhi NCR,
                Mumbai, Bengaluru, Chennai and other Indian cities.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header">
            <h2>Factors to Consider in Company Name Selection</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img src={storageService} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Short & Simple
                  </a>
                </h3>
                <p>
                  The name should be concise and not be too long. People should
                  be able to pronounce it easily and they should be able to
                  remember your company's name the first time they hear or read
                  it.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img src={logisticsImage} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Suffix
                  </a>
                </h3>
                <p>
                  The name of your company should end with the suffix "Private
                  Ltd" in a case of a Private limited company and "LLP" is a
                  case of a limited liability partnership.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img src={cargoServiceImage} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Meaningful
                  </a>
                </h3>
                <p>
                  The name of your company should be related to your business.
                  It should fit the company's branding. For example, Infosys
                  refers to information systems or IT technologies.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img src={truckingImage} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Should not be illegal / offensive
                  </a>
                </h3>
                <p>
                  The name of your company should not be against law. It should
                  not be abusive or against the customs and beliefs of any
                  religion.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img src={packageing} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Unique
                  </a>
                </h3>
                <p>
                  Name of your company should not be same or identical to an
                  existing company or trademark. You can go to
                  search.TaxOSmart.com to check if your company name is
                  identical to others. You should ideally avoid plural version
                  e.g., "Flipkart's" or changing just letter Case, spacing or
                  punctuation marks in an existing company name.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header">
            <h2>Steps For Company Registration</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Application of DSC & DPIN:
                  </a>
                </h3>
                <p>
                  First of all, the partners have to apply for Digital signature
                  and DPIN. Digital signature is an online signature used for
                  filing and DPIN refer to Directors PIN number issued by MCA.
                  If the directors already have DSC and DPIN, then this step can
                  be skipped.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Name Approval:
                  </a>
                </h3>
                <p>
                  You need to provide 3 different options for your company name
                  to MCA of which one will be selected. Names provided should
                  ideally be unique and suggestive of company business.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    MOA & AOA submission:
                  </a>
                </h3>
                <p>
                  Once name is approved, one needs to draft Memorandum of
                  Association and Articles of Association. Both MOA and AOA are
                  filed with the MCA with the subscription statement.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Get Incorporation Certificate:
                  </a>
                </h3>
                <p>
                  It typically takes 15- 25 days to form a Private limited
                  company and get the incorporation certificate. Incorporation
                  certification is a proof that company has been created. It
                  also includes your CIN number.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Cheaper infrastructure:
                  </a>
                </h3>
                <p>
                  Charges are lower for MSME registered company for facilities
                  such as electricity and VAT exemptions. In fact, other
                  business services such as patents are also cheaper for MSMEs
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Apply for PAN, TAN and Bank account:
                  </a>
                </h3>
                <p>
                  Then you need to apply for PAN and TAN. PAN and TAN are
                  received in 7 working days. Post this, you can submit the
                  Incorporation certificate, MOA, AOA and PAN with a bank to
                  open your bank account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Private Limited Company Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={privateLimitedImage}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Private Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pvt;
