import axios from "axios";
import React, { useState } from "react";

import "../assets/css/Section8reg.css";

import Nidhijpg from "../static/assetse/img/nidhiCompany.jpg";

import Nidhijpg2 from "../static/image/img/nidhiCompany.jpg";

import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';


const NidhiCRegisrtation = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Nidhi Company Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Nidhi Company Registration focuses on promoting thrift and savings among its members. This company receives deposits and lends money exclusively for mutual benefit. Explore our comprehensive package, including DIN for 3 directors, government registration fees, application preparation, and MSME certificate." />
        <meta name="keywords" content="Nidhi company registration, finance company, thrift, mutual benefit, DIN, MSME certificate, company formation." />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1
                className="display-1 animated slideInDown fs-1"
                style={{ color: "#0f316d" }}
              >
                Nidhi Company Registration
              </h1>
              <p>Ideal for Lending and Borrowing Amongst Members</p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img src={Nidhijpg} className="img-fluid gstImageCss" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Call To Action Section --> */}

      {/* <!-- ======= Featured Services Section ======= --> */}
      <section id="featured-services" className="featured-services pb-0">
        <div className="container">
          <div className="section-header">
            <h2>Factors to Consider in Nidhi Company Name Selection</h2>
          </div>
          <br />

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className=" fa fa-solid  fa fa-truck"></i>
              </div>
              <div>
                <h4 className="title">Application of DSC & DPIN:</h4>
                <p className="description text-justify">
                  First of all, the partners have to apply for Digital signature
                  and DPIN for Nidhi company. Digital signature is an online
                  signature used of filing and DPIN refer to Directors PIN
                  number issued by MCA. If the directors already have DSC and
                  DPIN, then this step can be skipped
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-leaf" aria-hidden="true"></i>
              </div>
              <div>
                <h4 className="title">Name Approval:</h4>
                <p className="description text-justify">
                  You need to provide 3 different options for your company name
                  to MCA of which one will be selected. Names provided should
                  ideally be unique and suggestive of company business
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-id-card" aria-hidden="true"></i>
              </div>
              <div>
                <h4 className="title">MOA & AOA submission:</h4>
                <p className="description text-justify">
                  Once name is approved, one needs to draft Memorandum of
                  Association and Articles of Association. Both MOA and AOA are
                  filed with the MCA with the subscription statement.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Featured Services Section --> */}
      <br />
      <br />

      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={Nidhijpg} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>Nidhi Company Registration</h2>
              </div>
              <p className="text-justify">
                "Nidhi" is a Hindi word, which means finance or fund. Nidhi
                means a company which has been incorporated with the objective
                of developing the habit of thrift and reserve funds amongst its
                members and also receiving deposits and lending to its members
                only for their mutual benefit.
              </p>

              <ul>
                <li>
                  <i className="bi bi-diagram-3"></i>
                  <div>
                    <h5 className="fs-3">What Is Included In Our Package?</h5>
                    <p className="text-justify">
                      DIN For 3 Directors, Government Registration Fees ,
                      Application Preparation, Obtain MSME Certificate
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section -->

<!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0 stepTop">
        <div className="container">
          <div className="section-header mt-0 pt-0">
            <h2>Steps to Incorporate Nidhi Company Registration</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Application of DSC & DPIN:
                  </a>
                </h3>
                <p className="text-justify">
                  First of all, the partners have to apply for Digital signature
                  and DPIN for Nidhi company. Digital signature is an online
                  signature used of filing and DPIN refer to Directors PIN
                  number issued by MCA. If the directors already have DSC and
                  DPIN, then this step can be skipped.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Name Approval:
                  </a>
                </h3>
                <p className="text-justify">
                  You need to provide 3 different options for your company name
                  to MCA of which one will be selected. Names provided should
                  ideally be unique and suggestive of company busines
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    MOA & AOA submission:
                  </a>
                </h3>
                <p className="text-justify">
                  Once name is approved, one needs to draft Memorandum of
                  Association and Articles of Association. Both MOA and AOA are
                  filed with the MCA with the subscription statement.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/trucking-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Get Incorporation Certificate:
                  </a>
                </h3>
                <p className="text-justify">
                  It typically takes 15- 25 days to form a Private limited
                  company and get the incorporation certificate. Incorporation
                  certification is a proof that company has been created. It
                  also includes your CIN number
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/packaging-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    Apply for PAN, TAN and Bank account:
                  </a>
                </h3>
                <p className="text-justify">
                  Then you need to apply for PAN and TAN. PAN and TAN are
                  received in 7 working days. Post this, you can submit the
                  Incorporation certificate, MOA, AOA and PAN with a bank to
                  open your bank account.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      <br />
      <br />
      <br />
      <br />
      <section className="frequ pt-0">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card panel">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span> What are the
                        requirements for registration of a Nidhi Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        A Nidhi company is registered as a Public Limited
                        Company. Hence, the requirements for incorporation of a
                        Nidhi company covered a minimum of three Directors and
                        Seven Shareholders. However, the MOA of a Nidhi company
                        must state that the primary objective of the proposed
                        company is to nurture and promote a habit of thriftiness
                        and savings among its members, and accept deposits from
                        or lend loans only to its members, for the mutual
                        benefits of them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Are the deposits with
                        the company safe and secured?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, because Government of India/Ministry of Corporate
                        Affairs/RBI has framed the laws/rules to ensure the
                        security and safety of deposits and Nidhi companies must
                        strictly abide by the rules and regulations framed by
                        the Central Government.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span> How does the Nidhi
                        Company use the fund/deposit procured by it?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The Company uses the funds in lending to the
                        Shareholder/members only as per the Nidhi company rules.
                        Nidhi Company lending is in the shape of small loans for
                        business and microfinance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span> Who Can Invest in a
                        Nidhi Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Only shareholders/Members of the Nidhi Company who have
                        a membership ID, can invest in the scheme. To be a
                        member, you must be 18-years and above as per age proof
                        and must be a citizen of India.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span>What is the time taken
                        by TaxOSmart.com in forming a Private Limited Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart.com can incorporate a Private Limited Company
                        within 15-25 days. The time took also depends on
                        relevant documents provided by the applicant and speed
                        of approvals from government. To ensure speedy
                        registration, please pick a unique name for the proposed
                        Company and make sure you have all the required
                        documents prior to starting the registration process.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> How can I become a
                        Shareholder/Member of the Nidhi Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, an NRI or Foreign National can become a Director in
                        a Private Limited Company. They can get a DPIN from
                        Indian ROC. Also, they can be a majority shareholder in
                        the company. But, at least one Director on the Board of
                        Directors must be an Indian Resident.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span>What is the minimum
                        capital required for forming a Private Limited Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>Business Restrictions:</p>
                      <p>
                        Nidhi Companies are strictly prohibited from engaging in
                        the following types of businesses:
                      </p>
                      <p>
                        Business of chit fund, hire purchase finance, leasing
                        finance, insurance or acquisition of securities issued
                        by anybody corporate. Nidhi Companies cannot carry on
                        any business other than the business of borrowing or
                        lending in its own name.
                      </p>
                      <br />
                      <h4>General Restrictions:</h4>
                      <p>
                        Nidhi Companies cannot issue any shares, debentures or
                        any other securities by any name or in any form
                        whatsoever.
                      </p>
                      <p>
                        Nidhi Companies cannot open current account with its
                        members. Please note, there is a restriction on current
                        account that means you can still open savings account
                        with the members.
                      </p>
                      <p>
                        Nidhi Companies cannot issue or cause to be issued any
                        advertisement in any form for soliciting deposit.
                      </p>
                      <p>
                        Nidhi Companies cannot pay any brokerage or incentive
                        for mobilising deposits from members or for deployment
                        of funds or for granting loans.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span className="badge">8</span> How many branches can a
                        Nidhi Company open?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        A Nidhi Company can open 3 branches in its district only
                        if it has earned profits after tax consistently during
                        the preceding three financial years. For any additional
                        branch, Nidhi will be first apply for approval from the
                        Regional Director.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">9</span> In which cities does
                        TaxOSmart incorporate Nidhi Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart provides Nidhi Company incorporation services
                        across India in all cities. We have done Nidhi company
                        registration in Mumbai, Delhi, Gurgaon, Noida,
                        Bangalore, Chennai, Hyderabad, Ahmedabad, Kolkata,
                        Surat, Pune, Jaipur, Lucknow, Kanpur, Nagpur and other
                        Indian cities.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span className="badge">10</span> Why should I choose
                        TaxOSmart for Nidhi Company registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart.com is one of the best online service portals
                        to help register Nidhi Companies in India. We have 10
                        years of experience in application of Nidhi Company
                        registration. TaxOSmart has 30+ regional offices in
                        India and expand its network in India with 2000+
                        customers for Nidhi Company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Nidhi Compnay Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Nidhijpg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Nidhi Company Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true">
                    Somthing Went Wrong
                  </i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NidhiCRegisrtation;
