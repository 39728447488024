import axios from "axios";
import React, { useState } from "react";

import $ from "jquery";
import "../assets/css/RevisedRF.css";

import revisedReturn from "../static/metaImages/revisedReturn.png";
import RevisedReturnjpg from "../static/assetse/img/revisedReturn.jpg";

import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';
const RevisedReturnfiling = () => {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };


  return (
    <div>
      <Helmet>
        <title>Revised Return Filing</title>
      </Helmet>
      <main>
        <section id="hero" className="hero d-flex align-items-center">
          <div className="container revTop">
            <div className="Topbox">
              <div className="topcontent">
                <h1 className="fs-1">Revised Return Filing</h1>
                <p className="fs-5">Expert help on Filing Revised Return</p>
                <div data-aos="" data-aos-delay="600">
                  <div className=" text-center text-lg-start">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Get In Touch</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg">
                <img src={revisedReturn} className="img-fluid revImg" alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features mt-0">
          <div className="container">
            <header className="section-header">
              <h2>What Is Included?</h2>
            </header>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>IT notice assessment</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Visit to IT Department</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Revision/Rectification</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Return Processing</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features incomeTop mt-1">
          <div className="container">
            <header className="section-header">
              <h2 className="mediaFont">
                Income tax notice Basic Follow-up Plan
              </h2>
            </header>
            <br />
            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>All basic plan Features</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA assess return</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA respond to notice</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>One time response</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features incomeTop mt-1">
          <div className="container">
            <header className="section-header">
              <h2>Savings Follow-up Plan</h2>
            </header>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA review of IT notice</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA assess return</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA respond to notice</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Respond till issue resolved</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features incomeTop mt-1">
          <div className="container">
            <header className="section-header">
              <h2>In Person Follow-up</h2>
            </header>

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA review of IT notice</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA assess return</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>CA respond to notice</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Respond till issue resolved</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        <br />

        <section className="pt-0">
          <div className="container">
            <div className="row">
              {/* <!-- ***** FAQ Start ***** --> */}
              <div className="col-md-6 offset-md-3">
                <div className="faq-title text-center pb-3">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="faq" id="accordion">
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-1"
                          data-aria-expanded="true"
                          data-aria-controls="faqCollapse-1"
                        >
                          <span className="badge">1</span>What is Income Tax?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-1"
                      className="collapse"
                      aria-labelledby="faqHeading-1"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Income tax is tax levied on the income of a person by
                          the government of India as per the provisions
                          contained in the Income Tax Act 1961. It is levied on
                          income earned during the year starting from 1 April
                          and ending 31st march.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-2">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-2"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-2"
                        >
                          <span className="badge">2</span> Who is liable to pay
                          Income tax
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-2"
                      className="collapse"
                      aria-labelledby="faqHeading-2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Every person is liable to pay tax in India if his
                          Income is more than the income notified by the
                          government in the slab rates. Here the definition of
                          person includes, An Individual, A Hindu Undivided
                          Family (HUF), A Company, A Firm, An Association of
                          Persons (AOP) or a Body of Individuals (BOI), A Local
                          Authority, Artificial juridical persons
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-3">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-3"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-3"
                        >
                          <span className="badge">3</span>What is Income tax
                          Return?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-3"
                      className="collapse"
                      aria-labelledby="faqHeading-3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          An Income tax Return is a statement of Income earned
                          to calculate tax liability and payment or refund of
                          taxes to the government. Thus the purpose of filing
                          the return is to report our income and taxes paid
                          there on to the government.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-4">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-4"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-4"
                        >
                          <span className="badge">4</span> Who is required to
                          file Income Tax Return?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-4"
                      className="collapse"
                      aria-labelledby="faqHeading-4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Any person whose income exceeds the basic exemption
                          limit as specified in the Income Tax Act,1961 is
                          required to file an Income Tax Return. Now, the basic
                          exemption limit changes from year to year. At present
                          the limit is Rs. 2,50,000 for individuals of less than
                          60 age, Rs. 3,00,000 for individuals in the age
                          bracket of 60-80 years, and Rs. 5,00,000 for
                          individuals of more than 80 age. It is compulsory to
                          file an income tax return if any of the condition is
                          applicable to you:
                        </p>
                        <p>
                          if you're taxable income is more than slabs notified
                          in Finance Act for that Year. Example for an
                          Individual Resident below 60 years of Age the Slab is
                          2,50,000 /- (A.Y. 2017-2018) and for senior citizen it
                          is 3,00,000. Thus if his income is more than
                          2,50,000/- ( or 3,00,000 in case of senior citizen)
                          then it is mandatory for him to file Income tax Return
                          irrespective of whether you have filed your tax return
                          or not.
                        </p>
                        <p>
                          If you area entity registered as a firm or a company
                          irrespective of the income or loss during the year.
                        </p>
                        <p>
                          If you have losses under any head and want to carry
                          forward those losses to next year.
                        </p>
                        <p>
                          If you want to claim refund of taxes already paid i.e.
                          TDS etc.
                        </p>
                        <p>
                          From 2012 onward resident individual holding any kind
                          of Foreign Asset e.g. Immovable property, bank account
                          etc. or is a signing authority in a foreign bank
                          account then he is compulsorily required to file the
                          return.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Revised Return Filling
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={revisedReturn}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Revised Return Filing")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <div id="successMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevisedReturnfiling;
