import axios from "axios";
import React, { useState } from "react";
import "../assets/css/llp.css";
import llp from "../assets/img/llp.png";
import llpImage from "../static/assetse/img/llp.jpg";
import featuresImage from "../static/assetse/img/features-1.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
const Llp = () => {
	const [formData, setFormData] = useState({
		type: "",
		name: "",
		email: "",
		mobile: "",
	});
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleCaptchaChange = (token) => {                  // new code for captcha
		setCaptchaToken(token);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!captchaToken) {                              // new code for captcha
			setError('Captcha is requred.');
			return;
		}

		const dataToSubmit = {					  // new code for captcha
			...formData,
			captchaToken,
		};

		axios
			.post("/apiCustomerDetail/", dataToSubmit)
			.then((response) => {
				setSuccess("Success");
				setSuccessModalVisible(true); // Show success modal

				setError("");
				setFormData({
					type: "",
					name: "",
					email: "",
					mobile: "",
				});
				setCaptchaToken(null); // Reset captcha token
			})
			.catch((error) => {
				setError("There was an error!");
				setErrorModalVisible(true);
			});
	};

	const handleCloseSuccess = () => setSuccessModalVisible(false);
	const handleCloseError = () => setErrorModalVisible(false);
	// Use to close the second popup box
	const [isVisible, setIsVisible] = useState(true);

	const closePop = () => {
		setIsVisible(false);
		window.location.reload();
	};
	return (
		<div>
			<Helmet>
				<title>LLP Registration</title>
				<meta charset="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
				<meta name="description" content="Discover the benefits of Limited Liability Partnership (LLP) registration under the LLP Act of 2008. Enjoy limited liability for owners with minimal maintenance requirements, protecting personal assets from creditors." />
				<meta name="keywords" content="LLP registration, Limited Liability Partnership, LLP Act 2008, business formation, limited liability, creditor protection." />
				<meta name="author" content="Taxosmart" />
				<meta name="robots" content="noindex" />


			</Helmet>
			<section
				id="hero"
				className="hero d-flex align-items-center wow fadeIn top5"
				data-wow-delay="0.1s"
			>
				<div className="container top0">
					<div className="Topbox">
						<div className="topcontent">
							<h1 className="InfoColor">
								Information About GST Reconciliation Software
							</h1>
							<p className="effort">
								Effortlessly reconcile GST transactions with precision and
								efficiency using our specialized software, ensuring compliance
								and accuracy for your business financial records.
							</p>
							<div data-aos-delay="600">
								<div className="text-center text-lg-start">
									<a
										data-bs-toggle="modal"
										data-bs-target="#demoModal"
										data-bs-backdrop="false"
										className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
									>
										<span className="getTouch">Get In Touch</span>
										<i className="bi bi-arrow-right white"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="topimg" data-aos="zoom-out" data-aos-delay="200">
							<img
								src={llp}
								className="img-fluid gstImageCss"
								style={{ borderRadius: "20%" }}
								alt=""
							/>
						</div>
					</div>
				</div>
			</section>

			<section id="features" class="features">
				<div class="container">
					<div
						class="row gy-4 align-items-center features-item"
						data-aos="fade-up"
					>
						<div class="col-md-5">
							<img
								src={featuresImage}
								class="img-fluid"
								style={{ borderRadius: "17px" }}
								alt=""
							/>
						</div>
						<div class="col-md-7">
							<h3 className="fs-3">What is LLP Registration?.</h3>
							<p class="fst-italic">
								LLP refers to Limited Liability Partnership and is governed by
								Limited Liability Partnership Act 2008. Limited Liability
								Partnership provides advantage of limited liability to its
								owners and at the same time requires minimal maintenance. The
								directors of a Private Limited Company have limited liability to
								creditors. In case of default, banks / creditors can only sell
								company's assets and not personal assets of directors.
							</p>
							<ul>
								<li>
									<i class="bi bi-check"></i> Dual advantages- Company and a
									Partnership.
								</li>
								<li>
									<i class="bi bi-check"></i> No partner will be responsible for
									other partner's misconduct..
								</li>
								<li>
									<i class="bi bi-check"></i> Cheaper to incorporate than a
									Private Limited Company.
								</li>
								<li>
									<i class="bi bi-check"></i> Limits the liabilities of its
									partners.
								</li>
							</ul>
						</div>
					</div>
					<div
						class="row gy-4 align-items-center features-item"
						data-aos="fade-up"
					>
						<div class="col-md-5 order-1 order-md-2">
							<img
								src={llpImage}
								class="img-fluid"
								alt=""
								style={{ borderRadius: "17px" }}
							/>
						</div>
						<div class="col-md-7 order-2 order-md-1">
							<h3 className="fs-3">Choose LLP because</h3>
							<p class="fst-italic">
								LLP Registration can be done through TaxOSmart in Delhi NCR,
								Mumbai, Bengaluru, Chennai and all other Indian cities.
							</p>
							<p>
								LLP is a Separate Legal Entity that gives the benefit of limited
								liability of company plus the flexibility of partnership,
								wherein no partner's is held liable on account of other's
								partner misconduct and their rights and duties to be governed by
								LLP agreement. The basic premise behind the introduction of
								Limited Liability Partnership (LLP) is to provide a form of
								business organization that is simple to maintain while at the
								same time providing limited liability to the owners.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section id="features" class="features featuresIncludeTop">
				<div class="container" data-aos="">
					<header class="section-header">
						<p className="fs-3">What Is Included In Our Package?</p>
					</header>

					<div class="row">
						<div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
							<div class="row align-self-center gy-4">
								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>DPIN For 2 Partners</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Digital Signature For 2 Partners</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Name Search And Approval</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>LLP Agreement</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>ROC Registration Fees</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>LLP PAN Card</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="features" class="features featuresTop featuresIncludeTop">
				<div class="container" data-aos="">
					<header class="section-header">
						<p className="fs-3">Documents Required for LLP Registration</p>
					</header>

					<div class="row">
						<div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
							<div class="row align-self-center gy-4">
								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Copy of PAN Card of Partners</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Passport size photograph of Partners</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Copy of Aadhaar Card/ Voter identity card.</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Copy of Rent agreement (If rented property)</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Electricity/ Water bill (Business Place)</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Copy of Property papers(If owned property)</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Landlord NOC (Format will be provided)</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="features" class="features featuresTop featuresIncludeTop">
				<div class="container" data-aos="">
					<header class="section-header">
						<p className="fs-3">Minimum Requirements For LLP Registration</p>
					</header>

					<div class="row">
						<div class="col-lg-12 mt-5 mt-lg-0 d-flex">
							<div class="row align-self-center gy-4">
								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Minimum 2 Partners</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>No Capital Requirement</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>At least one Designated Partner as Indian Resident</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>Copy of Rent agreement (If rented property)</h3>
									</div>
								</div>

								<div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
									<div class="feature-box d-flex align-items-center">
										<i class="bi bi-check"></i>
										<h3>DPIN for all Partners</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Success Modal */}

			<div>
				{isVisible && (
					<Modal show={successModalVisible} onHide={handleCloseSuccess}>
						<Modal.Header>
							<Modal.Title style={{ fontSize: "28px" }}>
								Successfull
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Thank you for choosing Taxosmart,We Request you to confirm your
							email address. Our representative will soon contact you.
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={closePop}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</div>

			{/* Error Modal */}
			<div>
				{isVisible && (
					<Modal
						show={errorModalVisible}
						onHide={handleCloseError}
						style={{ width: "100%" }}
					>
						<Modal.Header>
							<Modal.Title style={{ fontSize: "28px" }}>
								Something Went Wrong !
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>{error}</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" id="closeBtn" onClick={closePop}>
								Close
							</Button>

						</Modal.Footer>
					</Modal>
				)}
			</div>

			<div className="modal fade" id="demoModal">
				<div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
					<div className="modal-content sub-bg ">
						<div
							className="modal-header subs-header modal-xxl"
							style={{ width: "100%" }}
						>
							<h3 className="modal-title" style={{ fontSize: "34px" }}>
								Information About LLP Registration
							</h3>
							<button
								type="button"
								className="close"
								data-bs-dismiss="modal"
								aria-hidden="true"
							>
								&times;
							</button>
						</div>
						<div className="modal-body">
							<div className="text-center">
								<img
									className="img-fluid"
									src={llpImage}
									style={{ width: "100%", height: "auto", marginTop: "-2%" }}
								/>
							</div>
							<div className="heading-text text-center ">
								<h4 className="shadow p-3 mb-5">
									Ideal for Start-ups Going for Funding & Growing Business
								</h4>
							</div>
							<div className="">
								<form
									onSubmit={handleSubmit}
									style={{ border: "0px solid black" }}
								>
									<div className="form-group">
										<input
											className="form-control"
											style={{ display: "none" }}
											type="text"
											id="type"
											name="type"
											value={(formData.type = "LLP Registration")}
											onChange={handleChange}
											required
										/>
										<label htmlFor="name">Name</label>
										<input
											className="form-control"
											type="text"
											id="name"
											name="name"
											placeholder="Name"
											value={formData.name}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="email">Email</label>
										<input
											type="email"
											id="email"
											name="email"
											placeholder="Email"
											className="form-control"
											value={formData.email}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="mobile">Mobile</label>
										<input
											type="mobile"
											id="mobile"
											name="mobile"
											placeholder="Mobile"
											className="form-control"
											value={formData.mobile}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="form-group" style={{ marginTop: '20px' }}>
										<ReCAPTCHA
											sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
											onChange={handleCaptchaChange} />
									</div>
									<div style={{ textAlign: 'center', marginTop: '3%' }}>
										<button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Llp;
