import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/MSMEregiteration.css";
import MSMEpng from "../static/img/msme.png";
import MSMEjpg from "../static/assetse/img/msme.jpg";
import MSMEjpeg from "../static/image/img/msme.jpeg";

import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

const MSMEregistration = () => {
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", formData)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>MSME/SSI Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="MSME (Micro, Small and Medium Enterprises) registration enables businesses to access government subsidies and benefits under the MSMED Act, driving economic growth and promoting equitable development." />
        <meta name="keywords" content="MSME registration, SSI registration, Micro Small Medium Enterprises, MSMED Act, government subsidies, economic growth, business benefits." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <main>
        {/* <!-- ======= Hero Section ======= --> */}
        <section id="hero" className="hero d-flex align-items-center">
          <div className="container">
            <div className="Topbox">
              <div className="topcontent">
                <h1>MSME/SSI Registration</h1>
                <div data-aos="" data-aos-delay="600">
                  <div className="text-center text-lg-start">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      href=""
                      data-bs-backdrop="false"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Get Started</span>{" "}
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg">
                <img src={MSMEpng} className="img-fluid itrImage" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Hero --> */}

        <br />
        {/* <!-- ======= Featured Services Section ======= --> */}

        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-solid fa fa-truck"></i>
                </div>
                <div>
                  <h4 className="title">Micro Enterprises</h4>
                  <p className="description">
                    Micro-enterprises are the smallest entities, of course. The
                    investment under Micro manufacturing enterprises should be
                    less than Rs. 25 lakhs in plant and machinery, whereas, the
                    Micro Service Enterprises should not exceed Rs. 10 lakhs
                    investment.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-newspaper"></i>
                </div>
                <div>
                  <h4 className="title">Small Enterprises</h4>
                  <p className="description">
                    In Small Manufacturing Enterprises, the investment should be
                    in between Rs. 25 lakh and Rs. 5 crores in plant and
                    machinery, while in Small Service Enterprises this
                    investment limits between Rs. 10 lakh and Rs. 2 crores.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className=" fa fa-globe"></i>
                </div>
                <div>
                  <h4 className="title">Medium Enterprises</h4>
                  <p className="description">
                    And if we'll talk about the Medium manufacturing
                    enterprises, then the investment should be in between Rs. 5
                    crore and Rs. 10 crores in plant and machinery, and for
                    Small Service Enterprises it should be in between Rs. 2
                    crore and Rs. 5 crores.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        <br />
        <br />
        {/* <!-- End Featured Services Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about pt-0">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={MSMEjpg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>MSME/SSI Registration</h3>
                <p>
                  MSME stands for Micro, Small and Medium Enterprise. MSME or
                  SSI enterprises are the foundation of any economy and are an
                  engine of economic growth, advancing impartial improvement for
                  all. MSME registration or SSI registration helps businesses
                  avail multiple government subsidies and benefits MSMED act.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3">What Is Included In Our Package?</h5>
                      <p>
                        Government Registration Fees , Application Preparation,
                        Obtain MSME Certificate
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        <br />

        {/* <!-- ======= Services Section ======= --> */}
        <section id="service" className="services pt-0 mt-0">
          <div className="container">
            <div className="section-header mt-0 pt-0">
              <h2>ADVANTAGES OF MSME / SSI REGISTRATION</h2>
            </div>

            <div className="row gy-4">
              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/storage-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Cheaper bank loans:
                    </a>
                  </h3>
                  <p>
                    The rate of interest on loan offered to MSMEs is 1-1.5%
                    lower compared to typical business loan interest.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/logistics-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Easy access to credit:
                    </a>
                  </h3>
                  <p>
                    PM Modi has introduced the Mudhra Loan scheme, which
                    provides loans to MSME / SSI without collaterals.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/cargo-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Quicker approvals from state and central government
                      bodies:
                    </a>
                  </h3>
                  <p>
                    Business registered under MSME are given higher preference
                    in terms of government license and certification.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/trucking-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Tax rebates:
                    </a>
                  </h3>
                  <p>
                    MSME / SSI registered businesses enjoy multiple income taxes
                    and capital gains tax subsidies from the government.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/packaging-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Cheaper infrastructure:
                    </a>
                  </h3>
                  <p>
                    Charges are lower for MSME registered company for facilities
                    such as electricity and VAT exemptions. In fact, other
                    business services such as patents are also cheaper for MSMEs
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/warehousing-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Access to tenders:
                    </a>
                  </h3>
                  <p>
                    There are multiple government tenders which are open only to
                    MSMEs to promote small business participation in the India.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Services Section --> */}

        <section className="mt-3" id="frequently">
          <div className="container">
            <div className="row">
              {/* <!-- ***** FAQ Start ***** --> */}
              <div className="col-md-6 offset-md-3">
                <div className="faq-title text-center pb-3">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="faq" id="accordion">
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-1"
                          data-aria-expanded="true"
                          data-aria-controls="faqCollapse-1"
                        >
                          <span className="badge">1</span> How do you get MSME
                          Registration?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-1"
                      className="collapse"
                      aria-labelledby="faqHeading-1"
                    >
                      <div className="card-body">
                        <p>
                          Although, there is no compulsion to get MSME
                          registration but is always advised to do so as it
                          provides lots of benefits to the enterprises
                          registered under MSME Act. It will save you from a lot
                          of inconveniences later on. After assigning you the
                          Provisional MSME Certificate and you can begin with
                          your production and even apply for a Permanent
                          Certificate which will add more advantages for you.
                          Always apply for provisional registration at an
                          initial stage of the business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-2">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-2"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-2"
                        >
                          <span className="badge">2</span> Is SSI/MSME
                          Registration voluntary or mandatory?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-2"
                      className="collapse"
                      aria-labelledby="faqHeading-2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          As said above, SSI/MSME registration is totally
                          voluntary. Businessmen and entrepreneurs usually get
                          this done to utilise the advantages offered under it.
                          The registration process is quite easy and simple. You
                          can easily avail it (provided you have a manufacturing
                          plant or a commercial space where you render
                          services), many businesses opt for it over other
                          registrations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-3">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-3"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-3"
                        >
                          <span className="badge">3</span> Why should I apply
                          before commencing operations?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-3"
                      className="collapse"
                      aria-labelledby="faqHeading-3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          It is advisable to get a Provisional SSI Registration
                          Certificate (PRC) before starting your operation in
                          your enterprise. The PRC will work smoothly for you
                          and will help you out with particular NOCs and
                          clearances from regulatory bodies.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-4">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-4"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-4"
                        >
                          <span className="badge">4</span> What documents are
                          required for the application?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-4"
                      className="collapse"
                      aria-labelledby="faqHeading-4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          If your business or enterprise is listed under
                          Schedule-III of Industrial Licensing Exemption
                          Notification, then you can easily apply for PRC
                          without an industrial license. Other units must first
                          acquire an industrial license. All the clearances
                          should be obtained by enterprise whether it be
                          statutory or administrative. For example, a drug
                          license under the drug control order, if required. The
                          PRC is issued based on the application form. No field
                          enquiry is made.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-5">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-5"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-5"
                        >
                          <span className="badge">5</span>What is the time taken
                          by TaxOSmart.com in forming a Private Limited Company?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-5"
                      className="collapse"
                      aria-labelledby="faqHeading-5"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          TaxOSmart.com can incorporate a Private Limited
                          Company within 15-25 days. The time took also depends
                          on relevant documents provided by the applicant and
                          speed of approvals from government. To ensure speedy
                          registration, please pick a unique name for the
                          proposed Company and make sure you have all the
                          required documents prior to starting the registration
                          process.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-6">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-6"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-6"
                        >
                          <span className="badge">6</span> For how long is MSME
                          Provisional Registration Certificate(PRC) valid?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-6"
                      className="collapse"
                      aria-labelledby="faqHeading-6"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          The validation of a PRC is for 5 years and if the unit
                          is still not under operational then you may still
                          re-apply for it. As soon as you start with your
                          operations, you can easily apply for the Permanent
                          License.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-7">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-7"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-7"
                        >
                          <span className="badge">7</span> What benefits are
                          given to MSME by State and Central government?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-7"
                      className="collapse"
                      aria-labelledby="faqHeading-7"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          MSMEs are recognised by all the institutions and
                          banks. Special schemes are drafted to recognise them
                          as well. Banks prefer MSMEs instead of normal
                          enterprises to lend loans. The possibility of getting
                          a sanctioned loan is much higher in a case of MSME.
                          Bank loan interest rates will also be lower. There may
                          also be preferential treatment in case of delay in
                          repayment.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-8">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-8"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-8"
                        >
                          <span className="badge">8</span> What benefits are
                          given to MSME by State and Central government?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-8"
                      className="collapse"
                      aria-labelledby="faqHeading-8"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Many State government prefers enterprises which are
                          registered under MSMED Act. Subsidy such as on power,
                          taxes, and much more is offered by state governments.
                          In most of the states, sales tax exemption is provided
                          purchase preferences is given on goods produced. The
                          enterprise may also relish excise exemption scheme and
                          exemption from certain direct taxes in the initial
                          years of your business.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-9">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-9"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-7"
                        >
                          <span className="badge">9</span>What if I have only
                          one director for my Private Limited Company?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-9"
                      className="collapse"
                      aria-labelledby="faqHeading-9"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Well, you can get someone from your family or friend
                          circle as the second direction and give them marginal
                          share, e.g, 0.01%. You also have the option to
                          register a One Person Company, but it will not be
                          possible to add directors in OPC later.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-10">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-10"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-10"
                        >
                          <span className="badge">10</span> How is the value of
                          Plant and Machinery computed for SSI registration?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-10"
                      className="collapse"
                      aria-labelledby="faqHeading-10"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          While computing the value of Plant and Machinery, only
                          original value is asked to be taken into account as
                          per the MSME notification.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-11">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-11"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-11"
                        >
                          <span className="badge">11</span> What is the support
                          available for collateral free borrowing?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-11"
                      className="collapse"
                      aria-labelledby="faqHeading-11"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Certain trust such as Credit Guarantee Fund Trust has
                          been set up by Ministry of MSME, Government of India
                          and SIDBI, so that there won't be any breakage in the
                          flow of credit to the MSE sector without any
                          interference from the third party. The scheme is
                          solidified on the basis that the lender should give
                          importance to project viability and secure the credit
                          facility purely on the primary security of the assets
                          financed. Cluster based approach to lending is
                          intended to provide a full-service approach to cater
                          to the diverse needs of the MSE sector which may be
                          achieved through extending banking services to
                          recognised MSE clusters.
                        </p>

                        <p>
                          The banks have, therefore, been advised to treat it as
                          a thrust area and increasingly adopt the same for MSME
                          financing. Banks are always asked to take appropriate
                          steps so that the flow of credit is smoothly done to
                          the identified clusters of micro and small
                          entrepreneurs from the Minorities Communities residing
                          in the minority concentrated districts of the country.
                        </p>
                        <p>
                          The CGTMSE would provide cover for credit facility up
                          to Rs. 100 lakh which have been extended by lending
                          institutions without any collateral security. A
                          nominal annual fee is charged by the CGTMSE to avail
                          of the guarantee cover.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-12">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-12"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-12"
                        >
                          <span className="badge">12</span> What is credit
                          rating and why is it mandatory?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-12"
                      className="collapse"
                      aria-labelledby="faqHeading-12"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Credit rating is an estimate to figure out the
                          capability of an enterprise to meet all the financial
                          needs based on the previous year dealings. Although
                          credit rating is not compulsive but it is in the
                          interest of the MSE borrowers to get their credit
                          rating done as it would help in credit pricing of the
                          loans taken by them from banks.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-12">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-12"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-12"
                        >
                          <span className="badge">13</span> In which cities does
                          TaxOSmart provide MSME registration?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-12"
                      className="collapse"
                      aria-labelledby="faqHeading-12"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          TaxOSmart provides MSME application services across
                          India in all cities. We have done MSME registration in
                          Mumbai, Delhi, Gurgaon, Noida, Bangalore, Chennai,
                          Hyderabad, Ahmedabad, Kolkata, Surat, Pune, Jaipur,
                          Lucknow, Kanpur, Nagpur and other Indian cities.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                MSME/SSI Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={MSMEpng}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "MSME Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSMEregistration;
