import axios from "axios";
import React, { useState } from "react";

import "../assets/css/Accountingbookkpg.css";

import AccBook from "../static/image/img/accbook.jpg";
import Bookimg from "../static/assetse/img/bookkeeping.png";

import Bookpng from "../static/img/bookkeeping.png";
import ReCAPTCHA from "react-google-recaptcha";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const AccountingBookKpg = () => {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Bookkeeping</title>
        <meta charset="utf-8" />
        <meta name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description"
          content="Bookkeeping is a systematic record of financial transactions in the appropriate books of records. What it means is, whether you make a sale or purchase something, a record will be maintained of the transaction. This record will allow you to easily keep a track of all your transactions and will help you analyse how you can cut costs and reduce taxes. " />
        <meta name="keywords"
          content="bookkeeping and accounting, bookkeeping vs accounting, bookkeeping, accounting, accounting vs bookkeeping." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <main id="pageTop" className="pageTop">
        {/* copy code */}
        <section
          id="hero"
          className="hero d-flex align-items-center wow fadeIn top5"
          data-wow-delay="0.1s"
        >
          <div className="container top0">
            <div className="Topbox">
              <div className="topcontent">
                <h1 className="fs-1">Easily manage your Bookkeeping</h1>
                <p className="fs-5"> Simplifying your Accounting Process</p>
                <div data-aos-delay="600">
                  <div className="text-center text-lg-start" id="resp">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      data-bs-backdrop="false"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span className="getTouch">Contact Us</span>
                      <i className="bi bi-arrow-right white"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
                <img src={Bookpng} className="img-fluid gstImageCss" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* copy code  */}

        {/* <!-- End Call To Action Section --> */}
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={Bookimg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>INFORMATION ABOUT BOOKKEEPING SERVICES</h3>
                <p className="text-justify">
                  Bookkeeping is a systematic record of financial transactions
                  in the appropriate books of records. What it means is, whether
                  you make a sale or purchase something, a record will be
                  maintained of the transaction. This record will allow you to
                  easily keep a track of all your transactions and will help you
                  analyse how you can cut costs and reduce taxes. Bookkeeping is
                  a function of the accounts department of a business. However,
                  many small businesses do not have fully staffed and efficient
                  accounting departments and require the help of external
                  bookkeeping services. TaxOSmart can help your business
                  maintain its books through its network of CA/CSs and tax
                  experts.
                </p>
                <p className="text-justify">
                  Bookkeeping services are necessary for all businesses to
                  ensure accurate operational / financial information. Such
                  information is required by Management, Regulators, and
                  Investors. Also, it is a legal requirement for any business to
                  maintain an appropriate book of accounts to ensure that all
                  relevant taxes are paid and tax filings are made on time.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop">
          <div className="container">
            <header className="section-header">
              <h2 className="mediaFont">What Is Included In Our Package?</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Accounts payable function</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Accounts Reconciliation</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Payroll</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Monthly Analysis</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2>Advantages Of Bookkeeping</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Cost-Effective</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Reduced Tax Liabilities</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Time Effective</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Peace of Mind</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Higher Profits</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="pt-0">
          <div className="container">
            <div className="row">
              {/* <!-- ***** FAQ Start ***** --> */}
              <div className="col-md-6 offset-md-3">
                <div className="faq-title text-center pb-3">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
              <div className="col-md-12 ">
                <div className="faq" id="accordion">
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-1">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-1"
                          data-aria-expanded="true"
                          data-aria-controls="faqCollapse-1"
                        >
                          <span className="badge">1</span>What is the best time
                          to hire a bookkeeper?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-1"
                      className="collapse"
                      aria-labelledby="faqHeading-1"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          You should hire a bookkeeper as soon as you begin your
                          business. This way you'll avoid mistakes from the
                          starting and reduce headaches.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-2">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-2"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-2"
                        >
                          <span className="badge">2</span> Will I lose control
                          of my business by outsourcing my bookkeeping?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-2"
                      className="collapse"
                      aria-labelledby="faqHeading-2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          No way! You shall still maintain 100% control of all
                          decisions and all checks will still be signed by you.
                          You choose what merchants get paid and when. We
                          provide with the data and perform the specific task,
                          but you approve all receipts for payment and sign all
                          checks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-3">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-3"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-3"
                        >
                          <span className="badge">3</span> When should I hire a
                          bookkeeping service?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-3"
                      className="collapse"
                      aria-labelledby="faqHeading-3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          You should hire a bookkeeping service as soon as you
                          start your business. This way you can avoid mistakes
                          from the beginning and thus reduce headaches.
                          Depending on your needs our bookkeepers can complete
                          an entire year's bookkeeping in as little as 3 hours
                          time. If its so simple, can you do it in less time.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-4">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-4"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-4"
                        >
                          <span className="badge">4</span> Do I need a
                          bookkeeping service? I know how to use EXCEL and would
                          prefer it.
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-4"
                      className="collapse"
                      aria-labelledby="faqHeading-4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          If you own a Private Limited Company, then it's
                          recommended that you hire a professional bookkeeping
                          service. Bookkeeping requires the use of double-entry
                          journal accounting to ensure that the Trial Balance is
                          correct. As a private limited company, whether you
                          have one member or fifty members, the compliance,
                          accountability and accuracy should be the same.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-5">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-5"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-5"
                        >
                          <span className="badge">5</span>In which cities does
                          TaxOSmart provide Bookkeeping Services?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-5"
                      className="collapse"
                      aria-labelledby="faqHeading-5"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          TaxOSmart.com is one of the best online service
                          portals to help factories and establishments to
                          receive bookkeeping services in India. We have 10
                          years of experience in application of bookkeeping
                          services. TaxOSmart has 30+ regional offices in India
                          and expand its network in India with 2000+ customers
                          for bookkeeping services.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-6">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-6"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-6"
                        >
                          <span className="badge">6</span> What's the difference
                          between a bookkeeping service and an accountant?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-6"
                      className="collapse"
                      aria-labelledby="faqHeading-6"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          A bookkeeper puts together a complete database of your
                          business income and expenses for your financial year.
                          On the other hand, an accountant takes the bookkeeping
                          data and creates the necessary books of accounts for
                          tax compliance. The danger here is that the work of
                          the bookkeeper has a direct affect on the work of the
                          accountant. If the books of accounts are wrong, then
                          the accounting reports will also be wrong. Hence, the
                          importance of using a first-rate bookkeeping service
                          is key to the success of your business.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-7">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-7"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-7"
                        >
                          <span className="badge">7</span>Is it okay if I keep
                          my receipts and show them to my Accountant at the end
                          of the year?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-7"
                      className="collapse"
                      aria-labelledby="faqHeading-7"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Bookkeeping is relatively inexpensive and is crucial
                          to the success of your business. It is important to
                          keep an accurate record of your income and expenses,
                          so you can make the right decisions at the right time
                          thus reducing preventable losses. Small businesses are
                          especially vulnerable in this area as they perceive
                          great compensation from this exercise but
                          underestimate the true value of labour and materials
                          required. You would be better off with a bookkeeping
                          service that will accept your receipts every two
                          months and keep you profitable.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-8">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-8"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-8"
                        >
                          <span className="badge">8</span> I had a
                          sole-proprietorship and incorporated a Private Limited
                          Company mid-way through the year. How does this affect
                          my bookkeeping?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-8"
                      className="collapse"
                      aria-labelledby="faqHeading-8"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          You must treat these as two separate businesses with
                          two separate books of accounts. It is simple and clear
                          rule that each business has its own books of accounts
                          and GST registration as well. Also, each business
                          requires its own separate business bank account.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-9">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-9"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-7"
                        >
                          <span className="badge">9</span>What all the things
                          need to be maintained in the books of accounts?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-9"
                      className="collapse"
                      aria-labelledby="faqHeading-9"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Books of accounts comprise of a Journal, a Ledger
                          book, a Trial Balance, Original and carbon copies of
                          bills/invoices/receipts /, Cash Book, Profit and Loss
                          A/c, Balance Sheet and Cash Flow Statements.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card panel">
                    <div className="card-header" id="faqHeading-10">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-10"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-10"
                        >
                          <span className="badge">10</span>Is there a legal
                          requirement to maintain books of accounts?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-10"
                      className="collapse"
                      aria-labelledby="faqHeading-10"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Companies and LLPs are required to maintain books of
                          accounts as mandated by their governing statutes,
                          namely Companies Act, 2013 and Limited Liability
                          Partnership Act, 2008. Further, Income Tax Act, 1961
                          also obligates the maintenance of the books of
                          accounts, irrespective of the form of business, and
                          has separate provisions related to it. Thus, there may
                          be a situation where a Private Limited Company is
                          required to comply with such legal provisions as well.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-11">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-11"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-11"
                        >
                          <span className="badge">11</span> If I maintain books
                          of accounts with the help of a professional firm, is
                          it mandatory for me to get my accounts audited? If
                          yes, what is the frequency of audit?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-11"
                      className="collapse"
                      aria-labelledby="faqHeading-11"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          Maintaining or not maintaining books by handing over
                          the job to a professional firm does not determine
                          whether you need to undergo audit or not. Furthermore,
                          there are several different types of audits such as,
                          internal audit, cost audit, tax audit and statutory
                          audit. Each audit has its own statutory requirements,
                          thresholds of being conducted with relevant deadlines
                          and due dates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card panel">
                    <div className="card-header" id="faqHeading-12">
                      <div className="mb-0">
                        <h5
                          className="faq-title"
                          data-toggle="collapse"
                          data-target="#faqCollapse-12"
                          data-aria-expanded="false"
                          data-aria-controls="faqCollapse-12"
                        >
                          <span className="badge">12</span>Is there any
                          difference between accounting and accounting
                          standards?
                        </h5>
                      </div>
                    </div>
                    <div
                      id="faqCollapse-12"
                      className="collapse"
                      aria-labelledby="faqHeading-12"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          If accounting is the product, accounting standards are
                          its manual. Accounting standards are enacted and
                          published by The Institute of Chartered Accountants of
                          India which are meant to be followed by businesses for
                          true and fair recording of their transactions. All
                          accounting standards are not mandatory for all forms
                          of businesses. Simply put, the bigger your business,
                          the number of accounting standards which need to be
                          followed.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Accounting Book Keeping
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Bookpng}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Accounting / Bookkeeping")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AccountingBookKpg;
