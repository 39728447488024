import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import HomePage from "./components/HomePage";
import TdsSoftware from "./components/TdsSoftware";
import GstReconcile from "./components/GstReconcile";
import GstInvoice from "./components/GstInvoice";
import InvestmentProof from "./components/InvestmentProof";
import Einvoice from "./components/Einvoice";

import "./App.css";
import BulkForm from "./components/BulkForm";
import BulkPan from "./components/BulkPan";
import PdfSighn from "./components/PdfSighn";
import ItrNon from "./components/ItrNon";
import As26Recon from "./components/As26Recon";
import Pvt from "./components/Pvt";
import Llp from "./components/Llp";
import Opc from "./components/Opc";

import Fssai from "./components/Fssai";

import "./App.css";
import IecRegistration from "./components/IecRegistration";
import IsoRegistration from "./components/IsoRegistration";
import Dsc from "./components/Dsc";
import EsiRegistration from "./components/EsiRegistration";
import Epf from "./components/Epf";
import TradeLicence from "./components/TradeLicence";
import GstRegistration from "./components/GstRegistration";
import ProfessionalTR from "./components/ProfessionalTR";
import TradeMarkReg from "./components/TradeMarkReg";
import TrademarkAssignment from "./components/TrademarkAssignment";
import TrademarkObjection from "./components/TrademarkObjection";
import CopyRight from "./components/CopyRight";
import PatentRegistration from "./components/PatentRegistration";
import RocCompliances from "./components/RocCompliances";
import LlpCompliance from "./components/LlpCompliance";
import CompanyAnnualfiling from "./components/CompanyAnnualfiling";
import AccountingBookKpg from "./components/AccountingBookKpg";
import ChangeInDirector from "./components/ChangeInDirector";
import IncomeTaxreturn from "./components/IncomeTaxreturn";
import RevisedReturnfiling from "./components/RevisedReturnfiling";
import GstReturnFiling from "./components/GstReturnFiling";
import RespondTaxNotice from "./components/RespondTaxNotice";
import PublicLtdCompany from "./components/PublicLtdCompany";
import MSMEregistration from "./components/MSMEregistration";
import SocietyRegistration from "./components/SocietyRegistration";
import Section8Registeration from "./components/Section8Registeration";
import NidhiCRegisrtation from "./components/NidhiCRegisrtation";
import IndianSubsidiary from "./components/IndianSubsidiary";

import FooterHome from "./components/FooterPage/FooterHome";
import Blogs from "./components/Blogs/Blogs";
import Career from "./components/FooterPage/Career";
import Blog from "./components/FooterPage/Blog";
import Hsn from "./components/FooterPage/Hsn";
import ContactUs from "./components/FooterPage/ContactUs";
import AboutUs from "./components/FooterPage/AboutUs";
import TopicDetail from "./components/Blogs/TopicDetail";
import NewsArticle from "./components/Blogs/NewsArticle";
import BlogDetail from "./components/BlogDetail";
import ImportantDates from "./components/ImportantDates";

function App() {
  return (
    <div>
      <Router>
        <Home />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/TdsSoftware" element={<TdsSoftware />}></Route>
          <Route path="/GstReconcile" element={<GstReconcile />}></Route>
          <Route path="/GstInvoice" element={<GstInvoice />}></Route>
          <Route path="/InvestmentProof" element={<InvestmentProof />}></Route>
          <Route path="/BulkForm" element={<BulkForm />}></Route>
          <Route path="/Einvoice" element={<Einvoice />}></Route>
          <Route path="/BulkPan" element={<BulkPan />}></Route>
          <Route path="/PdfSighn" element={<PdfSighn />}></Route>
          <Route path="/ItrNon" element={<ItrNon />}></Route>
          <Route path="/As26Recon" element={<As26Recon />}></Route>
          <Route path="/Pvt" element={<Pvt />}></Route>
          <Route path="/Llp" element={<Llp />}></Route>
          <Route path="/Opc" element={<Opc />}></Route>bl
          {/* Start Blogs */}
          <Route path="/BlogDetail/:id" element={<BlogDetail />}></Route>
          {/* Start Business Dropdown */}
          <Route
            path="/PublicLtdCompany"
            element={<PublicLtdCompany />}
          ></Route>
          <Route
            path="/msmeRegistration"
            element={<MSMEregistration />}
          ></Route>
          <Route
            path="/societyRegistration"
            element={<SocietyRegistration />}
          ></Route>
          <Route
            path="/Section8Registeration"
            element={<Section8Registeration />}
          ></Route>
          <Route
            path="/nidhicRegisrtation"
            element={<NidhiCRegisrtation />}
          ></Route>
          <Route
            path="/indianSubsidiary"
            element={<IndianSubsidiary />}
          ></Route>
          {/* Registration dropdown */}
          <Route path="/Fssai" element={<Fssai />}></Route>
          <Route path="/IecRegistration" element={<IecRegistration />}></Route>
          <Route path="IsoRegistration" element={<IsoRegistration />}></Route>
          <Route path="/Dsc" element={<Dsc />}></Route>
          <Route path="/EsiRegistration" element={<EsiRegistration />}></Route>
          <Route path="/Epf" element={<Epf />}></Route>
          <Route path="/TradeLicence" element={<TradeLicence />}></Route>
          <Route path="/GstRegistration" element={<GstRegistration />}></Route>
          <Route path="/ProfessionalTR" element={<ProfessionalTR />}></Route>
          <Route path="/TradeMarkReg" element={<TradeMarkReg />}></Route>
          <Route
            path="TrademarkAssignment"
            element={<TrademarkAssignment />}
          ></Route>
          <Route
            path="/TrademarkObjection"
            element={<TrademarkObjection />}
          ></Route>
          <Route path="/CopyRight" element={<CopyRight />}></Route>
          <Route
            path="PatentRegistration"
            element={<PatentRegistration />}
          ></Route>
          {/* Compilences Dropdown */}
          <Route path="/RocCompliances" element={<RocCompliances />}></Route>
          <Route path="/LlpCompliance" element={<LlpCompliance />}></Route>
          <Route
            path="/CompanyAnnualfiling"
            element={<CompanyAnnualfiling />}
          ></Route>
          <Route
            path="/AccountingBookKpg"
            element={<AccountingBookKpg />}
          ></Route>
          <Route
            path="/ChangeInDirector"
            element={<ChangeInDirector />}
          ></Route>
          {/* Tax Filing */}
          <Route path="/IncomeTaxreturn" element={<IncomeTaxreturn />}></Route>
          <Route
            path="/RevisedReturnfiling"
            element={<RevisedReturnfiling />}
          ></Route>
          <Route path="/GstReturnFiling" element={<GstReturnFiling />}></Route>
          <Route
            path="/RespondTaxNotice"
            element={<RespondTaxNotice />}
          ></Route>
          {/* footer Pages Here */}
          <Route path="/Hsn" element={<Hsn />}></Route>
          <Route path="/AboutUs" element={<AboutUs />}></Route>
          <Route path="/ContactUs" element={<ContactUs />}></Route>
          <Route path="/Career" element={<Career />}></Route>
          <Route path="/Blog" element={<Blog />}></Route>
          <Route path="/TopicDetail" element={<TopicDetail />}></Route>
          <Route path="/NewsArticle" element={<NewsArticle />}></Route>
          <Route path="/ImportantDates" element={<ImportantDates />}></Route>
        </Routes>
      </Router>
      <Blogs />
      <FooterHome />
    </div>
  );
}

export default App;
