import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/CopyRight.css";
import patentReg from "../static/assetse/img/patentRegistration.jpg";
import PatentSearch from "../static/image/img/patentSearch.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import "bootstrap/dist/css/bootstrap.min.css";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { Helmet } from 'react-helmet';
const PatentRegistration = () => {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>Patent Registration in India</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Patent registration in India provides intellectual property rights for unique inventions. Governed by the Patent Act of 1970 and Patent Rules of 1972, it allows inventors to secure their innovations with the Indian government's support." />
        <meta name="keywords" content="Patent Registration, intellectual property, Patent Act 1970, Patent Rules 1972, unique invention, Indian patent law" />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="call-to-action" className="call-to-action">
        <div className="container" data-aos="zoom-out">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              {/* <!-- <h3>Patent Registration</h3>
        <p>Protect your Invention</p> --> */}
              <a
                className="cta-btn"
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                href=""
                data-bs-backdrop="false"
              >
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}
      <br />
      <main id="main">
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about pt-0">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first mt-0">
                <img src={patentReg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>Patent Registration</h3>
                <p>
                  A Patent registration helps in getting an intellectual
                  property right to an invention carried out by an individual or
                  firm. IP department has been initiated by Indian government to
                  grant you the full right to register your invention under
                  patent (but only if it is unique). In return the inventor must
                  produce all the proofs related to the invention as asked by
                  the government. It also ensures that owner gets more
                  preference over other person for your particular invention. In
                  India, Patent is being governed by the Patent Act 1970 &
                  Patent Rules 1972.
                </p>

                <ul>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3">What Is Included In Our Package?</h5>
                      <p>
                        Government Registration Fees , Application Preparation,
                        Obtain MSME Certificate
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features patentTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2 className="section-header">
                What Is Included In Our Package?
              </h2>
            </header>

            <div className="row">
              {/* <!--  <div className="col-lg-6">
          <img src="static/assets/img/features.png" className="img-fluid" alt="">
        </div> --> */}

              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Prior Art Search</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Application Drafting</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Application Filing</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Government Fees</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features patentTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2>Why Patent Registration?</h2>
              {/* <!--   <p>Laboriosam et omnis fuga quis dolor direda fara</p> --> */}
            </header>

            <div className="row">
              {/* 
        <!--  <div className="col-lg-6">
          <img src="static/assets/img/features.png" className="img-fluid" alt="">
        </div> --> */}

              <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
                <div className="row align-self-center gy-4">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Get Royalty by licensing your patent</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Protection For a period of 20 Years In India</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>you can then Utilize your Invention Yourself</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- End Features Section --> */}
      </main>
      {/* <!-- End #main --> */}

      <br />
      <section id="features" className="features patentTop">
        <div className="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div
            className="row feature-icons aos-init aos-animate mt-0"
            data-aos=""
          >
            <h3>Steps For Patent Application</h3>

            <div className="row mt-0">
              <div className="col-xl-12 d-flex content">
                <div className="row align-self-center gy-4 mt-0">
                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="fa fa-coffee" aria-hidden="true"></i>

                    <div>
                      <h4>Step 1: Patent search-</h4>
                      <p className="text-justify">
                        A thorough search is performed for all the existing
                        inventions on the idea that you are trying to patent. If
                        there is a patent already on the idea you are
                        considering, than patent is not granted. Patent search
                        saves you from the effort of going through the year long
                        registration process. You can stop if the patent is
                        already registered.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 icon-box aos-init aos-animate">
                    <i className="fa fa-bookmark" aria-hidden="true"></i>

                    <div>
                      <h4>Step 2: Patent domicile-</h4>
                      <p className="text-justify">
                        Patent which are registered in India is only valid for
                        India. It protects your invention in India only and does
                        not apply to other countries. There is the possibility
                        to protect your invention in other countries as well.
                        For same, you need to apply for a separate application
                        in each country.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="fa fa-life-ring" aria-hidden="true"></i>

                    <div>
                      <h4>Step 3: File Patent application-</h4>
                      <p className="text-justify">
                        The Patent office of the Indian government reviews your
                        patent application. There check for any existing patents
                        granted on similar idea. If they find the invention
                        unique and patentable, then they grant patent for the
                        application.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                    data-aos-delay="400"
                  >
                    <i className="fa fa-gavel" aria-hidden="true"></i>

                    <div>
                      <h4>Step 5: Patent Grant-</h4>
                      <p className="text-justify">
                        Once the patent is granted, the application status is
                        updated online at the Patent site. It can take from 6
                        months to even 1.5 year for a patent certificate to be
                        granted.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <section className="mt-0">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card panel">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span> What can be Patented?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        It can be any invention related to work, manufacturing,
                        the machine, process, computer software or any other
                        which was never introduced to the public before.
                      </p>
                      <p>
                        If a Patent is granted in India does it mean that it
                        will protect my invention outside India as well?
                      </p>
                      <p>
                        A patent granted in India is applicable only in India
                        i.e. a patent owner cannot exercise his rights outside
                        India. If the patent owner wants to get protection in
                        countries outside India then they can apply for the same
                        in other countries within 12 months of getting a Patent
                        in India.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Should I Patent my
                        publication before or after publishing the details of
                        invention?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        To Patent an invention in India it should fulfil the
                        patentability criteria of being novel and non-obvious.
                        Thus if it is published, invention ceases to be novel
                        and hence cannot be patented thereafter.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span>Should I Patent my
                        publication before or after publishing the details of
                        invention?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        To Patent an invention in India it should fulfil the
                        patentability criteria of being novel and non-obvious.
                        Thus if it is published, invention ceases to be novel
                        and hence cannot be patented thereafter.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span> Do I need professional
                        help to draft and file an application for a Patent on my
                        behalf?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        There is absolutely no other payment. Our charges are
                        inclusive of everything. We will send you an invoice
                        with no hidden charges. Our team provides you support
                        till you get your bank account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span>Is it possible to keep
                        my invention secret after getting a patent for it?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, the Patents are granted on the condition that the
                        details will be disclosed to the general public. Hence
                        it is not possible to keep it a secret.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> Can NRIs/Foreign
                        Nationals become a Director in Private Limited Company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, an NRI or Foreign National can become a Director in
                        a Private Limited Company. They can get a DPIN from
                        Indian ROC. Also, they can be a majority shareholder in
                        the company. But, at least one Director on the Board of
                        Directors must be an Indian Resident.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span>Is it possible to get my
                        software related invention Patented?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        For a software to be patented it must fulfil the
                        patentability criteria of being novel, non-obvious
                        (inventive step) and industrial applicability. Also, it
                        should not be falling under the category of
                        non-patentable items etc. To ensure novelty, it is
                        recommended to take expert help for conducting a
                        patentability search so that you do not incur government
                        fee for a non-patentable invention.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span className="badge">8</span> Do I need a proper
                        office address to start a company?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, you don't need a proper office. You can even
                        register your company at your residential address. It is
                        perfectly legal to start the company at your home or in
                        your garage. MCA team typically doesn't visit your
                        office.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">9</span> Why is a Patent search
                        essential?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Patent search is very important because every year, the
                        department receives many applications for patent
                        registration. But not all applications get approved due
                        to some copied reasons. Thus to waste any time on patent
                        registration, you should go for patent search first.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span className="badge">10</span>If I could search
                        through Patent search about the registration, then why
                        should I go to any consultant for the same
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, you could search easily at portal. But it requires
                        the expert consultation to search for a patent. They can
                        help you with the filing with more accuracy. So it is
                        always advised to consult experts while Patent search or
                        registration in India.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-11">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-11"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-11"
                      >
                        <span className="badge">11</span>Can I discuss details
                        of my invention with a potential investor before filing
                        for patent?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-11"
                    className="collapse"
                    aria-labelledby="faqHeading-11"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Disclosing the details to an investor poses a risk for
                        invention not meeting the patentability criteria i.e.
                        the invention ceases to be novel and non-obvious.
                        Therefore it is important that you should first file an
                        application to register the patent and then disclose the
                        details to a potential investor. If disclosing the
                        details to an investor or business partner is
                        unavoidable then, in such instance it should be
                        accompanied by a non-disclosure agreement.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-12">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-12"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-12"
                      >
                        <span className="badge">12</span>What is the validity of
                        any Patent
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-12"
                    className="collapse"
                    aria-labelledby="faqHeading-12"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Patent registration lasts for 20 years from the date of
                        filing the provisional or permanent patent. After that,
                        it would fall under public domain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* <!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                TDS SOFTWARE
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={patentReg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Patent Registeration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default PatentRegistration;
