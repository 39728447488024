import React from "react";

import "../../assets/css/style.css";

import "../../assets/css/bootstrap.min.css";

import "../../assets/css/navbar.css";
import "../../assets/css/Career.css";

import "../../assets/css/Career.css"
import Icon2 from "../../static/assets/img/icons/icon-2.png";
import Icon3 from "../../static/assets/img/icons/icon-3.png";
import Icon4 from "../../static/assets/img/icons/icon-4.png";

// import SucWeb from "../../static/img/Succ.webp";

const Career = () => {
  return (
    <div>
      <br />
      <div className="row animated animatedFadeInUp fadeInUp mn_menus">
        <br />
        <br />

        <h1>
          Endless Opportunities <strong>CAREERS</strong>
        </h1>

        <h2>Be a part of our success</h2>

        <h3>Intrested candidates can Send Your Resume to:</h3>
        <h4>careers@taxosmart.com</h4>
      </div>

      <div className="row animated animatedFadeInUp fadeInUp mobile">
        <br />
        <br />

        <h1>
          Endless Opportunities <strong className="carOpp">CAREERS</strong>
        </h1>

        <h2>Be a part of our success</h2>

        <h3> Intrested candidates can Send Your Resume to:</h3>
        <h4>careers@taxosmart.com</h4>
      </div>

      <br />
      <div className="row ml-5 me-5">
        <div className="col-sm-12 theory">
          <center className="center">
            <i className="fa fa-quote-left"></i>
          </center>
          <center>
            <h1 className="gde">
              <span>Grow, Develop and Evolve</span>
            </h1>
          </center>
          <p>
            Tax-O-Smart is dedicated to develop highly innovate and creative
            products and services that execute total tax and accounting
            solutions to all kinds of business structures. At Tax-O-Smart,
            people are exposed to an intelligent and dynamic environment for
            professional excellence and as well as personal growth. They have to
            work on the technologies and domains that translate into continual
            expansion of professional boundaries. We here are regulating
            etiquette, ethics, discipline, professional conduct and standards
            within the organization.
          </p>
          <br />
        </div>
      </div>
      <br />

      <div classNameName="bg-white">
        <b />
        <h1 classNameName="ml-45">
          <center>Tax-O-Smart Benefits and Perks</center>
        </h1>
        <br />

        <div className="container-xxl py-5 ng-scope">
          <div className="container pt-5">
            <div className="row g-4">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                  <div className="fact-icon">
                    <img src={Icon2} alt="Icon" />
                  </div>
                  <h3 className="mb-3 carDev">Careers development</h3>
                  <p className="mb-0 text-justify">
                    At Tax-O-Smart, you will be exposed to a wide space of
                    learning new methods of handling and dealing with the
                    official matters. We safeguard and enhance the professional
                    standing, interest of our team members. You will have an
                    amazing range of opportunities. You might become an expert
                    in a particular field or build proficiency across many
                    areas.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                  <div className="fact-icon">
                    <img src={Icon3} alt="Icon" />
                  </div>
                  <h3 className="mb-3 carDev">Diversity & inclusion</h3>
                  <p className="mb-0 text-justify">
                    We at Tax-O-Smart motivate our employees to open up their
                    mind and come out with new and innovative ideas that may
                    help the organization to achieve its goals. We seek out
                    people from diverse backgrounds and encourage them to take
                    risks and approach challenges unconventionally.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                  <div className="fact-icon">
                    <img src={Icon4} alt="Icon" />
                  </div>
                  <h3 className="mb-3">Best Times</h3>
                  <p className="mb-0 text-justify">
                    Though we have professional hours and days where we are
                    totally dedicated to serve our clients but we do not neglect
                    the peace and pleasure requirements of our team members and
                    employees
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <br />
        <div className="row bg-white" data-aos="fade-left">
          <div className="col-sm-12">
            <div className="container">
              <center>
                <h1>We are Hiring</h1>
              </center>
              <div
                className="mn_menu2 text-center"
                style={{ display: "block ruby" }}
              >
                <h3>Intrested candidates can Send Your Resume to:</h3>

                <h5>careers@taxosmart.com</h5>
              </div>

              <div className="mobile">
                <h3>Intrested candidates can send your resume to:</h3>

                <h5>careers@taxosmart.com</h5>
              </div>

              <br />
              <br />
              <div
                className="panel-group pointer text-start align-center"
                id="accordion"
              >
                <div className="panel panel-default">
                  <div
                    className="panel-heading "
                    data-toggle="collapse"
                    data-parent="#accordion"
                    data-target="#role1"
                  >
                    <h4 className="panel-title">
                      <a>Role 1: Full Stack Developer</a>
                    </h4>
                  </div>
                  <div id="role1" className="panel-collapse collapse show">
                    <div className="panel-body">
                      <div className="col-md-6">
                        <strong>
                          The selected candidate(s) need to work on following
                          things:
                        </strong>
                        <ul>
                          <li>Working on sister portals of taxosmart</li>
                          <li>Work and co-ordinate with other tech members</li>
                          <li>Java projects which are into pipelines</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <strong>Requirements:</strong>
                        <ul>
                          <li>
                            Java is must + HTML, CSS, Javascript, Jquerry, Ajax,
                            Json, Mysql, etc{" "}
                          </li>
                          <li>Good communication skills</li>
                          <li>College is not the barrier</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        data-target="#role2"
                      >
                        Role 2: Business Advisor
                      </a>
                    </h4>
                  </div>
                  <div id="role2" className="panel-collapse collapse in">
                    <div className="panel-body">
                      <div className="col-md-6">
                        <strong>
                          The selected candidate(s) need to work on following
                          things:
                        </strong>
                        <ul>
                          <li>Interacting with clients</li>
                          <li>
                            Attend calls and emails from clients and solving
                            their queries
                          </li>
                          <li>Converting leads into sales</li>
                          <li>Advising clients on services</li>
                          <li>Support the operation team</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <strong>Requirements:</strong>
                        <ul>
                          <li>
                            Good communication skills and convincing skills
                          </li>
                          <li>Good command over English (written & verbal)</li>
                          <li>Excellent interpersonal skills</li>
                          <li>Good at Hindi (Verbally)</li>
                          <li>Familiar with startup environment</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        data-target="#role3"
                      >
                        Role 3: Paid Assistant
                      </a>
                    </h4>
                  </div>
                  <div id="role3" className="panel-collapse collapse">
                    <div className="panel-body">
                      <div className="col-md-6">
                        <strong>
                          The selected candidate(s) need to work on following
                          things:
                        </strong>
                        <ul>
                          <li>
                            Filing returns (ITR, TDS, Sales Tax Return, Service
                            Tax Return)
                          </li>
                          <li>
                            Company Formation (Pvt. Ltd. Company, LLP,
                            Partnership, OPC etc.)
                          </li>
                          <li>
                            Basic Registrations (Trademark, Service Tax, Sales
                            Tax, TAN, IEC etc.)
                          </li>
                          <li>Need to co-ordinate with sales team</li>
                          <li>Connect with clients for required documents</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <strong>Requirements:</strong>
                        <ul>
                          <li>
                            Good communication skills and convincing skills
                          </li>
                          <li>Good command over English (written & verbal)</li>
                          <li>Good at Hindi (Verbally)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="" className="row animated animatedFadeInUp fadeInUp"></div>
      </div>
    </div>
  );
};

export default Career;
