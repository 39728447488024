import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/EsiRegistration.css";
import EsiImg from "../static/assetse/img/esi.jpg";
import Esilogo from "../static/assetse/img/esiLogo.jpg";
import EsiReg from "../static/assetse/img/esiRegistration.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const EsiRegistration = () => {
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };
  return (
    <div>
      <Helmet><title>ESIC</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="The Employee State Insurance Corporation (ESIC) is a mandatory health insurance scheme for units with 10 or more employees earning up to Rs. 15,000. Learn about contributions, benefits, and how to register through TaxOSmart." />
        <meta name="keywords" content="Employee State Insurance Corporation, ESIC, ESI Act 1948, health insurance scheme, employee benefits, ESI registration, TaxOSmart." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* <!-- ======= About Section ======= --> */}

      {/* <!-- ======= Call To Action Section ======= --> */}
      <section id="call-to-action" className="call-to-action top">
        <div className="container" data-aos="zoom-out">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              {/* <!-- <h3>Employee State Insurance (ESI) Registration</h3> */}
              {/* <p>Mandatory for All Companies with > 10 Employees</p> --> */}

              <a
                className="cta-btn actionbtn"
                data-bs-toggle="modal"
                data-bs-target="#demoModal"
                href=""
                data-bs-backdrop="false"
              >
                Call To Action
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Call To Action Section --> */}

      <br />
      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={EsiImg} className="esiImg" alt="" />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>What Is Employee State Insurance Corporation?</h2>
              </div>

              <p>
                Employee State Insurance Corporation or ESIC is a self-financing
                social security and health insurance scheme which provides
                medical benefit, sickness benefit, maternity benefit,
                disablement benefit and various other benefits such as funeral
                expenses, free supply of physical aids etc. to the employees and
                their family.
              </p>

              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bi bi-diagram-3"></i>
                  <div>
                    <h5 className="fs-3">Choose ESI Registration because :</h5>
                    <p>
                      Provides complete medical care to employees , Includes
                      employee's dependants also, Mandatory for units with 10
                      employees
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      <br />

      <section
        id="about"
        className="about
      "
      >
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>What Is Employee State Insurance Corporation?</h2>
              </div>
              <p>
                Units or Establishments that have 10 or more employees, drawing
                the wages of up to Rs.15,000 a month are required to be
                registered for ESIC under the ESI Act 1948. The benefits
                provided by the scheme are funded from the contributions raised
                from covered employees and their employers at the fixed
                percentage of wages. At present, covered employees contribute
                1.75% of the wages to the ESIC and covered employers contribute
                4.75% of the wages, payable to their employees. The state
                government also contribute 1/8th share cost of medical benefit.
                ESI Registration is done through TaxOSmart.
              </p>
            </div>

            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={Esilogo} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* 
  <!-- ======= Features Section ======= --> */}
      <section id="features" className="features whatIncl mt-0">
        <div className="container" data-aos="">
          <div className="section-header">
            <h2>
              What Is Included In Our Employee State Insurance Registration
              Package?
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>ESI Consultation</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Employer Registration</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Employee Registration</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Documentation</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      {/* <!-- ======= Features Section ======= --> */}
      <section id="features" className="features docTop">
        <div className="container" data-aos="">
          {/* <!-- <header className="section-header">
        <p className="section-header">Documents Required for Employee State Insurance Registration</p>
          <p>Laboriosam et omnis fuga quis dolor direda fara</p>
      </header> -->
       */}
          <div className="section-header">
            <h2>
              Documents Required for Employee State Insurance Registration
            </h2>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Registration Certificate or Licence issued under Shops and
                      Establishment Acts or Factories Act.
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Memorandum and Articles of Association/Partnership
                      Deed/Trust Deed depending on the entity that is applying
                      for registration.
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Evidence in support of the date of commencement of
                      production/business/first sale (e.g. Copy of First
                      Invoice).
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Address Proof: Latest Rent receipt of the premises you are
                      occupying indicating the capacity in which the premises
                      are occupied, if applicable.
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Photocopy of certificate of Commencement of production
                      and/or Registration No. of CST/ST (or GST once it becomes
                      applicable).
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Copy of bank statement</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      {/* <!-- End #main --> */}

      <section id="features" className="features docTop pt-0">
        <div className="container" data-aos="">
          <div className="section-header pt-0">
            <h2>Advantages Of ESI Registration</h2>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div className="row align-self-center gy-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>It provides complete medical benefits</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>It includes dependants</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      It can be used at different ESI dispensaries and hospitals
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Any payments made will be reimbursed</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>It takes the needs of the disabled into account</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Access to Medical care in ESI Dispensaries/Hospitals
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <section id="features" className="features mt-0 pt-0">
        <div className="container aos-init aos-animate" data-aos="">
          {/* <!-- Feature Icons --> */}
          <div className="row feature-icons aos-init aos-animate" data-aos="">
            <div className="section-header">
              <h2>Steps For ESIC Registration</h2>
            </div>

            <div className="row mt-6%">
              <div className="col-xl-12 d-flex content whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6 icon-box aos-init aos-animate">
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Getting ESI Registration:</h4>
                      <p>
                        Establishments or Factories must get themselves
                        registered within 15 days of the Act becoming applicable
                        to them by submitting an Employer's Registration Form
                        (Form-01) to the relevant Regional Office.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 icon-box aos-init aos-animate">
                    <i className="ri-stack-line"></i>
                    <div>
                      <h4>Obtaining Code Number:</h4>
                      <p>
                        A 17-digit identification number called the Code number
                        will be provided which must be used in all
                        Correspondence relating to the Scheme. Form 3 (Return on
                        Declaration) must also be submitted along with Form 1.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 icon-box aos-init aos-animate">
                    <i className="ri-command-line"></i>
                    <div>
                      <h4>For Employees:</h4>
                      <p>
                        At the time of joining the insurable employment,
                        employees are required to fill in a Declaration Form
                        (Form1) and submit two copies of a family photo to the
                        employer, which must be submitted to the relevant ESI
                        Branch Office by his employer.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Insurance Number:</h4>
                      <p>
                        The employee will then be allotted an insurance number
                        for the purpose of his identification under the scheme.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Temporary Identity Card:</h4>
                      <p>
                        The employees will also be issued a temporary identity
                        card for availing medical benefit for him/herself and
                        his/her family for a duration of three months.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-6 icon-box aos-init aos-animate"
                    data-aos=""
                  >
                    <i className="ri-line-chart-line"></i>
                    <div>
                      <h4>Permanent Photo Identity Card:</h4>
                      <p>
                        Thereafter, he will be provided with a permanent photo
                        identity card. A person once registered need not
                        register again when there is a change of employment. The
                        same registration can be transferred from one employment
                        to another.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
      </section>

      <section id="about" className="about">
        <div className="container-fluied" data-aos="">
          <div className="row gx-0">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div className="container content">
                <div className="section-header">
                  <h2>ELIGIBILITY FOR ESIC REGISTRATION</h2>
                </div>
                <p>
                  If a company/organisation/business establishment employs 10 or
                  more employees/workers and in the case of Maharashtra and
                  Chandigarh more than 20 employees ESI registration is
                  required. The company should register itself with the ESIC.
                </p>

                <p>
                  As for workers or employees, they are covered or entitled
                  under ESI when they earn less than Rs.21,000 per month and
                  Rs.25,000 in the case of a person with disability. The worker
                  contributes 1.75% of their salary while the employer
                  contributes 4.75% towards the ESI scheme Please note that
                  these rates are revised from time to time. Those workers whose
                  daily average wage is up to Rs.50 are exempted from
                  contributing to the ESI fund; however, employers will continue
                  to contribute towards these workers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <section id="about" className="about">
        <div className="container-fluied" data-aos="">
          <div className="row gx-0">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div className="container content">
                <div className="section-header">
                  <h2>Concerns Related To ESIC Registration</h2>
                </div>

                <p>
                  Minimum The ESI scheme is a self-financing scheme from the
                  contributions. The ESI scheme funds are primarily built out of
                  contribution from employers and employees monthly at a fixed
                  percentage of wages paid. The State Governments also
                  contributes 1/8th share of the cost of Medical Benefit.
                </p>

                <li>
                  The following establishments employing 10 or more persons
                  attracts ESI coverage:
                </li>
                <li>Shops</li>
                <li>
                  Hotels or restaurants not having any manufacturing activity,
                  but only providing service.
                </li>
                <li>Cinemas</li>
                <li>Roadside Motor Transport Estabpshments</li>
                <li>News paper establishments.</li>
                <li>
                  Private Educational Institutions and Medical Institutions
                </li>
                <li>
                  In some states the minimum employees required for coverage is
                  for 20 or more. A few State Governments have not extended
                  scheme to include Medical & Educational Institutions.
                </li>
                <li>
                  Any sum deducted from wages under the ESI Act will be deemed
                  to have been entrusted to the employer by the employee for the
                  purpose of paying the contribution for which it was deducted.
                  Non-payment or delayed payment of the Employee's contribution
                  after being deducted from the wages of the employee amounts to
                  'Breach of trust' and is a criminal offence punishable under
                  Section 406 of IPC.
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}

      <section className="">
        <div className="container">
          <div className="row">
            {/* <!-- ***** FAQ Start ***** --> */}
            <div className="col-md-6 offset-md-3">
              <div className="faq-title text-center pb-3">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                <div className="card panel">
                  <div className="card-header" id="faqHeading-1">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-1"
                        data-aria-expanded="true"
                        data-aria-controls="faqCollapse-1"
                      >
                        <span className="badge">1</span> What is the procedure
                        for registration of an employer?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-1"
                    className="collapse"
                    aria-labelledby="faqHeading-1"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        An employer shall apply for FORM 01 within 15 days after
                        the act becomes applicable to a unit or establishment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Is it mandatory for the
                        employer to register under ESI?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, it is mandatory requirement for every employer who
                        comes under this act to register their Factory/
                        Establishment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span>What are the compliance
                        requirements?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Every employer covered under this act has to comply with
                        various compliances such as deposit of monthly
                        contribution, in order to file half yearly return and
                        report to ESIC authorities if there is any change in
                        business activity, address, ownership and the
                        management, maintenance of registers and records etc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span> Whether the benefits
                        grant to insured employees are transferable?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No, right to receive any benefits under this act is not
                        transferable.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span>In which cities does
                        TaxOSmart provide ESI Registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart provides ESI application services across India
                        in all cities. We have done ESI registration in Mumbai,
                        Delhi, Gurgaon, Noida, Bangalore, Chennai, Hyderabad,
                        Ahmedabad, Kolkata, Surat, Pune, Jaipur, Lucknow,
                        Kanpur, Nagpur and other Indian cities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> Why should I choose
                        TaxOSmart for ESI Registration?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        TaxOSmart.com is one of the best online service portals
                        to help factories and establishments toget ESI
                        registration in India. We have 10 years of experience in
                        application of ESI Registration. TaxOSmart has 30+
                        regional offices in India and expand its network in
                        India with 2000+ customers for ESI Registration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span>Is it mandatory for the
                        employer to register under the scheme?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Yes, it is the statutory responsibility of the employer
                        register their Factory/ Establishment under the ESI Act
                        within 15 days from the date when the act becomes
                        applicable to them.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-8">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-8"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-8"
                      >
                        <span className="badge">8</span> What is the ESI Code
                        number?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-8"
                    className="collapse"
                    aria-labelledby="faqHeading-8"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        It is a 17-digit unique identification number allotted
                        to every registered establishment. This number is
                        generated through the ESIC portal on submission of the
                        required information by the employer or generated on
                        receipt of a survey report from the Social Security
                        Officer.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-9">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-9"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">9</span>Can an establishment or
                        factory once covered go out of coverage if the number of
                        persons employed falls below the minimum limit
                        prescribed?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-9"
                    className="collapse"
                    aria-labelledby="faqHeading-9"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Once a factory or an Establishment is covered under the
                        Act, it continues to be covered despite the fact that
                        the number of covered employees employed at any time
                        falls below the required limit or there is a change in
                        the manufacturing activity.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-10">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-10"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-10"
                      >
                        <span className="badge">10</span>If the wages of an
                        employee exceed Rs.15,000/- in a month, will he not be
                        covered and deduction of contribution from his wages
                        should be stopped?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-10"
                    className="collapse"
                    aria-labelledby="faqHeading-10"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        If an employee's wages (excluding remuneration for
                        overtime) exceeds the wage limit prescribed by the
                        Central Government after beginning of the contribution
                        period, he continues to be an employee till the end of
                        that contribution period and therefore contribution is
                        to be deducted and paid on the total wages earned by
                        him.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-11">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-11"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-11"
                      >
                        <span className="badge">11</span>Is over-time to be
                        included for wage ceiling limit for coverage of an
                        employee?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-11"
                    className="collapse"
                    aria-labelledby="faqHeading-11"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        No. Overtime is not included for calculating the wage
                        ceiling limit for coverage of an employee. But, it is
                        included for payment of contribution to cover the risk
                        during the period of overtime work, and to enable
                        receiving cash benefits at an enhanced rate also.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card panel">
                  <div className="card-header" id="faqHeading-12">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-12"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-12"
                      >
                        <span className="badge">12</span>What is the time limit
                        for payment of contribution?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-12"
                    className="collapse"
                    aria-labelledby="faqHeading-12"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Contribution will be paid for an employee in to a bank
                        duly authorised by the Corporation within 21 days of the
                        last day of the month in which the contribution is due
                        for any wage period.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-13">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-13"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-13"
                      >
                        <span className="badge">13</span>What is the mode of
                        payment of contributions?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-13"
                    className="collapse"
                    aria-labelledby="faqHeading-13"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The employer needs to file monthly contributions online
                        through ESIC portal in respect of all its employees
                        after registering them. The amount of contributions to
                        be paid are to be calculated by keeping a record of,
                        employee wise, number of days for which wages are paid &
                        the amount of wages paid respectively to calculate the
                        amount of contributions payable. The total amount of
                        contribution (including the shares of the employees and
                        employers) in respect of all the employees for each
                        month must be deposited in any branch of SBI in cash or
                        by cheque or demand draft on generation of such a
                        challan through ESIC portals. Contributions can be paid
                        online through SBI internet banking too.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-14">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-14"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-14"
                      >
                        <span className="badge">14</span>Is there any interest
                        on delayed payment?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-14"
                    className="collapse"
                    aria-labelledby="faqHeading-14"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        An employer who doesn't pay the contribution within the
                        time limit, will be liable to pay simple interest at the
                        rate of 12% per annum for each day of default or delay
                        in payment of contribution.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card panel">
                  <div className="card-header" id="faqHeading-15">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-15"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-15"
                      >
                        <span className="badge">15</span>What are the records to
                        be maintained for the purpose of the ESI scheme?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-15"
                    className="collapse"
                    aria-labelledby="faqHeading-15"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        In addition to the Muster roll, wage record and books of
                        Account maintained under other laws, the employer is
                        required to maintain the following records for ESI:-
                      </p>

                      <ul>
                        <li>Accident Register in Form-11.</li>
                        <li>An inspection book.</li>
                        <li>
                          The immediate employer is also required to maintain
                          the Employee's Register for the employees deployed to
                          the principal employer.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                {" "}
                ESI Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={EsiImg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "ESI Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="text-success">
                {/* <!-- <img src="./././static/img/success.png" height="20" width="20"> --> */}
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" className="h-120" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-18 w-100 fw-bold">
                  Entered Data Is Incorrect
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script>AOS.init();</script>
      <script>$(document).ready(function() {$(this).scrollTop(0)});</script>
    </div>
  );
};

export default EsiRegistration;
