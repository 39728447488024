import React from "react";

import "../../assets/css/style.css";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/navbar.css";
import "../../assets/css/aboutUs.css";

import About2 from "../../static/assets/img/about-2.jpg";
import Taxosmartpng from "../../static/assets/img/Taxosmart.png";
import Icon2 from "../../static/assets/img/icons/icon-2.png";
import Icon3 from "../../static/assets/img/icons/icon-3.png";
import Icon4 from "../../static/assets/img/icons/icon-4.png";

const AboutUs = () => {
  return (
    <div>
      <div className="container-xxl py-5 ng-scope">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h4 className=" fs-4">
                Reasons Why Customers Like Us
              </h4>
              <h1 className="display-5 mb-4 fs-3">
                Why You Should Trust Us? Learn More About Us!
              </h1>
              <p className="mb-4">
                TaxOSmart is founded on the principle of building and
                implementing great ideas that drive progress for clients and
                enhance lives through enterprise solutions.{" "}
              </p>
              <p className="mb-4">
                TaxOSmart.com is a technology platform to simplify legal and
                business related matters. We are committed to helping start ups
                and small business owners in solving legal compliance related to
                starting and running their business.{" "}
              </p>
              <p className="mb-4">
                {" "}
                Through technology, we bring numerous government/ legal forms at
                one place and have simplified them to be fully understood by
                common man. We are a technology driven platform trying to
                organize professional services industry in India.{" "}
              </p>
            </div>
            <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
              <div className="feature-img">
                <img className="img-fluid mt-0" src={About2} alt="" />
                <img className="img-fluid" src={Taxosmartpng} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Facts Start --> */}
      <div className="container-xxl py-5">
        <div className="container pt-5">
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={Icon2} alt="Icon" />
                </div>
                <h3 className="mb-3">Our Vision</h3>
                <p className="mb-0 text-justify">
                  To be the most preferred technological platform for every
                  client who encounters us, as they undergo calmness by leaving
                  their hectic official and business matters to us.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={Icon3} alt="Icon" />
                </div>
                <h3 className="mb-3">Our Mission</h3>
                <p className="mb-0 text-justify">
                  To offer the most affordable, quick and automated professional
                  services to our clients and provide them an one-click access
                  to all the processes like startup registrations, tax filing,
                  accounting, documentation, finance accumulation, and many
                  other legal affairs managed by a professional team that is
                  dedicated to deliver their clients with satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}

      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h4 className="">Features</h4>
            <h1 className="display-5 mb-4 ob">Objectives</h1>
          </div>
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon2} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Promote, foster and maintain the highest standards
                            of various processes before delivering them to our
                            clients.
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon3} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Promote and increase the knowledge, skills and
                            proficiency of our team members.
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon4} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Provide variety of updated facilities to our clients
                            that satisfy most of their requirements.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon2} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Expand our amenity to reach out the clients and
                            render them with our satisfying services.
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon3} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Set up and promote new technologies to improve the
                            process of official and professional affairs.
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon3} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Safeguard and enhance the professional standing,
                            interest and reputation of the members employed with
                            us.
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={Icon3} alt="Icon" />
                        <div className="ms-4">
                          <h4>
                            Regulate the etiquette, ethics, discipline,
                            professional conduct and standards within the
                            organization.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- JavaScript Libraries -->
    <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
    <script src="static/assets/lib/wow/wow.min.js"></script>
    <script src="static/assets/lib/easing/easing.min.js"></script>
    <script src="static/assets/lib/waypoints/waypoints.min.js"></script>
    <script src="static/assets/lib/counterup/counterup.min.js"></script>
    <script src="static/assets/lib/owlcarousel/owl.carousel.min.js"></script>
    <script src="static/assets/lib/tempusdominus/js/moment.min.js"></script>
    <script src="static/assets/lib/tempusdominus/js/moment-timezone.min.js"></script>
    <script src="static/assets/lib/tempusdominus/js/tempusdominus-bootstrap-4.min.js"></script>

    <!-- Template Javascript -->
    <script src="static/assets/js/main.js"></script> */}
    </div>
  );
};

export default AboutUs;
